import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import GeneralHeroSection from "../components/HeroSection/GeneralHeroSection";
import HeroSectionImage from "../assets/contactUsHero.png";
import backgroundCover from "../assets/Section.png";
import MessageSection from "../components/Contact/SendMessage";
import axios from "axios";
import { Link } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Contact = () => {
  const [contact, setContact] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3);
  const [isAllShown, setIsAllShown] = useState(false);

  useEffect(() => {
    const getContactDetails = () => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/contact`)
        .then((response) => {
          setContact(response?.data[0]);
          console.log("Contact=======>",response.data)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    getContactDetails();
  }, []);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const handleShowLess = () => {
    setVisibleCount(3);
    setIsAllShown(false);
  };

  const handleShowAll = () => {
    setVisibleCount(contact?.campuses?.length);
    setIsAllShown(true);
  };

  const backgroundStyle = {
    backgroundImage: `url(${backgroundCover})`,
    backgroundSize: "cover",
  };

  return (
    <Layout>
      <div>
        <GeneralHeroSection
          image={HeroSectionImage}
          heading="Contact"
          imageStyle="object-contain"
        />
      </div>
      <div className="mt-10" style={backgroundStyle}>
        <MessageSection contact={contact} />
      </div>
      <div className="flex flex-col items-center">
        <iframe
          className="w-[95%] my-10 m-auto h-[395px]"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=-79.6393%2C43.5807%2C-79.1157%2C43.8554&amp;layer=mapnik&amp;marker=43.6532%2C-79.3832"
        ></iframe>
        <div className="w-[95%] mb-10">
          <h1 className="uppercase text-center my-5 font-bold text-3xl">
            All Campuses
          </h1>
          <div className="flex flex-wrap justify-start gap-2">
            {contact?.campuses?.slice(0, visibleCount)?.map((campus, index) => (
              <div
                key={index}
                style={{
                  padding: "20px",
                  width: "calc(33.333% - 20px)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  marginY: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flexGrow:"1",
                  minWidth:"250px",
                  flexWrap:"wrap"
                }}
              >
                <h2 className="font-extrabold text-xl leading-6 text-[#183864] mb-2 uppercase">
                  {campus.name}
                </h2>
                <p className="font-normal text-base leading-4 text-[#777777] mb-2">
                  {campus.address}
                </p>
                <p className="font-normal text-base leading-6 text-[#183864] mb-2">
                  {campus.desc1}
                </p>
                <p className="font-normal text-base leading-6 text-[#183864] mb-2">
                  {campus.desc2}
                </p>
                <div className="flex justify-end items-center gap-3">
                  <Link
                    to={`/contact/${campus?._id}`}
                    className="text-sm font-semibold uppercase text-[#B02526]"
                  >
                    More Info
                  </Link>
                  <div>
                    <svg
                      className="bubbling-svg"
                      width="7"
                      height="12"
                      viewBox="0 0 7 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.73242 1.9043L6.00004 7.22249L1.73242 12.5407"
                        stroke="#B02526"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end gap-2 mt-4">
            {!isAllShown && visibleCount < contact?.campuses?.length && (
              <button
                onClick={handleShowAll}
                className="py-2 px-4 bg-[#B02526] text-white rounded hover:bg-blue-500 font-semibold transition duration-200"
              >
                Show All
              </button>
            )}
            <div className="flex gap-4">

              {visibleCount > 3 && (
                <button
                  onClick={handleShowLess}
                  className="flex bg-[#B02526] hover:bg-blue-500 font-semibold items-center gap-1 py-2 px-4 text-white rounded transition duration-200"
                >
                  <span>Show Less</span>
                  <IoIosArrowUp />
                </button>
              )}
                {visibleCount < contact?.campuses?.length && (
                <button
                  onClick={handleShowMore}
                  className="flex items-center gap-1 py-2 px-4 text-white rounded bg-[#B02526] hover:bg-blue-500 font-semibold transition duration-200"
                >
                  <span>Show More</span>
                  <IoIosArrowDown />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
