import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa';
import { useCart } from '../Event/CartContext';

const ConfirmEmail = ({ setStraper, back }) => {
  const [emails, setEmails] = useState([]);
  const [otps, setOtps] = useState([]);
  const [verified, setVerified] = useState([]);
  const { cart } = useCart();

  useEffect(() => {
    const storedEmails = JSON.parse(localStorage.getItem("emailsToVerify")) || [];
    setEmails(storedEmails);
    setOtps(storedEmails.map(() => new Array(6).fill("")));
    setVerified(storedEmails.map(() => false));
    localStorage.setItem("isAllEmailVerified", false)
  }, []);

  const handleChange = (element, digitIndex, emailIndex) => {
    if (isNaN(element.value) && element.value !== '') return;

    const newOtps = [...otps];
    newOtps[emailIndex][digitIndex] = element.value;
    setOtps(newOtps);


    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = (event, emailIndex) => {
    const pasteData = event.clipboardData.getData("Text");
    if (pasteData.length === 6 && /^\d+$/.test(pasteData)) {
      const newOtps = [...otps];
      newOtps[emailIndex] = pasteData.split("");
      setOtps(newOtps);
    }
  };

  const handleBackspace = (element, digitIndex, emailIndex) => {
    if (!element.value && element.previousSibling) {
      element.previousSibling.focus();
    }
  };

const handleSubmit = async () => {
    let allApproved = true;
    const newVerified = [...verified];
  
    for (let i = 0; i < emails.length; i++) {
      if (newVerified[i]) continue;
  
      const otpCode = otps[i].join("");
      if (otpCode.length !== 6) {
        toast.error(`Please enter a 6-digit OTP for ${emails[i]}.`);
        return;
      }
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/event/verify-otp`, {
          email: emails[i],
          otp: otpCode,
        });
  
        if (response.data.success) {
          newVerified[i] = true;
        } else {
          toast.error(`Verification failed for ${emails[i]}: ${response.data.message}`);
          allApproved = false;
        }
      } catch (error) {
        toast.error(`An error occurred during OTP verification for ${emails[i]}.`);
        allApproved = false;
      }
    }
  
    setVerified(newVerified);
  
    if (allApproved && newVerified.every(status => status)) {
      toast.success("All emails verified successfully!");
      setStraper(3);
      localStorage.setItem("isAllEmailVerified", true);
    } else {
      localStorage.setItem("isAllEmailVerified", false);
      toast.info("Some emails still need verification.");
    }
  };
  
  return (
    <div className="relative w-full h-full p-6">
      <div className="mt-2">
        <h2 className="sm:text-2xl text-lg uppercase py-2 font-bold w-full flex justify-center text-slate-600">Verify Email</h2>
        <h2 className="text-xl font-semibold text-slate-600">Verify Your Existing Emails</h2>
        <p className="mt-2 mb-4 text-slate-600">
          You should have received an email. Please check it and enter the OTP provided.
        </p>
        <div className='flex w-full justify-center mt-10'>
          <div className="flex flex-col items-center p-4 bg-white shadow-md rounded-lg w-full max-w-lg">
            <h2 className="text-2xl font-semibold mb-4">Verify Your Emails</h2>
            <p className="text-gray-600 mb-6">Enter the 6-digit code sent to each email</p>

            {emails.map((email, emailIndex) => (
              <div key={emailIndex} className="mb-6 w-full">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium mb-2">{email}</h3>
                  {verified[emailIndex] && <FaCheckCircle className="text-green-500" />}
                </div>
                <div className="flex space-x-2 justify-center">
                  {otps[emailIndex].map((digit, digitIndex) => (
                    <input
                      key={digitIndex}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleChange(e.target, digitIndex, emailIndex)}
                      onPaste={(e) => handlePaste(e, emailIndex)}
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          handleBackspace(e.target, digitIndex, emailIndex);
                        }
                      }}
                      disabled={verified[emailIndex]} // Disable input if verified
                      className={`w-12 h-12 text-center text-black text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        verified[emailIndex] ? "bg-gray-100 cursor-not-allowed" : ""
                      }`}
                    />
                  ))}
                </div>
              </div>
            ))}

            <button
              onClick={handleSubmit}
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 mt-4"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
