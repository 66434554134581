import React, {useState, useEffect} from "react";
import workSafeTraining from "../assets/workSafeTraining.png"
import trainingCarousalImage from "../assets/trainingCarousalImage.png"
import Layout from "../components/Layout/Layout";
import Testimonials from "../components/testimonials/Testimonials";
import { Link, useLocation } from "react-router-dom";
import { CartProvider } from '../components/Event/CartContext';

import axios from 'axios';

const TrainingOFA = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [category, setCategory] = useState([])
  useEffect(() => {
    const getCategory = () => {
      axios.get(`${process.env.REACT_APP_BASE_URL}/category/${id}`)
      .then(response => {
        console.log(response?.data?.data?.category);
        setCategory(response?.data?.data?.category)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
    getCategory()
  }, [])
  console.log("categorycategorycategorycategory", category)
  return (
    <Layout>
      <div className="my-7">
        <div className="flex flex-col text-center items-center justify-center gap-3 sm:gap-4 md:gap-5 xl:gap-10">
          <div className="box-border">
            <img
              src={workSafeTraining}
              className="rounded-[7.59px] shadow-xl w-[370px] h-[139.07px] sm:w-[480px] sm:h-[200px] md:w-[560px] md:h-[260px] lg:w-[680px] xl:w-[878px] xl:h-[330px] max-[370px]:w-[300px]"
            />
          </div>
          <div>
            <h2 className="text-xl font-extrabold leading-[21.07px] sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl xl:leading-[50px]">
              {category.name}
            </h2>
          </div>
        </div>
      </div>

      {/* Accord Section */}
      <div>
        <div className="flex flex-col gap-4 w-[378px] mx-auto sm:w-[500px] md:w-[580px] lg:w-[680px] xl:w-[1120px]  max-[370px]:w-[300px]">
          <div className="mx-auto w-[350px] leading-[10.93px] md:w-[480px] md:leading-[12px] lg:w-[580px] lg:leading-[18px] xl:w-[885px] xl:leading-[24.59px] max-[370px]:w-[300px]">
            <p className="text-[8px] md:text-[10px] lg:text-[14px] xl:text-lg text-center w-full font-extrabold text-[#5E6282] ">
              {category.description}
            </p>
          </div>
          <div className="w-[370px] sm:w-[500px] md:w-full mx-auto xl:w-[1120px] min-[320px]:w-[300px] my-7">
            <div id="accordion-collapse" data-accordion="collapse">
              <h2 id="accordion-collapse-heading-1" className="mb-1 bg-[#FFFFFF] font-semibold text-[7.09px] text-red-600 font-bold border-[0.44px] border-[#000000] border-opacity-[5%] sm:text-[12px] lg:text-[16px] xl:text-[20px]">
                {category?.courses &&
                  category?.courses.map((item, index) => {
                    const subjectName= item.name.replace(/\s+/g, '-')
                  return (
                    <Link to={`/course-about/${subjectName}?course_id=${item._id}`}
                      className="flex items-center w-full py-4 px-5 shadow-md focus:ring-4 focus:ring-gray-200 gap-2 lg:gap-6 rounded-lg transform hover:scale-105 transition-transform duration-300"
                      data-accordion-target="#accordion-collapse-body-1"
                      aria-expanded="true"
                      aria-controls="accordion-collapse-body-1"
                    >
                      <svg
                        data-accordion-icon
                        className="w-[3.71px] h-[7.09px] rotate-90 shrink-0 sm:w-[4.0px] sm:h-[10px] lg:w-[6.2px] lg:h-[12px]  xl:w-[8.38px] xl:h-[16px] xl:font-[900]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5 5 1 1 5"
                        />
                      </svg>
                      <span>{item.name}</span>
                    </Link>
                  )
                  })
                }
              </h2>
            </div>
          </div>
          <div>
            <div className="mb-1 w-[97%] mx-auto">
              <p className="text-[10px] font-bold text-[#183864] leading-[15px] text-center w-full lg:text-[14px] xl:text-[16px] xl:px-6 xl:leading-[24px]">
                Find out why ProSafe Training is the top choice for
                professionals seeking high-quality First Aid and Workplace
                Safety training and certification.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Cousal Section */}

      <div>
        <div className=" my-4 w-[350px] mx-auto sm:w-[520px] md:w-[620px] lg:w-[788px] xl:w-[1175px]  max-[370px]:w-[300px]">
          <div
            className=" flex flex-col items-center gap-3 sm:gap-6 xl:gap-16"
            data-carousel=""
          >
            <div className=" h-[317px] w-full rounded-[10px] overflow-hidden shadow-md sm:h-max ">
              <div
                className=" duration-700 ease-in-out flex flex-col sm:flex-row sm:items-center my-1 "
                data-carousel-item="active"
              >
                <div className="px-6 py-4 sm:basis-2/4">
                  <img
                    src={trainingCarousalImage}
                    className="rounded-[10px]  "
                    alt="..."
                  />
                </div>

                <div className="basis-2/4 flex flex-col gap-3 text-center text-[#183864] mx-[1px] sm:text-left sm:pr-1 xl:gap-6 ">
                    <h3 className="font-bold text-[18.17px] leading-[21.8px] sm:text-[14px] lg:text-[18px] xl:text-[20px] xl:leading-[24px] max-[370px]:text-[16px]">
                      Over 20 years of experience
                    </h3>
                    <p className="text-[11.8px] font-normal sm:text-[8px] md:text-[10px] lg:text-[14px] xl:text-[18px] xl:leading-[27px]  max-[370px]:text-[10px]">
                    With over two decades of experience in the First Aid and Workplace Safety industry, we have a proven track record of delivering high-quality training and certification to professionals across a variety of sectors
                    </p>
                </div>
              </div>
            </div>
            <div className="flex gap-2 lg:gap-4 justify-center">
              <button
                type="button"
                className="w-2 h-2 rounded-full opacity-100 bg-[#183864] lg:w-3 lg:h-3  xl:w-[14px] xl:h-[14px]"
                aria-current="true"
                aria-label="Slide 1"
                data-carousel-slide-to="0"
              ></button>
              <button
                type="button"
                className="w-2 h-2 rounded-full bg-[#D9D9D9] lg:w-3 lg:h-3 xl:w-[14px] xl:h-[14px]"
                aria-current="false"
                aria-label="Slide 2"
                data-carousel-slide-to="1"
              ></button>
              <button
                type="button"
                className="w-2 h-2 rounded-full bg-[#D9D9D9] lg:w-3 lg:h-3  xl:w-[14px] xl:h-[14px]"
                aria-current="false"
                aria-label="Slide 3"
                data-carousel-slide-to="2"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Testimonials />
      </div>
    </Layout>
  );
};

export default TrainingOFA;
