import React, { useEffect, useState } from 'react'
import verticalDots from '../../assets/verticalDots.svg'
import trainingHeroVector1 from '../../assets/trainingHeroVector1.svg'
import TrainingHeroTopCircle from '../../assets/TrainingHeroTopCircle.svg'
import Vector from '../../assets/homeBannerVecotr.svg'
import Vector2 from '../../assets/homeBannerVector2.svg'
import TrainingHero from '../../assets/aboutHeroChracter.svg'
import aboutuscircle from '../../assets/aboutuscircle.svg'
import DrBag from '../../assets/DrBag.png'

import FirstAidKit from '../../assets/FirstAidKit.png';
import Towel from '../../assets/towel.png';
import ActiSplint from '../../assets/ActiSplint.png';
import RescueBag from '../../assets/RescueBag.png';
import BoxOpner from '../../assets/BoxOpner.png';
import BagBasic from '../../assets/BagBasic.png';
import BagLevel2 from '../../assets/BagLevel2.png';
import pocketMask from '../../assets/pocketMask.png';
import { Link } from "react-router-dom";
import axios from 'axios';

const SuppliersProductsHeroSection = () => {
  const [products, setProducts] = useState([]); 
  useEffect(() => {
    const getProducts = () => {
      console.log("${process.env.REACT_PUBLIC_BASE_URL}", process.env.REACT_APP_BASE_URL)
      axios.get(`${process.env.REACT_APP_BASE_URL}/product`)
      .then(response => {
        setProducts(response?.data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
    getProducts()
  }, [])
  const bubbleSVG1 = (<svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="47" y="47" width="47" height="47" rx="23.5" transform="rotate(180 47 47)" fill="#E9EFFF"/>
  </svg>)

  return (
    <div className='relative flex mb-5'>
      <div className='lg:flex hidden absolute left-[-25px] top-5 md:top-1/4'>
        <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
      </div>
      <div className='lg:flex hidden absolute right-[-5px] bottom-[-7%] z-[9999]'>
        <img src={verticalDots} alt=''/>
      </div>
      <img className="lg:flex hidden absolute -bottom-[10px] z-10 w-full" src={Vector} alt="" />
      <img className="lg:flex hidden absolute bottom-[0px] w-full" src={Vector2} alt="" />
      <div className='md:flex hidden absolute top-1/3 left-1/3'>{bubbleSVG1}</div>
      <div className='w-full lg:z-[10] px-4 lg:px-20 pt-10'>
        <div className='font-[800] text-[32px] lg:text-[56px] leading-[32px] lg:leading-[70.56px] text-[#183864] mb-4'>Products</div>
        <div className='flex justify-between items-center '>
          <div className='text-[#9A9EA6] font-[500] text-[16px] leading-[24px]'>{products.length} products</div>
          <div className='flex gap-5 items-center'>
            <div className='text-[#9A9EA6] text-[16px] text-[500] leading-[24px]'>Filters: Availability</div>
            <div className='text-[#9A9EA6] text-[16px] text-[500] leading-[24px]'>Price</div>
          </div>
        </div>
        <div className='gap-2 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-10 justify-items-center'>
          {products.length > 0 &&
            products.map((items, index) => (
              <Link to={`/supplies-product-view?id=${items._id}`} className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <div className='flex items-center justify-center h-[60%]'>
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${items.image}`}
                    alt="Rescue bag"
                  />
                </div>
                <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>{items.name}</div>
                <div className='flex justify-between items-end mx-10'>
                  <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>{items.price}</div>
                  <div className='relative w-[20px] cursor-pointer'>
                    <div>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                      </svg>
                    </div>
                    <div className='absolute -top-2 -right-3'>
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          }
          {/* <Link to="/supplies-product-view" className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <div className='flex items-center justify-center h-[60%]'>
              <img src={ActiSplint} alt="Rescue bag" />
            </div>
            <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>WorkSafeBC Basic First Aid Kit</div>
            <div className='flex justify-between items-end mx-10'>
              <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$29.00 CAD</div>
              <div className='relative w-[20px] cursor-pointer'>
                <div>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                  </svg>
                </div>
                <div className='absolute -top-2 -right-3'>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                  </svg>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/supplies-product-view" className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <div className='flex items-center justify-center h-[60%]'>
              <img src={Towel} alt="Rescue bag" />
            </div>
            <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>Mix Fibre Blanket</div>
            <div className='flex justify-between items-end mx-10'>
              <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$19.00 CAD</div>
              <div className='relative w-[20px] cursor-pointer'>
                <div>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                  </svg>
                </div>
                <div className='absolute -top-2 -right-3'>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                  </svg>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/supplies-product-view" className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <div className='flex items-center justify-center h-[60%]'>
              <img src={FirstAidKit} alt="Rescue bag" />
            </div>
            <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>Personal Deluxe First Aid Kit</div>
            <div className='flex justify-between items-end mx-10 bg-white'>
              <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$19.00 CAD</div>
              <div className='relative w-[20px] cursor-pointer'>
                <div>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                  </svg>
                </div>
                <div className='absolute -top-2 -right-3'>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                  </svg>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/supplies-product-view" className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <div className='flex items-center justify-center h-[60%]'>
              <img src={pocketMask} alt="Rescue bag" />
            </div>
            <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>Pocket Mask in Poly bag</div>
            <div className='flex justify-between items-end mx-10 bg-white'>
              <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$9.99 CAD</div>
              <div className='relative w-[20px] cursor-pointer'>
                <div>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                  </svg>
                </div>
                <div className='absolute -top-2 -right-3'>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                  </svg>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/supplies-product-view" className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <div className='flex items-center justify-center h-[60%]'>
              <img src={BagBasic} alt="Rescue bag" />
            </div>
            <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>WorkSafeBC Basic First Aid Kit</div>
            <div className='flex justify-between items-end mx-10 bg-white'>
              <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$29.00 CAD</div>
              <div className='relative w-[20px] cursor-pointer'>
                <div>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                  </svg>
                </div>
                <div className='absolute -top-2 -right-3'>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                  </svg>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/supplies-product-view" className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <div className='flex items-center justify-center h-[60%]'>
              <img src={BoxOpner} alt="BoxOpner" />
            </div>
            <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>WorkSafeBC Level 1 First Aid Kit - Metal Box</div>
            <div className='flex justify-between items-end mx-10 bg-white'>
              <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$119.00 CAD</div>
              <div className='relative w-[20px] cursor-pointer'>
                <div>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                  </svg>
                </div>
                <div className='absolute -top-2 -right-3'>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                  </svg>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/supplies-product-view" className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <div className='flex items-center justify-center h-[60%]'>
              <img src={RescueBag} alt="Rescue bag" />
            </div>
            <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>WorkSafeBC Level 1 First Aid Kit - Nylon Bag</div>
            <div className='flex justify-between items-end mx-10'>
              <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$79.00 CAD</div>
              <div className='relative w-[20px] cursor-pointer'>
                <div>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                  </svg>
                </div>
                <div className='absolute -top-2 -right-3'>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                  </svg>
                </div>
              </div>
            </div>
          </Link>
          <Link to="/supplies-product-view" className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <div className='flex items-center justify-center h-[60%]'>
              <img src={BagLevel2} alt="BagLevel2" />
            </div>
            <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>WorkSafeBC Level 2 First Aid Kit</div>
            <div className='flex justify-between items-end mx-10'>
              <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$139.00 CAD</div>
              <div className='relative w-[20px] cursor-pointer'>
                <div>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                  </svg>
                </div>
                <div className='absolute -top-2 -right-3'>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                  </svg>
                </div>
              </div>
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default SuppliersProductsHeroSection;
