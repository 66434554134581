import React from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { useCart } from "./CartContext";

const EventModal = ({
  event,
  course,
  spiltCourseName,
  registerSeats,
  setRegisterSeats,
  setStraper,
}) => {
  const { cart, dispatch } = useCart();
  console.log("event issss", event)

  // Calculate the available seats
  const availableSeats = event?.remainingSeats > event?.seatsTotal ? event.seatsTotal : event?.remainingSeats;

  // Generate an array of available seat numbers
  const seatOptions = Array.from({ length: availableSeats }, (_, i) => i + 1);

  const handleAddToCart = () => {
    if (registerSeats === 0) {
      toast.error("Please add seats!");
    } else {
      const eventCart = cart.filter((item) => item.id === event._id);
      if (eventCart.length > 0) {
        const cartItem = {
          id: eventCart[0].id,
          seats: Number(registerSeats),
        };

        dispatch({ type: "UPDATE_EVENT_SEATS_CART", payload: cartItem });
        toast.success("Event updated cart!");
        setStraper(2);
      } else {
        const cartItem = {
          id: event._id,
          name: `${spiltCourseName(course.name)} - ${moment.utc(event.startDate).format(
            "MMMM DD, YYYY"
          )} - ${event.campus}`,
          price: course.price,
          seats: Number(registerSeats),
          event,
          course,
        };
        dispatch({ type: "ADD_TO_CART", payload: cartItem });
        toast.success("Event added to cart!");
        setStraper(2);
      }
    }
  };

  const handleSeatsChange = (e) => {
    const value = e.target.value;
    setRegisterSeats(value);
  };

  return (
    <>
      <div className="w-[90%] sm:w-[80%] md:w-[90%] !min-h-[60vh] m-auto mt-10 pb-4 z-[9999]">
        <div>
          <div className="flex flex-wrap">
            <div className="text-[24px] font-bold text-[#183864] uppercase mr-1">
              Event:
            </div>
            <div className="text-[24px] font-bold text-wrap text-gray-700">
              {`${spiltCourseName(course.name)} | ${moment.utc(event.startDate).format(
                "MMMM DD"
              )} | ${moment.utc(event?.startDate).format("dddd")} | ${event.campus}`}
            </div>
          </div>
          <div className="mt-10">
            <div className="overflow-x-auto">
              <table className="min-w-[500px] w-full border-0 rounded-lg overflow-hidden">
                <thead>
                  <tr className="text-center bg-slate-600 text-white h-12">
                    <th className="p-2 text-sm font-medium uppercase ">
                      Details
                    </th>
                    <th className="p-2 text-sm font-medium uppercase border-x">
                      Price
                    </th>
                    <th className="p-2 text-sm font-medium uppercase">
                      No. of seats
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white border-0">
                  <tr className="text-center h-12 border-b border-gray">
                    <td className="p-2 ">
                    {`${spiltCourseName(course.name)} | ${moment.utc(event.startDate).format(
                        "MMMM DD"
                      )} | ${moment.utc(event?.startDate).format("dddd")} | ${event.campus}`}
                    </td>
                    <td className="p-2 border-x">$ {course.price} CAD*</td>
                    <td className="p-2 ">
                      <select
                        className="w-full h-full outline-none pl-4"
                        value={registerSeats}
                        onChange={handleSeatsChange}
                        required
                      >
                        <option value="" disabled>
                          Select No. of Seats
                        </option>
                        {seatOptions?.map((seat) => (
                          <option key={seat} value={seat}>
                            {seat}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-end w-full my-2">
              <div>* price does not include taxes</div>
            </div>
            <div className="flex justify-end w-full my-2">
              <div
                onClick={handleAddToCart}
                className="w-auto px-4 py-2 h-[50px] bg-[#B02526] rounded-[8px] flex items-center justify-center text-white text-base font-bold cursor-pointer"
              >
                <div className="uppercase">Add Event to cart</div>
              </div>
            </div>
          </div>
          <div className="w-full !my-10">
            <div className="text-[24px] font-bold text-[#183864] uppercase">
              Details
            </div>
            <div className="flex flex-wrap gap-2 text-[18px] mt-4 text-[#9A9EA6]">
              <div className="font-bold ">Location:</div>
              <div className="text-wrap">{event.campus || "--"}</div>
            </div>
            <div className="flex flex-wrap gap-2 text-[18px] mt-1 text-[#9A9EA6]">
              <div className="font-bold">Date:</div>
              <div>
                {moment.utc(event.startDate).diff(moment.utc(event.endDate), 'days') !== 0 
                  ? `${moment.utc(event.startDate).format("MMMM DD, YYYY")} - ${moment.utc(event.endDate).format("MMMM DD, YYYY")}`
                  : moment.utc(event.startDate).format("MMMM DD, YYYY")
                }
              </div>
            </div>
            <div className="flex flex-wrap gap-2 text-[18px] mt-1 text-[#9A9EA6]">
              <div className="font-bold">Day:</div>
              <div>
                {moment.utc(event.startDate).diff(moment.utc(event.endDate), 'days') !== 0 
                  ? `${moment.utc(event.startDate).format("dddd")} - ${moment.utc(event.endDate).format("dddd")}`
                  : moment.utc(event.startDate).format("dddd")
                }
              </div>
            </div>
            <div className="flex flex-wrap gap-2 text-[18px] mt-1 text-[#9A9EA6]">
              <div className="font-bold">Timing:</div>
              <div className="text-wrap">{event?.timing || "--"}</div>
            </div>
            <div className="flex flex-wrap gap-2 text-[18px] mt-1 text-[#9A9EA6]">
              <div className="font-bold">Contact:</div>
              <div>{event.contact || "--"}</div>
            </div>
            <div className="flex flex-wrap gap-2 text-[18px] mt-1 text-[#9A9EA6]">
              <div className="font-bold ">Address:</div>
              <div className="text-wrap">{event.address || "--"}</div>
            </div>
            <div className="flex flex-wrap gap-2 text-[18px] mt-1 text-[#9A9EA6]">
              <div className="font-bold">Description:</div>
              <div className="text-wrap">{event.description || "--"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventModal;
