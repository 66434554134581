// import React, { useEffect, useState } from "react";
// import Layout from '../components/Layout/Layout';
// import Frequently from "../components/Homepage/FrequentlyAskQuestion";
// import FAQ from "../components/Homepage/FAQ";
// import Testimonials from '../components/testimonials/Testimonials'
// import Blog from '../components/Homepage/Blog'
// import Campus from "../components/Homepage/Campus";
// import HomeBanner from "../components/Homepage/HomeBanner";
// import Cources from "../components/Homepage/Courses";
// import Training from "../components/Homepage/Training";
// import Connect from "../components/Homepage/Connect";
// import axios from "axios";

// const Home = () => {
//   const [HomeData, setHomeData] = useState();
//   const [bannerData, setBannerData] = useState([]);
//   const [connectBannerData, setConnectBannerData] = useState([]);


//   useEffect(() => {
//     const getHomeDetails = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/home`);
//         setHomeData(response?.data.data[0]);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     }
//     getHomeDetails();
//   }, []);

//   useEffect(() => {
//     const fetchBannerData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/homeBanner`);
//         setBannerData(response?.data[0]);
//       } catch (error) {
//         console.error('Error fetching banner data:', error);
//       }
//     };

//     const fetchConnectBannerData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/connectBanners`);
//         setConnectBannerData(response?.data[0]);
//       } catch (error) {
//         console.error('Error fetching banner data:', error);
//       }
//     };


//     fetchBannerData(); // Call the function to fetch banner data
//     fetchConnectBannerData()

//   }, []); // Ensure the effect runs only once by passing an empty dependency array


//   console.log("HomeDataHomeDataHomeData", HomeData);

//   const componentsMap = {
//     "HomeBanner": <HomeBanner data = {bannerData}/>,
//     "Training": <Training data = {HomeData}/>,
//     "Cources": <Cources />,
//     "Testimonials": <Testimonials />,
//     "Frequently": <Frequently />,
//     "FAQ": <FAQ />,
//     "Connect": <Connect data = {connectBannerData}/>,
//     "Blog": <Blog />,
//     "Campus": <Campus />
//   };

//   const orderedComponents = HomeData?.positionComponents ? Object.entries(HomeData.positionComponents)
//     .sort((a, b) => a[1] - b[1])
//     .map(([componentName, _]) => componentsMap[componentName]) : [];

//   return (
//     <Layout>
//       <div>
//         {orderedComponents}
//       </div>
//       {/* <HomeBanner />,
//       <Training />,
//       <Cources />,
//       <Frequently />,
//       <FAQ />,
//       <Testimonials />,
//       <Connect />,
//       <Blog />,
//       <Campus /> */}
//     </Layout>
//   )
// }

// export default Home;



import React, { useEffect, useState } from "react";
import Layout from '../components/Layout/Layout';
import Frequently from "../components/Homepage/FrequentlyAskQuestion";
import FAQ from "../components/Homepage/FAQ";
import Testimonials from '../components/testimonials/Testimonials'
import Blog from '../components/Homepage/Blog'
import Campus from "../components/Homepage/Campus";
import HomeBanner from "../components/Homepage/HomeBanner";
import Cources from "../components/Homepage/Courses";
import Training from "../components/Homepage/Training";
import Connect from "../components/Homepage/Connect";
import axios from "axios";

const Home = () => {
  const [HomeData, setHomeData] = useState();
  const [bannerData, setBannerData] = useState([]);
  const [connectBannerData, setConnectBannerData] = useState([]);

  useEffect(() => {
    const getHomeDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/home`);
        const data = response?.data.data[0];
        if (data && data.positionComponents) {
          const modifiedPositions = adjustComponentPositions(data.positionComponents);
          data.positionComponents = modifiedPositions;
        }
        setHomeData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    getHomeDetails();
  }, []);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/homeBanner`);
        setBannerData(response?.data[0]);
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };

    const fetchConnectBannerData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/connectBanners`);
        setConnectBannerData(response?.data[0]);
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };

    fetchBannerData();
    fetchConnectBannerData();
  }, []);

  const componentsMap = {
    "HomeBanner": <HomeBanner data={bannerData}/>,
    "Training": <Training data={HomeData}/>,
    "Cources": <Cources />,
    "Testimonials": <Testimonials />,
    "Frequently": <Frequently />,
    "FAQ": <FAQ />,
    "Connect": <Connect data={connectBannerData}/>,
    "Blog": <Blog />,
    "Campus": <Campus />
  };

  const orderedComponents = HomeData?.positionComponents ? Object.entries(HomeData.positionComponents)
    .sort((a, b) => a[1] - b[1])
    .map(([componentName, _]) => componentsMap[componentName]) : [];

  return (
    <Layout>
      <div>
        {orderedComponents}
      </div>
       {/* <HomeBanner />,
   <Training />,
   <Cources />,
   <Frequently />,
   <FAQ />,
   <Testimonials />,
   <Connect />,
   <Blog />,
   <Campus /> */}
    </Layout>
  );
}

function adjustComponentPositions(positionComponents) {
  const positions = {...positionComponents};
  const testimonialPos = positions['Testimonials'];
  const faqPos = positions['Frequently'];

  if (faqPos <= testimonialPos) {
    Object.keys(positions).forEach(key => {
      if (positions[key] > testimonialPos) {
        positions[key]++;
      }
    });
    positions['Frequently'] = testimonialPos + 1;
  }

  return positions;
}

export default Home;
