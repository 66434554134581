import React from "react";
import { useState } from "react";
import { createParser } from "eventsource-parser";
import { useRef } from "react";
import { toast } from 'react-toastify';

function Chatbot() {
  const [reply, setReply] = useState("");
  const lastMessageRef = useRef(null);
  const [chat, setChat] = useState([
    {
      role: "bot",
      content: "Hi, I'm Freeadcopy Chatbot. How can I help you?",
    },
  ]);
  const [inputText, setInputText] = useState("");
  const [isResponding, setIsResponding] = useState(false);
  const [showChat, setShowChat] = useState(false);

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (inputText.trim() === "") {
        toast.error("Please enter a message");
      return;
    }

    let userMessage = { role: "user", content: inputText };
    setChat([...chat, userMessage]);
    const prompt = inputText;
    setInputText("");

    setIsResponding(true);
    const chatResponse = await fetch(`/api/chatbot`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: prompt,
      }),
    });

    if (!chatResponse.ok) {
      throw new Error(chatResponse.statusText);
    }

    // This data is a ReadableStream
    const data = chatResponse.body;
    if (!data) {
      return;
    }

    let _reply = "";
    const onParse = (event) => {
      if (event.type === "event") {
        const data = event.data;

        try {
          const text = JSON.parse(data).text ?? "";
          setReply((prev) => prev + text);
          _reply += text;
        } catch (e) {
          console.error(e);
        }
      }
    };

    // https://web.dev/streams/#the-getreader-and-read-methods
    const reader = data.getReader();
    const decoder = new TextDecoder();
    const parser = createParser(onParse);
    let done = false;
    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);
      parser.feed(chunkValue);
      //focus the last message
      lastMessageRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }

    let gptReply = { role: "bot", content: _reply };
    setReply("");
    setChat([...chat, userMessage, gptReply]);
    setIsResponding(false);
  };

  return (
    // <div className='fixed z-50 bottom-5 md:bottom-2 right-10 '>
    //   <div
    //     className={` w-[100%] sm:w-[75%] md:w-[400px] z-10 ms-5 md:ms-0 rounded-lg overflow-hidden transition-all duration-500 ease-in-out
    //     ${showChat ? "h-[450px]" : "h-[0px]"}`}
    //   >
    //     <div className='bg-[#6e48aa] text-white p-2 rounded-t-lg flex flex-row justify-between'>
    //       <h1 className='text-md '>Freeadcopy Chat</h1>
    //       <img
    //         src={CloseIcon}
    //         alt='close'
    //         className='w-5 h-5 hover:cursor-pointer'
    //         onClick={() => setShowChat(!showChat)}
    //       />
    //     </div>
    //     <div className='bg-white md:w-[400px] border-0 rounded-b-lg relative h-[80%] p-1'>
    //       <div className=' h-full p-4 overflow-y-auto chat-messages-container'>
    //         {chat.map((message, index) => (
    //           <div
    //             key={index}
    //             className={`flex mb-4 ${
    //               message.role === "user" && "justify-end"
    //             }`}
    //           >
    //             <div
    //               className={`max-w-[70%] rounded-lg p-2 ${
    //                 message.role === "user"
    //                   ? "bg-[#6e48aa] text-white"
    //                   : "bg-gray-200"
    //               }`}
    //             >
    //               {message.content}
    //             </div>
    //           </div>
    //         ))}
    //         {reply !== "" && (
    //           <div className={`flex mb-4`}>
    //             <div
    //               className={`max-w-[70%] rounded-lg p-2 bg-gray-200`}
    //               ref={lastMessageRef}
    //             >
    //               {reply}
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //     </div>

    //     <div className='flex h-max pt-1.5   border-gray-300'>
    //       <input
    //         type='text'
    //         className='flex-grow p-2 rounded-l-lg border border-gray-300 focus:outline-none focus:ring-0 focus:!border-gray-300'
    //         placeholder='Type your message...'
    //         value={inputText}
    //         onChange={(e) => setInputText(e.target.value)}
    //         onKeyDown={handleInputKeyDown}
    //         disabled={isResponding}
    //       />
    //       <button
    //         className='bg-[#6e48aa] text-white px-4 py-2 rounded-r-md'
    //         onClick={handleSendMessage}
    //       >
    //         <img src={SendIcon} alt='send' className='w-5 h-5' />
    //       </button>
    //     </div>
    //   </div>
    //   <div
    //     className={`flex z-0 absolute bottom-0 right-0 flex-end justify-end hover:cursor-pointer 
    //     overflow-hidden transition-all duration-500 ease-in-out 
    //     ${!showChat ? "h-16" : "h-0"}`}
    //   >
    //     <ChatIcon onClick={() => setShowChat(!showChat)} image={Chat} />
    //   </div>
    // </div>
    <>
    {!showChat ?
      <button
          className="fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-20 h-20 hover:bg-[#B02526] bg-[#183864] m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900 z-[999999] transition-transform duration-200 transform hover:scale-110"
          type="button" aria-haspopup="dialog" aria-expanded="false" data-state="closed"
          onClick={() => setShowChat(true)}
          >
          <svg xmlns=" http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
          className="text-white block border-gray-200 align-middle">
          <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z" className="border-gray-200">
          </path>
          </svg>
      </button>
    :
      <button
        className="fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-20 h-20 bg-[#B02526] hover:bg-[#183864] m-0 cursor-pointer border-gray-200 p-0 normal-case leading-5 hover:text-gray-900 z-[999999] transition-transform duration-200 transform hover:scale-110"
        type="button" aria-haspopup="dialog" aria-expanded="true" data-state="open"
        onClick={() => setShowChat(false)}
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="35" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
          className="text-white block border-gray-200 align-middle">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
     </button>
    }

    {showChat &&
      <div style={{"box-shadow": "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)"}}
      className="fixed bottom-[calc(5rem+1.5rem)] right-0 mr-4 bg-white rounded-lg border border-[#e5e7eb] w-[440px] h-[634px] z-[999999] shadow-2xl">
      
      <div className="bg-[#B02526] w-full p-0">
          <div className="flex flex-col space-y-1.5 p-6">
          <h2 className="font-semibold text-lg tracking-tight text-white">Prosafe Chatbot</h2>
          <p className="text-sm text-white leading-3">Powered by Prosafe A.I.</p>
          </div>
      </div>

      <div className="pr-10 h-[474px] p-6" style={{"min-width": "100%", "display": "table"}}>
        <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1"><span
            className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1"><svg stroke="none" fill="black" strokeWidth="1.5"
                viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                </path>
              </svg></div>
          </span>
          <p className="leading-relaxed"><span className="block font-bold text-gray-700">AI </span>Hi, how can I help you today?
          </p>
        </div>
        <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1"><span
            className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1"><svg stroke="none" fill="black" strokeWidth="0"
                viewBox="0 0 16 16" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z">
                </path>
              </svg></div>
          </span>
          <p className="leading-relaxed"><span className="block font-bold text-gray-700">You </span>fewafef</p>
        </div>
        <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1"><span
            className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1"><svg stroke="none" fill="black" strokeWidth="1.5"
                viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                </path>
              </svg></div>
          </span>
          <p className="leading-relaxed"><span className="block font-bold text-gray-700">AI </span>Sorry, I couldn't find any
            information in the documentation about that. Expect answer to be less accurateI could not find the answer to
            this in the verified sources.</p>
        </div>
      </div>
        <div className="flex items-center pt-0 p-6">
            <form className="flex items-center justify-center w-full space-x-2">
              <input
                className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                placeholder="Type your message" value="" />
              <button
                className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2">
                Send</button>
            </form>
        </div>

      </div>
    }
  </>
  );
}

export default Chatbot;
