import React, { useEffect, useState } from 'react'
import verticalDots from '../assets/verticalDots.svg'
import TrainingHeroTopCircle from '../assets/TrainingHeroTopCircle.svg'
import Vector from '../assets/homeBannerVecotr.svg'
import Vector2 from '../assets/homeBannerVector2.svg'
import aboutuscircle from '../assets/aboutuscircle.svg'
import EmptyCart from '../components/Supplier/Cart/emptyCart';
import ProductCart from '../components/Supplier/Cart/productCart';
import Layout from '../components/Layout/Layout'
import iconsPNG from '../assets/iconsGroup.png';
import FirstAidKit from '../assets/FirstAidKit.png';
import Towel from '../assets/towel.png';
import ActiSplint from '../assets/ActiSplint.png';
import RescueBag from '../assets/RescueBag.png';
import pocketMask from '../assets/pocketMask.png';
import { useLocation } from 'react-router-dom'; 
import Subscribe from '../components/Supplier/Subscribe';

const Cart = () => {
  const [isEmptyCart, setIsEmptyCart] = useState(true) 
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const param1Value = searchParams.get('productId');
    if (param1Value !== '0') {
      setIsEmptyCart(true);
    } else {
      setIsEmptyCart(false);
    }
  }, [location.search]); 
  const bubbleSVG1 = (<svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="47" y="47" width="47" height="47" rx="23.5" transform="rotate(180 47 47)" fill="#E9EFFF"/>
  </svg>)

  return (
    <Layout>
      <div className='relative flex h-full lg:h-[704px] overflow-hidden mb-5'>
        <div className='absolute left-[-25px] top-5 md:top-1/4'>
          <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
        </div>
        <div className='md:flex hidden absolute right-[-25px] top-[10%]'>
          <img src={verticalDots} alt=''/>
        </div>
        {!isEmptyCart &&
          <div className='xl:flex hidden absolute right-[10%] z-50'>
            <img className='md:w-[320px] md:h-[320px] w-[175px] h-[176px]' src={TrainingHeroTopCircle} alt=''/>
          </div>
        }
        <div className='md:flex hidden absolute left-[15%] top-[50%] -z-[1]'>
          <img src={aboutuscircle} alt=''/>
        </div>
        <img className="lg:flex hidden absolute -bottom-[10px] z-10 w-full" src={Vector} alt="" />
        <img className="lg:flex hidden absolute bottom-[0px] w-full" src={Vector2} alt="" />
        {isEmptyCart ? 
          <ProductCart />
          :
          <EmptyCart />
        }
      </div>
      <div className='w-[95%] m-auto'>
        <div>
          <div className="font-[500] text-[20px] leading-[30px] text-[#5E6282]">Featured collection</div>
          <div className='justify-items-center gap-2 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-10'>
            <div className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <div className='flex items-center justify-center h-[60%]'>
                <img src={ActiSplint} alt="Rescue bag" />
              </div>
              <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>WorkSafeBC Basic First Aid Kit</div>
              <div className='flex justify-between items-end mx-10'>
                <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$29.00 CAD</div>
                <div className='relative w-[20px] cursor-pointer'>
                  <div>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                    </svg>
                  </div>
                  <div className='absolute -top-2 -right-3'>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <div className='flex items-center justify-center h-[60%]'>
                <img src={Towel} alt="Rescue bag" />
              </div>
              <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>Mix Fibre Blanket</div>
              <div className='flex justify-between items-end mx-10'>
                <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$19.00 CAD</div>
                <div className='relative w-[20px] cursor-pointer'>
                  <div>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                    </svg>
                  </div>
                  <div className='absolute -top-2 -right-3'>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <div className='flex items-center justify-center h-[60%]'>
                <img src={FirstAidKit} alt="Rescue bag" />
              </div>
              <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>Personal Deluxe First Aid Kit</div>
              <div className='flex justify-between items-end mx-10 bg-white'>
                <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$19.00 CAD</div>
                <div className='relative w-[20px] cursor-pointer'>
                  <div>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                    </svg>
                  </div>
                  <div className='absolute -top-2 -right-3'>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='rounded-[14px] w-[296px] h-[300px] bg-white' style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <div className='flex items-center justify-center h-[60%]'>
                <img src={pocketMask} alt="Rescue bag" />
              </div>
              <div className='font-[800] text-[14px] leading-[17.64px] mt-10 ml-10'>Pocket Mask in Poly bag</div>
              <div className='flex justify-between items-end mx-10 bg-white'>
                <div className='text-[#B02526] font-[600] text-[16px] leading-[16px] mt-5'>$9.99 CAD</div>
                <div className='relative w-[20px] cursor-pointer'>
                  <div>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 16C14.89 16 14 16.89 14 18C14 18.5304 14.2107 19.0391 14.5858 19.4142C14.9609 19.7893 15.4696 20 16 20C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18C18 17.4696 17.7893 16.9609 17.4142 16.5858C17.0391 16.2107 16.5304 16 16 16ZM0 0V2H2L5.6 9.59L4.24 12.04C4.09 12.32 4 12.65 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H18V13H6.42C6.3537 13 6.29011 12.9737 6.24322 12.9268C6.19634 12.8799 6.17 12.8163 6.17 12.75C6.17 12.7 6.18 12.66 6.2 12.63L7.1 11H14.55C15.3 11 15.96 10.58 16.3 9.97L19.88 3.5C19.95 3.34 20 3.17 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H4.21L3.27 0M6 16C4.89 16 4 16.89 4 18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20C6.53043 20 7.03914 19.7893 7.41421 19.4142C7.78929 19.0391 8 18.5304 8 18C8 17.4696 7.78929 16.9609 7.41421 16.5858C7.03914 16.2107 6.53043 16 6 16Z" fill="#183864"/>
                    </svg>
                  </div>
                  <div className='absolute -top-2 -right-3'>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.5 6.49899H6.5V9.49899H5.5V6.49899H2.5V5.49899H5.5V2.49899H6.5V5.49899H9.5V6.49899Z" fill="#183864"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='my-10'>
          <Subscribe />
        </div>
      </div>
    </Layout>
  )
}

export default Cart;
