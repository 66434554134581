import React from "react";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";

const ExcitingNewsModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border bg-white py-12 px-16  rounded-lg shadow-lg"
      contentLabel="Exciting News"
    >
      <button onClick={onRequestClose} className="absolute top-2 right-2">
        <RxCross2 className="h-5 w-5" />
      </button>
      <div className="text-2xl font-semibold">
        Exciting news will show here :)
      </div>
    </Modal>
  );
};

export default ExcitingNewsModal;
