import React from 'react'
import verticalDots from '../../assets/verticalDots.svg'
import trainingTopImage from '../../assets/trainingTopImage.png'
import trainingTopLeftImage from '../../assets/trainingTopLeftImage.png'
import trainingHeroVector1 from '../../assets/trainingHeroVector1.svg'
import TrainingHeroTopCircle from '../../assets/TrainingHeroTopCircle.svg'
import TrainingHero from '../../assets/TrainingHero.png'

const HeroSection = () => {

  const bubbleSVG1 = (<svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="47" y="47" width="47" height="47" rx="23.5" transform="rotate(180 47 47)" fill="#E9EFFF"/>
  </svg>
  )
  return (
    <div className='relative flex h-[230px] md:h-[404px] overflow-hidden mb-5'>
       <div className='absolute left-[54%] top-5 md:top-0'>
        <img className='md:w-[85px] w-[65px]' src={trainingTopImage} alt=''/>
      </div>
      <div className='absolute left-[35px] top-5 md:top-[75%]'>
        <img className='md:w-[85px] w-[65px]' src={trainingTopLeftImage} alt=''/>
      </div>
      {/* <div className='absolute w-full left-[-25px] top-5  md:top-[75%]'>
        <img className='w-full' src={trainingHeroVector1} alt=''/>
      </div> */}
      <div className='absolute left-[-25px] top-5 md:top-1/4'>
        <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
      </div>
      <div className='md:flex hidden absolute right-[-25px] top-[10%]'>
        <img src={verticalDots} alt=''/>
      </div>
      {/* <div className='absolute bottom-10'>
        <img src={heroSectionVectorBg} alt=''/>
      </div>
      <div className='absolute bottom-0'>
        <img src={heroSectionVectorBG2} alt=''/>
      </div> */}
      <div className='absolute bottom-0 right-0 md:right-[11%]'>
        <img className='md:w-[320px] md:h-[320px] w-[175px] h-[176px]' src={TrainingHeroTopCircle} alt=''/>
      </div>
      <div className='absolute bottom-5 right-0 md:right-[15%]'>
        <img className='md:w-[250px] md:h-[250px] w-[175px] h-[176px]' src={TrainingHero} alt=''/>
      </div>
      <div className='md:flex hidden absolute top-1/3 left-1/3'>{bubbleSVG1}</div>
      <div className='flex justify-center items-center w-2/3 md:w-2/3 lg:w-full'>
        <div className='text-[#183864] font-semibold text-[40px] md:text-[55px] lg:text-[71px] leading-[43.55px] lg:leading-[71px] text-center'>Training</div>
      </div>
    </div>
  )
}

export default HeroSection
