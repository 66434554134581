import React, { useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
   const navigate = useNavigate();
   const [otps, setOtps] = useState( new Array(6).fill(""));
   const [studentID, setStudentID] = useState("")
   const [isEmailsent, setIsEmailSent] = useState(false)
   const [isForget, setIsForget] = useState(false)
   const [email, setEmail] = useState("")
   const [studentEmail, setStudentEmail] = useState("")
   const handleBackspace = (element) => {
    if (!element.value && element.previousSibling) {
      element.previousSibling.focus();
    }
  };

  const handleChange = (element, digitIndex) => {
    if (isNaN(element.value) && element.value !== '') return;

    const newOtps = [...otps];
    newOtps[digitIndex] = element.value;
    console.log("otps", newOtps)
    setOtps(newOtps);


    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior
    const pasteData = event.clipboardData.getData("Text");

    // Only process if the pasted data is 6 digits
    if (pasteData.length === 6 && /^\d+$/.test(pasteData)) {
      setOtps(pasteData.split(""));

      // Automatically focus the last input after pasting
      const inputs = document.querySelectorAll("input[type='text']");
      inputs[inputs.length - 1].focus();
    }
  };

  const handleInputChange = (e) => {
    console.log("enter", e.target.value)
    // Allow only numbers as input
    const input = e.target.value.replace(/[^0-9]/g, ''); 
    setStudentID(input);
  };
  const handleEmailChange = (e) => {
    setStudentEmail(e.target.value);
  };

  const handleLogin = async () => {
      if (studentID.length < 6) {
        toast.error(`Please enter a valid Student ID`);
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/students/findbyID`, {
            studentID: studentID,
        }, {
            validateStatus: (status) => {
              return status < 500;
            },
          });
  
        if (response.data.success) {
          setIsEmailSent(true)
          setEmail(response.data?.email)
          // setStudentID("")
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(`An error occurred while Sending OTP`);
      }
  };

  const handleVerifyOTP = async () => {
    const otpCode = otps.join("");
    if (otpCode.length !== 6) {
      toast.error(`Please enter a 6-digit OTP.`);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/event/verify-otp`, {
        email,
        otp: otpCode,
      }, {
          validateStatus: (status) => {
            return status < 500;
          },
        });

      if (response.data.success) {
        toast.success(response.data.message);
        setOtps(new Array(6).fill(""))
        localStorage.setItem("studentID", studentID);
        navigate('/student/dashboard');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(`An error occurred while Sending OTP`);
    }
  };

  const handleSendEmail = async () => {

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/students/findbyemail`, {
        email: studentEmail,
      }, {
          validateStatus: (status) => {
            return status < 500;
          },
        });

      if (response.data.success) {
        toast.success(response.data.message);
        setStudentEmail("")
        setIsForget(false)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(`An error occurred while Sending OTP`);
    }
  };

  return (
    <div className="min-h-screen bg-purple-900 flex flex-col items-center justify-center">
    <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900 to-[#073967]"></div>
    <div className="relative z-10 bg-transparent rounded-3xl shadow-xl flex flex-col sm:flex-row justify-center">
      <div className="hidden lg:flex flex-col text-gray-300 px-14 max-w-md">
        <h1 className="font-semibold text-4xl my-3">Welcome back</h1>
        <p className="text-sm opacity-75 pr-3">
          You can login using your student ID we sent you while registration, and 
          can see the complete history of all your previous course
        </p>
      </div>

      <div className="p-12 bg-white rounded-3xl w-96 mx-auto">
        <div className="mb-7">
          <h3 className="text-2xl font-semibold text-gray-800">Sign In</h3>
        </div>

        <div className="space-y-6">
            {(!isEmailsent && !isForget) &&
                <div className="flex items-center">
                    <span className="text-sm px-3 py-3 bg-gray-200 border border-r-0 border-gray-200 rounded-l-lg">
                        ST-
                    </span>
                    <input
                        type="text"
                        placeholder="Enter Student ID"
                        value={studentID}
                        onChange={handleInputChange}
                        className="w-full text-sm px-4 py-3 bg-gray-200 border border-gray-200 rounded-r-lg focus:outline-none focus:border-[#073967] focus:bg-gray-100"
                    />
                </div>
            }
          {(!isEmailsent && !isForget) &&
            <div className="text-sm ml-auto" onClick={() => {
                setIsEmailSent(false)
                setIsForget(true)
            }}>
                <a href="#" className="text-[#073967] hover:text-purple-600">
                Forgot your Student ID?
                </a>
            </div> 
          }
          {isEmailsent &&
            <div className="flex space-x-2 justify-center">
                    {otps.map((digit, digitIndex) => (
                        <input
                        key={digitIndex}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleChange(e.target, digitIndex)}
                        onPaste={handlePaste}
                        onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                            handleBackspace(e.target, digitIndex);
                            }
                        }}
                        className={`w-12 h-12 text-center text-black text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                        />
                    ))}
            </div>
          }
            {isForget &&
                <input
                    type="email"
                    placeholder="Enter Email"
                    value={studentEmail}
                    onChange={handleEmailChange}
                    className="w-full text-sm px-4 py-3 bg-gray-200 border border-gray-200 rounded-r-lg focus:outline-none focus:border-[#073967] focus:bg-gray-100"
                />
            }

          <button
            type="submit"
            className="w-full flex justify-center bg-[#073967] hover:bg-[#073967]/90 text-gray-100 p-3 rounded-lg font-semibold transition ease-in duration-500"
            onClick={(!isEmailsent && !isForget) ? handleLogin : (isEmailsent && !isForget) ? handleVerifyOTP : (!isEmailsent && isForget) ? handleSendEmail : ""}
          >
            {(!isEmailsent && !isForget) ? "Sign in" : (isEmailsent && !isForget) ? "Verify OTP" : (!isEmailsent && isForget) ? "Send Email" : ""}
            {/* {!isEmailsent ? "Sign in" : "Verify OTP"}
            {!isEmailsent ? "Sign in" : "Verify OTP"} */}
          </button>
        </div>

        <div className="mt-7 text-center text-gray-300 text-xs">
          <span>
            Copyright © 2021-2023{' '}
            {/* <a href="https://codepen.io/uidesignhub" className="text-purple-500 hover:text-purple-600">
              AJI
            </a> */}
          </span>
        </div>
      </div>
    </div>

    <svg className="absolute bottom-0 left-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#fff" d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z" />
    </svg>
    </div>
  )
}

export default LoginPage
