import React from "react";
import Header from './Header'
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from 'axios';
import { useEffect } from "react";
import { useState } from "react";
import Chatbot from "../ChatBot/Chatbot";

const Layout = ({ children }) => {
  const [headerData, setHeaderData] = useState();
  const [footerData, setFooterData] = useState();

  const getHeaderData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/header`)
    .then(response => {
      console.log(response.data[0]);
      setHeaderData(response.data[0])
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }
  const getFooterData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/footer`)
    .then(response => {
      console.log(response.data[0]);
      setFooterData(response.data[0])
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }
  useEffect(() => {
    getHeaderData();
    getFooterData();
  }, [])
  return (
    <>
      <div className="flex flex-col min-h-screen justify-between">
        <div className="flex flex-col">
          <Header
            headerData={headerData}
          />
          {/* <Navbar /> */}
        </div>
        <div className="w-full">
          {children}
          <Chatbot />
        </div>
        <Footer
          footerData={footerData}
        />
      </div>
    </>
  )
}
export default Layout;
