import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const scrollRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const handleScroll = (index) => {
      if (scrollRefs[index].current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRefs[index].current;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
          setBlogs((prevItems) => [...prevItems, ...prevItems.slice(0, 3)]); // Adjust slice size as needed
        }
      }
    };

    scrollRefs.forEach((ref, index) => {
      const scrollElement = ref.current;
      if (scrollElement) {
        scrollElement.addEventListener('scroll', () => handleScroll(index));
      }
    });

    return () => {
      scrollRefs.forEach((ref, index) => {
        const scrollElement = ref.current;
        if (scrollElement) {
          scrollElement.removeEventListener('scroll', () => handleScroll(index));
        }
      });
    };
  }, [blogs]);

  useEffect(() => {
    const getAllBlogs = () => {
      axios.get(`${process.env.REACT_APP_BASE_URL}/blogs`)
        .then(response => {
          const sortedBlogs = response?.data?.data.sort((a, b) => a.order - b.order);
          setBlogs(sortedBlogs);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    };
    getAllBlogs();
  }, []);


  return (
    <>
      <div className="">
        <div className="flex justify-between w-[90%] m-auto">
          <div className="md:text-3xl lg:text-[48px] leading-[50px] font-[800] md:pt-0 pt-1">Recent Blog Of Us</div>
          <div className="flex justify-center items-center gap-3">
            <Link to={`/blog`} className="text-[17px] leading-[60px] font-semibold text-[#B02526]">See All Post</Link>
            <div>
              <svg className="bubbling-svg" width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.73242 1.9043L6.00004 7.22249L1.73242 12.5407" stroke="#B02526" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        </div>
        <div className="flex md:max-h-[772px] md:overflow-hidden lg:mt-7">
          <div
            ref={scrollRefs[0]}
            className="w-[310px] m-auto gap-5 flex flex-col justify-start mt-7 lg:pt-10 custom-scrollbar"
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
          >
            {blogs?.slice(0)?.map((item, index) => (
              <motion.div
                key={index}
                className="flex flex-col w-[290px] p-3 rounded-[13.34px] cursor-pointer transform hover:scale-105 transition-transform duration-300"
                style={{ boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.11)' }}
                animate={{
                  y: [0, -1000],
                }}
                transition={{
                  duration: 40,
                  ease: "linear",
                  loop: Infinity,
                  repeatDelay: 0,
                }}
              >
                <div className="flex justify-center">
                  <img className="object-contain w-[262px] h-[262px] rounded-md" src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`} alt="" />
                </div>
                <div className="flex text-xs leading-[24.02px] font-bold text-[#183864] w-[262px] mt-6">{item?.title}</div>
                <div className="font-normal text-[8px] leading-[10.41px] text-[#ADADAD] w-[262px]">{item?.date}</div>
                <div className="font-normal text-[9.34px] leading-[14.01px] text-[#777777] mt-3 w-[262px]">{item?.desc}</div>
                <Link to={`/blog-inside?id=${item?._id}`} className="text-[#B02526] font-semibold text-[8.01px] leading-[16.01px] mt-2 mb-4 w-[262px]">Read More »</Link>
              </motion.div>
            ))}
          </div>

          <div
            ref={scrollRefs[1]}
            className="w-[310px] m-auto gap-5 flex flex-col justify-start mt-7 lg:pt-52 custom-scrollbar"
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
          >
            {blogs?.slice(1)?.map((item, index) => (
              <motion.div
                key={index}
                className="flex flex-col w-[290px] p-3 rounded-[13.34px] cursor-pointer transform hover:scale-105 transition-transform duration-300"
                style={{ boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.11)' }}
                animate={{
                  y: [0, -1000],
                }}
                transition={{
                  duration: 40,
                  ease: "linear",
                  loop: Infinity,
                  repeatDelay: 0,
                }}
              >
                <div className="flex justify-center">
                  <img className="object-contain w-[262px] h-[262px] rounded-md" src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`} alt="" />
                </div>
                <div className="flex text-xs leading-[24.02px] font-bold text-[#183864] w-[262px] mt-6">{item?.title}</div>
                <div className="font-normal text-[8px] leading-[10.41px] text-[#ADADAD] w-[262px]">{item?.date}</div>
                <div className="font-normal text-[9.34px] leading-[14.01px] text-[#777777] mt-3 w-[262px]">{item?.desc}</div>
                <Link to={`/blog-inside?id=${item?._id}`} className="text-[#B02526] font-semibold text-[8.01px] leading-[16.01px] mt-2 mb-4 w-[262px]">Read More »</Link>
              </motion.div>
            ))}
          </div>

          <div
            ref={scrollRefs[2]}
            className="w-[310px] m-auto gap-5 flex flex-col justify-start mt-7 lg:mt-10 custom-scrollbar"
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
          >
            {blogs?.slice(2)?.map((item, index) => (
              <motion.div
                key={index}
                className="flex flex-col w-[290px] p-3 rounded-[13.34px] cursor-pointer transform hover:scale-105 transition-transform duration-300"
                style={{ boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.11)' }}
                animate={{
                  y: [0, -1000],
                }}
                transition={{
                  duration: 40,
                  ease: "linear",
                  loop: Infinity,
                  repeatDelay: 0,
                }}
              >
                <div className="flex justify-center">
                  <img className="object-contain w-[262px] h-[262px] rounded-md" src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`} alt="" />
                </div>
                <div className="flex text-xs leading-[24.02px] font-bold text-[#183864] w-[262px] mt-6">{item?.title}</div>
                <div className="font-normal text-[8px] leading-[10.41px] text-[#ADADAD] w-[262px]">{item?.date}</div>
                <div className="font-normal text-[9.34px] leading-[14.01px] text-[#777777] mt-3 w-[262px]">{item?.desc}</div>
                <Link to={`/blog-inside?id=${item?._id}`} className="text-[#B02526] font-semibold text-[8.01px] leading-[16.01px] mt-2 mb-4 w-[262px]">Read More »</Link>
              </motion.div>
            ))}
          </div>

          <div
            ref={scrollRefs[3]}
            className="w-[310px] m-auto gap-5 flex flex-col justify-start mt-7 lg:pt-52 custom-scrollbar"
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
          >
            {blogs?.slice(3)?.map((item, index) => (
              <motion.div
                key={index}
                className="flex flex-col w-[290px] p-3 rounded-[13.34px] cursor-pointer transform hover:scale-105 transition-transform duration-300"
                style={{ boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.11)' }}
                animate={{
                  y: [0, -1000],
                }}
                transition={{
                  duration: 40,
                  ease: "linear",
                  loop: Infinity,
                  repeatDelay: 0,
                }}
              >
                <div className="flex justify-center">
                  <img className="object-contain w-[262px] h-[262px] rounded-md" src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`} alt="" />
                </div>
                <div className="flex text-xs leading-[24.02px] font-bold text-[#183864] w-[262px] mt-6">{item?.title}</div>
                <div className="font-normal text-[8px] leading-[10.41px] text-[#ADADAD] w-[262px]">{item?.date}</div>
                <div className="font-normal text-[9.34px] leading-[14.01px] text-[#777777] mt-3 w-[262px]">{item?.desc}</div>
                <Link to={`/blog-inside?id=${item?._id}`} className="text-[#B02526] font-semibold text-[8.01px] leading-[16.01px] mt-2 mb-4 w-[262px]">Read More »</Link>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
