import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import footerLogo from '../../assets/footerLogo.png';

const Footer = ({footerData}) => {
  const [menu2, setMenu2] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    if (footerData) {
      const data = footerData?.menu2[0]?.split(',');
      setMenu2(data);
    }
  }, [footerData])
  return (
    <div className="" style={{background: 'rgba(249,249, 249)'}}>
      <div className="w-[95%] hidden lg:flex m-auto p-10">
        <div className="w-[30%] xl:w-[45%]">
          <div className="mb-4 w-fit">
            <img src={`${process.env.REACT_APP_BASE_URL}/${footerData?.logo}`} alt="Footer Logo" width={75} height={75} />
            {/* <img src={footerLogo} alt="Prosafe" /> */}
            <div className="text-[#1C2539] font-[600] text-[24px] leading-[36px] mb-4 flex justify-center mt-2">Ready To</div>
          </div>
          <div className="flex flex-col xl:flex-row gap-5 w-full mb-4">
            <div className="flex items-center gap-3">
              <div className="rounded-[30px] bg-[#E3EBFD] w-[48px] h-[48px] flex justify-center items-center">
                <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.4395 4.96094C20.5957 4.84375 20.8301 4.96094 20.8301 5.15625V13.125C20.8301 14.1797 19.9707 15 18.9551 15H2.70508C1.65039 15 0.830078 14.1797 0.830078 13.125V5.15625C0.830078 4.96094 1.02539 4.84375 1.18164 4.96094C2.08008 5.66406 3.21289 6.52344 7.19727 9.41406C8.01758 10 9.42383 11.2891 10.8301 11.2891C12.1973 11.2891 13.6426 10 14.4238 9.41406C18.4082 6.52344 19.541 5.66406 20.4395 4.96094ZM10.8301 10C9.89258 10.0391 8.60352 8.86719 7.93945 8.39844C2.74414 4.64844 2.35352 4.29688 1.18164 3.35938C0.947266 3.20312 0.830078 2.92969 0.830078 2.61719V1.875C0.830078 0.859375 1.65039 0 2.70508 0H18.9551C19.9707 0 20.8301 0.859375 20.8301 1.875V2.61719C20.8301 2.92969 20.6738 3.20312 20.4395 3.35938C19.2676 4.29688 18.877 4.64844 13.6816 8.39844C13.0176 8.86719 11.7285 10.0391 10.8301 10Z" fill="#183864"/>
                </svg>
              </div>
              <div className="text-[#183864] text-[16px] font-bold">{footerData?.email}</div>
            </div>
            <div className="flex items-center gap-3">
              <div className="rounded-[30px] bg-[#E3EBFD] w-[48px] h-[48px] flex justify-ceźter items-center">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.45703 10.0007C6.45703 14.5732 8.49953 16.749 9.38786 17.4857C9.56453 17.6315 9.81036 17.709 10.1162 17.709L11.7187 17.709C12.3554 17.709 12.8662 17.0573 12.662 16.3598L12.1737 14.6932C12.042 14.2448 11.6512 13.959 11.2304 13.959L10.717 13.959L10.717 13.334C10.717 13.959 10.7162 13.959 10.7154 13.959L10.6854 13.959C10.638 13.9569 10.5909 13.9507 10.5445 13.9407C10.467 13.9257 10.372 13.899 10.267 13.8507C10.0529 13.7532 9.81703 13.579 9.60203 13.2865C9.18286 12.7148 8.87036 11.7248 8.87036 10.0007C8.87036 8.27565 9.18286 7.28648 9.60203 6.71482C9.8162 6.42232 10.052 6.24815 10.267 6.15065C10.3871 6.09583 10.5151 6.06039 10.6462 6.04565L10.6862 6.04315L10.7029 6.04315L10.7095 6.04232L10.7154 6.04232C10.7154 6.04232 10.717 6.04232 10.717 6.66732L10.717 6.04232L11.0404 6.04232C11.512 6.04232 11.9387 5.68315 12.0154 5.15898L12.2595 3.49232C12.3562 2.83815 11.8662 2.29232 11.2845 2.29232L9.5137 2.29232C9.14703 2.29232 8.8587 2.40648 8.67786 2.60898C7.92953 3.44565 6.45703 5.63898 6.45703 10.0007ZM10.777 7.29232L11.0404 7.29232C12.1629 7.29232 13.0904 6.44732 13.2529 5.34065L13.497 3.67399C13.697 2.31232 12.6762 1.04232 11.2845 1.04232L9.5137 1.04232C8.90286 1.04232 8.2287 1.23398 7.7462 1.77565C6.7962 2.83898 5.20703 5.32648 5.20703 10.0007C5.20703 15.009 7.4687 17.5182 8.59036 18.4482C9.05036 18.829 9.60703 18.959 10.1162 18.959L11.7187 18.959C13.2462 18.959 14.2845 17.4507 13.8612 16.009L13.3729 14.3423C13.0929 13.384 12.2304 12.709 11.2312 12.709L10.777 12.709C10.7102 12.6684 10.6533 12.6134 10.6104 12.5482C10.4187 12.2865 10.1204 11.6098 10.1204 10.0007C10.1204 8.39232 10.4187 7.71482 10.6104 7.45315C10.6895 7.34482 10.752 7.30482 10.777 7.29232Z" fill="#183864"/>
                </svg>
              </div>
              <div className="text-[#183864] text-[16px] font-bold">{footerData?.phone}</div>
            </div>
          </div>
          <div className="w-[162px] h-[53px] rounded-[10px] bg-[#B02526]" onClick={() => navigate("/contact")}>
            <div className="text-[16px] leading-[28.8px] font-[700] text-white flex items-center justify-center w-full h-full cursor-pointer rounded-lg transition-colors duration-300 hover:bg-blue-500 shadow-custom transform hover:scale-110 transition-transform duration-300">{footerData?.button_text}</div>
          </div>
        </div>
        <div className="w-[70%] xl:w-[55%]">
          <div className="flex w-full h-full">
            <div className="text-[#1C2539] font-[600] text-[24px] leading-[30px] w-[50%]">{footerData?.menu1[0]}</div>
            <div className="flex flex-col justify-between">
              <div className="text-[#1C2539] font-[600] text-[24px] leading-[50px] w-[385px]">
                {menu2?.length > 0 &&
                  menu2.map((item, index) => {
                    return (
                         <a  key={index} href="#" className="inline-block relative hover:underline transform hover:scale-105 transition-transform duration-300">
                          {item}
                        </a>
                    )
                  })
                }
              </div>
              <div className="flex justify-end gap-2">
                <div className="flex text-[15px] leading-[27px] font-[500]">Find ProSafe Training on</div>
                <div className="flex gap-2">
                  <div>
                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.9">
                        <path d="M21.9625 10.75C21.9625 16.0781 18.0523 20.5039 12.9391 21.2773V13.8438H15.4313L15.9039 10.75H12.9391V8.77344C12.9391 7.91406 13.3688 7.09766 14.7008 7.09766H16.0328V4.47656C16.0328 4.47656 14.8297 4.26172 13.6266 4.26172C11.2203 4.26172 9.63047 5.76562 9.63047 8.42969V10.75H6.92344V13.8438H9.63047V21.2773C4.51719 20.5039 0.650001 16.0781 0.650001 10.75C0.650001 4.86328 5.41953 0.09375 11.3063 0.09375C17.193 0.09375 21.9625 4.86328 21.9625 10.75Z" fill="#090C0A"/>
                        <path d="M0.306251 21.1484H22.3063V22.2227H0.306251V21.1484Z" fill="#090C0A"/>
                      </g>
                    </svg>
                  </div>
                  <div>
                    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.9">
                        <path d="M18.452 0.125C19.1824 0.125 19.827 0.769531 19.827 1.54297V18C19.827 18.7734 19.1824 19.375 18.452 19.375H1.90899C1.17852 19.375 0.576954 18.7734 0.576954 18V1.54297C0.576954 0.769531 1.17852 0.125 1.90899 0.125H18.452ZM6.37774 16.625V7.47266H3.5418V16.625H6.37774ZM4.95977 6.18359C5.86211 6.18359 6.59258 5.45312 6.59258 4.55078C6.59258 3.64844 5.86211 2.875 4.95977 2.875C4.01445 2.875 3.28399 3.64844 3.28399 4.55078C3.28399 5.45312 4.01445 6.18359 4.95977 6.18359ZM17.077 16.625V11.5977C17.077 9.14844 16.5184 7.21484 13.6395 7.21484C12.2645 7.21484 11.3191 7.98828 10.9324 8.71875H10.8895V7.47266H8.18242V16.625H11.0184V12.1133C11.0184 10.9102 11.2332 9.75 12.7371 9.75C14.198 9.75 14.198 11.125 14.198 12.1562V16.625H17.077Z" fill="#090C0A"/>
                        <path d="M0.576954 20.1484H19.827V21.2227H0.576954V20.1484Z" fill="#090C0A"/>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-10 px-4 lg:hidden flex flex-col">
        <div>
          <div className="flex justify-between">
            <div>
              <img src={footerLogo} alt="Prosafe" />
            </div>
            <div className="">
              <div className="text-[#1C2539] font-[600] text-[20px] leading-[30px] mb-4 flex justify-center mt-2">Ready To</div>
              <div className="w-[124.62px] h-[40.77px] rounded-[8px] bg-[#B02526]">
                <div className="text-[12px] leading-[22px] font-[700] text-white flex items-center justify-center w-full h-full cursor-pointer rounded-lg transition-colors duration-300 hover:bg-blue-500 shadow-custom transform hover:scale-110 transition-transform duration-300">Contact ProSafe</div>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col justify-between w-full my-10">
            <div className="flex items-center gap-1">
              <div className="flex justify-center items-center">
                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.208984" width="40.6239" height="40.6239" rx="20.3119" fill="#E3EBFD"/>
                  <path d="M28.5101 18.3513C28.6423 18.2521 28.8407 18.3513 28.8407 18.5166V25.2608C28.8407 26.1534 28.1134 26.8477 27.2538 26.8477H13.5009C12.6083 26.8477 11.9141 26.1534 11.9141 25.2608V18.5166C11.9141 18.3513 12.0794 18.2521 12.2116 18.3513C12.972 18.9464 13.9307 19.6737 17.3028 22.1201C17.9971 22.616 19.1872 23.707 20.3774 23.707C21.5345 23.707 22.7577 22.616 23.4189 22.1201C26.791 19.6737 27.7497 18.9464 28.5101 18.3513ZM20.3774 22.616C19.5839 22.6491 18.493 21.6573 17.9309 21.2606C13.534 18.0868 13.2034 17.7893 12.2116 16.9958C12.0132 16.8636 11.9141 16.6322 11.9141 16.3677V15.7396C11.9141 14.88 12.6083 14.1527 13.5009 14.1527H27.2538C28.1134 14.1527 28.8407 14.88 28.8407 15.7396V16.3677C28.8407 16.6322 28.7084 16.8636 28.5101 16.9958C27.5183 17.7893 27.1877 18.0868 22.7907 21.2606C22.2287 21.6573 21.1377 22.6491 20.3774 22.616Z" fill="#183864"/>
                </svg>

              </div>
              <div className="text-[#183864] text-[13.54px] leading-[17.06px] font-bold">info@prosafefirstaid.ca</div>
            </div>
            <div className="flex items-center gap-1">
              <div className="flex justify-center items-center">
                <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.816406" width="40.6239" height="40.6239" rx="20.3119" fill="#E3EBFD"/>
                  <path fillRule="evenodd" strokeWidth="evenodd" d="M18.1282 20.3109C18.1282 24.1807 19.8569 26.0222 20.6087 26.6457C20.7582 26.7691 20.9663 26.8347 21.2251 26.8347L22.5813 26.8347C23.1202 26.8347 23.5525 26.2831 23.3797 25.6928L22.9664 24.2823C22.855 23.9028 22.5242 23.6609 22.168 23.6609L21.7336 23.6609L21.7336 23.132C21.7336 23.6609 21.7329 23.6609 21.7322 23.6609L21.7068 23.6609C21.6667 23.6591 21.6268 23.6539 21.5876 23.6454C21.522 23.6327 21.4416 23.6101 21.3527 23.5692C21.1715 23.4867 20.9719 23.3393 20.7899 23.0918C20.4352 22.608 20.1707 21.7701 20.1707 20.3109C20.1707 18.851 20.4352 18.0138 20.7899 17.53C20.9712 17.2824 21.1708 17.135 21.3527 17.0525C21.4543 17.0061 21.5627 16.9761 21.6736 16.9636L21.7075 16.9615L21.7216 16.9615L21.7272 16.9608L21.7322 16.9608C21.7322 16.9608 21.7336 16.9608 21.7336 17.4898L21.7336 16.9608L22.0072 16.9608C22.4064 16.9608 22.7675 16.6568 22.8324 16.2132L23.0391 14.8027C23.1209 14.249 22.7062 13.7871 22.2139 13.7871L20.7152 13.7871C20.4049 13.7871 20.1608 13.8837 20.0078 14.0551C19.3744 14.7632 18.1282 16.6195 18.1282 20.3109ZM21.7844 18.0187L22.0072 18.0187C22.9572 18.0187 23.7422 17.3036 23.8797 16.367L24.0864 14.9564C24.2557 13.804 23.3917 12.7292 22.2139 12.7292L20.7152 12.7292C20.1982 12.7292 19.6276 12.8914 19.2193 13.3498C18.4153 14.2497 17.0703 16.355 17.0703 20.3109C17.0703 24.5496 18.9844 26.6732 19.9337 27.4602C20.323 27.7826 20.7942 27.8926 21.2251 27.8926L22.5813 27.8926C23.8741 27.8926 24.7529 26.616 24.3946 25.3959L23.9813 23.9854C23.7443 23.1743 23.0144 22.603 22.1687 22.603L21.7844 22.603C21.7279 22.5687 21.6797 22.5222 21.6433 22.4669C21.4811 22.2454 21.2286 21.6728 21.2286 20.3109C21.2286 18.9497 21.4811 18.3763 21.6433 18.1548C21.7103 18.0632 21.7632 18.0293 21.7844 18.0187Z" fill="#183864"/>
                </svg>
              </div>
              <div className="text-[#183864] text-[13.54px] leading-[17.06px] font-bold">6045857233</div>
            </div>
          </div>
          <div className="mb-20">
            <div className="font-[600] text-[18px] leading-[19.35px] mb-1">About ProSafe</div>
            <div>
            <div className="font-semibold text-base leading-[32.25px] text-[#1C2539] w-[80%]">
                <a href="#" className="inline-block relative hover:underline">
                  <span className="transition-transform absolute left-0 bottom-0 w-full h-1 bg-black transform scale-x-0"></span>
                  Classroom Code of Conduct1111111
                </a>
                <a href="#" className="inline-block relative hover:underline">
                  <span className="transition-transform absolute left-0 bottom-0 w-full h-1 bg-black transform scale-x-0"></span>
                  Change, Refund & Return Policy
                </a>
                <a href="#" className="inline-block relative hover:underline">
                  <span className="transition-transform absolute left-0 bottom-0 w-full h-1 bg-black transform scale-x-0"></span>
                  Communicable Disease Plan
                </a>
                <a href="#" className="inline-block relative hover:underline">
                  <span className="transition-transform absolute left-0 bottom-0 w-full h-1 bg-black transform scale-x-0"></span>
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2">
            <div className="flex text-[15px] leading-[27px] font-[500]">Find ProSafe Training on</div>
            <div className="flex gap-2">
              <div>
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M21.9625 10.75C21.9625 16.0781 18.0523 20.5039 12.9391 21.2773V13.8438H15.4313L15.9039 10.75H12.9391V8.77344C12.9391 7.91406 13.3688 7.09766 14.7008 7.09766H16.0328V4.47656C16.0328 4.47656 14.8297 4.26172 13.6266 4.26172C11.2203 4.26172 9.63047 5.76562 9.63047 8.42969V10.75H6.92344V13.8438H9.63047V21.2773C4.51719 20.5039 0.650001 16.0781 0.650001 10.75C0.650001 4.86328 5.41953 0.09375 11.3063 0.09375C17.193 0.09375 21.9625 4.86328 21.9625 10.75Z" fill="#090C0A"/>
                    <path d="M0.306251 21.1484H22.3063V22.2227H0.306251V21.1484Z" fill="#090C0A"/>
                  </g>
                </svg>
              </div>
              <div>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.9">
                    <path d="M18.452 0.125C19.1824 0.125 19.827 0.769531 19.827 1.54297V18C19.827 18.7734 19.1824 19.375 18.452 19.375H1.90899C1.17852 19.375 0.576954 18.7734 0.576954 18V1.54297C0.576954 0.769531 1.17852 0.125 1.90899 0.125H18.452ZM6.37774 16.625V7.47266H3.5418V16.625H6.37774ZM4.95977 6.18359C5.86211 6.18359 6.59258 5.45312 6.59258 4.55078C6.59258 3.64844 5.86211 2.875 4.95977 2.875C4.01445 2.875 3.28399 3.64844 3.28399 4.55078C3.28399 5.45312 4.01445 6.18359 4.95977 6.18359ZM17.077 16.625V11.5977C17.077 9.14844 16.5184 7.21484 13.6395 7.21484C12.2645 7.21484 11.3191 7.98828 10.9324 8.71875H10.8895V7.47266H8.18242V16.625H11.0184V12.1133C11.0184 10.9102 11.2332 9.75 12.7371 9.75C14.198 9.75 14.198 11.125 14.198 12.1562V16.625H17.077Z" fill="#090C0A"/>
                    <path d="M0.576954 20.1484H19.827V21.2227H0.576954V20.1484Z" fill="#090C0A"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer;
