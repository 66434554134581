import React from "react";
import EmptyCartImg from "../../../assets/EmptyCart.png";

const EmptyCart = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="font-[800] text-[32px] lg:text-[40px] leading-[50px] mt-10">Your cart is empty</div>
      <div>
        <img src={EmptyCartImg} alt="EmptyCart"  className="w-[338px] h-[300px] lg:w-full lg:h-full "/>
      </div>
      <div className="bg-[#B02526] w-[260px] lg:w-[310px] h-[44.45px] lg:h-[53px] rounded-[10px] flex items-center justify-center text-white font-[700] text-[20.13px] lg:text-[24px] leading-[28.8px] cursor-pointer">Continue Shopping</div>
    </div>
  )
}
export default EmptyCart;
