import React, { useState } from "react";
import BlogCard from "../components/Blog/BlogCard";
import GeneralHeroSection from "../components/HeroSection/GeneralHeroSection";
import Layout from '../components/Layout/Layout';
import BlogHeroSection from "../assets/BlogHeroSection.png"
import RbuImg1 from '../assets/RbuImg1.png'
import RbuImg3 from '../assets/RbuImg3.png'
import RbuImg5 from '../assets/RbuImg5.png'
import verticalDots from '../assets/verticalDots.svg'
import { useEffect } from "react";
import axios from 'axios';

const Blog = (props) => {
  const [blog, setBlog] = useState({});
  useEffect(() => {
    const getBlog = () => {
      console.log("${process.env.REACT_PUBLIC_BASE_URL}", process.env.REACT_APP_BASE_URL)
      axios.get(`${process.env.REACT_APP_BASE_URL}/blogs`)
      .then(response => {
        console.log(response?.data?.data);
        const sortedBlogs = response?.data?.data.sort((a, b) => a.order - b.order);
        setBlog(sortedBlogs)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
    getBlog()
  }, [])
  const data = [
    {
      image: RbuImg1,
      title: "A Comprehensive Guide to Building Your First Aid Kit For Home",
      date: "September 23, 2023 /// No comments",
      description:
        "Every second matters during times of emergency.It is therefore essential to have a well-stocked first aid kit at home. A first aid kit provides",
    },
    {
      image: RbuImg3,
      title: "A Comprehensive Guide to Building Your First Aid Kit For Home",
      date: "September 23, 2023 /// No comments",
      description:
        "Every second matters during times of emergency.It is therefore essential to have a well-stocked first aid kit at home. A first aid kit provides",
      button: "Read More >>",
    },
    {
      image: RbuImg5,
      title: "A Comprehensive Guide to Building Your First Aid Kit For Home",
      date: "September 23, 2023 /// No comments",
      description:
        "Every second matters during times of emergency.It is therefore essential to have a well-stocked first aid kit at home. A first aid kit provides",
    },
    {
      image: RbuImg1,
      title: "A Comprehensive Guide to Building Your First Aid Kit For Home",
      date: "September 23, 2023 /// No comments",
      description:
        "Every second matters during times of emergency.It is therefore essential to have a well-stocked first aid kit at home. A first aid kit provides",
    },
    {
      image: RbuImg3,
      title: "A Comprehensive Guide to Building Your First Aid Kit For Home",
      date: "September 23, 2023 /// No comments",
      description:
        "Every second matters during times of emergency.It is therefore essential to have a well-stocked first aid kit at home. A first aid kit provides",
      button: "Read More >>",
    },
    {
      image: RbuImg5,
      title: "A Comprehensive Guide to Building Your First Aid Kit For Home",
      date: "September 23, 2023 /// No comments",
      description:
        "Every second matters during times of emergency.It is therefore essential to have a well-stocked first aid kit at home. A first aid kit provides",
    },
  ];

  const bubbleSVG1 = (<svg width="165" height="165" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="165" y="165" width="165" height="165" rx="82.5" transform="rotate(180 165 165)" fill="#E9EFFF"/>
  </svg>
  )

  const bubbleSVG2 = (<svg width="464" height="464" viewBox="0 0 464 464" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="464" height="464" rx="232" fill="#E9EFFF"/>
  </svg>
  )
  
  const [visibleItems, setVisibleItems] = useState(3);

  const handleLearnMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3);
  };
  const blogCard = blog.length > 0 && blog?.map((items) => {
    return (
      <BlogCard
        image={items.image}
        title={items.title}
        date={items.date}
        description={items.desc}
        id={items._id}
      />
    );
  });

  return (
    <Layout>

      <div>
        <GeneralHeroSection image={BlogHeroSection} heading="BLOG" imageStyle="object-cover"/>
      </div>

      <div className="relative flex flex-col items-center overflow-hidden">
        <div>
          <h1 className="text-center uppercase text-[#183864] font-semibold text-[40px] leading-[43.55px] md:text-[44px] md:leading-[40px] lg:text-[52px] lg:leading-[45px] xl:text-[71px] xl:leading-[71px]">
            {props.text}
          </h1>
        </div>
        <div className="z-10">{blogCard}</div>

        <div className="absolute left-0 top-[8%] -z-30">
          <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
        </div>

        <div className="lg:flex hidden absolute right-[-15px] top-[35%] -z-30">
          <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
        </div>

        <div className="lg:flex hidden absolute left-[42%] top-[56%] -z-30">
          {bubbleSVG1}
        </div>

        <div className="lg:flex hidden absolute right-[5%] bottom-[3%] -z-30">
          {bubbleSVG2}
        </div>

        <div>
          {visibleItems < data.length && (
            <button onClick={handleLearnMore} className="text-[9.87px] font-bold leading-[17.77px] text-[#B02526] px-[17.69px] py-[7.35px] border-[0.62px] border-[#B02526] rounded-[6.17px] mb-4 lg:text-sm lg:px-[22px] lg:py-[10px] xl:text-base  xl:px-[29px] xl:py-[12px] xl:border-[1px]">
              Load More
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
}
export default Blog;
