import React, { createContext, useContext, useState } from 'react';

const StrapperContext = createContext();

export const StrapperProvider = ({ children }) => {
  const [straper, setStraper] = useState(1);

  return (
    <StrapperContext.Provider value={{ straper, setStraper }}>
      {children}
    </StrapperContext.Provider>
  );
};

export const useStrapperContext = () => useContext(StrapperContext);
