import React, {useState} from "react";
import logo from '../../assets/prosafeLogo.png';
import mobileLogo from '../../assets/headerLogoMobile.png'
import Modal from "react-modal";
import { Link, useNavigate} from "react-router-dom";
import { motion } from "framer-motion";
import Navbar from "./Navbar";
import CartModal from '../Event/CartModal';
import { useStrapperContext } from '../Strapper/StrapperContext';
import StrapperFrom from "../StraperFrom";

const Header = ({headerData}) => {
  const { straper, setStraper } = useStrapperContext();
  const [showSideBar, setShowSideBar] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(showSideBar);
  const [isCartOpen, setCartOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpenCart = () => {
    setStraper(4);
    setCartOpen(true)
  };
  const handleCloseCart = () => setCartOpen(false);
  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () =>  {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 0,
      border: "none",
      background: "transparent",
      width: "375px",
      maxHeight: "653px",
      overflowY: "auto", // Add this to enable scrolling if content exceeds maxHeight
      top: "0", // Adjust as needed
      right: "0",
      bottom: "0",
      left: "0",
      // transform: "translateY(-100%)", // Initial position off-screen
      // transition: "transform 0.3s ease-in-out", // Animation transition
    },
    overlay: {
      background: "rgba(13, 18, 27, 0.6)",
    },
  };
  const customStylesCart = {
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 0,
      border: "none",
      background: "white",
      width: "375px",
      maxHeight: "653px",
      overflowY: "auto", // Add this to enable scrolling if content exceeds maxHeight
      top: "0", // Adjust as needed
      right: "0",
      bottom: "0",
      left: "0",
      // transform: "translateY(-100%)", // Initial position off-screen
      // transition: "transform 0.3s ease-in-out", // Animation transition
    },
    overlay: {
      background: "rgba(13, 18, 27, 0.6)",
    },
  };
  if (showSideBar && !modalIsOpen) {
    openModal()
  }
  if (!showSideBar && modalIsOpen) {
    closeModal()
  }
  return (
    <>
      {isCartOpen &&
        <Modal
          isOpen={isCartOpen}
          onRequestClose={handleCloseCart}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              overflowY: 'auto',
              zIndex: '99999'
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '900px',
              height: '700px',
              backgroundColor: '#f8f8f8',
              padding: '0',
              paddingBottom: '10px',
              marginBottom: '10px',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }
          }}
          ariaHideApp={false}
        >
          <StrapperFrom
            onClose={handleCloseCart}
            setStraper={setStraper}
            back={false}
          />
        </Modal>
      }
      <div
        className="shadow-md lg:flex lg:flex-col hidden"
        style={{ boxShadow: '0 -2px 10px 0 rgba(0, 0, 0, 0.2)' }}
      >
        <div className="flex ml-[50px] xl:ml-[101px] mr-[31px] xl:mr-[51px] my-[20px]">
          <Link to="/">
            {/* <img src={logo} width={75} height={75} /> */}
            <img src={`${process.env.REACT_APP_BASE_URL}/${headerData?.logo}`} alt="Logo" width={75} height={75} />
          </Link>
          <div className="flex gap-5 justify-end w-full">
            <div className="flex items-center gap-3">
              <div className="rounded-[30px] bg-[#E3EBFD] w-[48px] h-[48px] flex justify-center items-center">
                <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.4395 4.96094C20.5957 4.84375 20.8301 4.96094 20.8301 5.15625V13.125C20.8301 14.1797 19.9707 15 18.9551 15H2.70508C1.65039 15 0.830078 14.1797 0.830078 13.125V5.15625C0.830078 4.96094 1.02539 4.84375 1.18164 4.96094C2.08008 5.66406 3.21289 6.52344 7.19727 9.41406C8.01758 10 9.42383 11.2891 10.8301 11.2891C12.1973 11.2891 13.6426 10 14.4238 9.41406C18.4082 6.52344 19.541 5.66406 20.4395 4.96094ZM10.8301 10C9.89258 10.0391 8.60352 8.86719 7.93945 8.39844C2.74414 4.64844 2.35352 4.29688 1.18164 3.35938C0.947266 3.20312 0.830078 2.92969 0.830078 2.61719V1.875C0.830078 0.859375 1.65039 0 2.70508 0H18.9551C19.9707 0 20.8301 0.859375 20.8301 1.875V2.61719C20.8301 2.92969 20.6738 3.20312 20.4395 3.35938C19.2676 4.29688 18.877 4.64844 13.6816 8.39844C13.0176 8.86719 11.7285 10.0391 10.8301 10Z" fill="#183864"/>
                </svg>
              </div>
              <div className="text-[#183864] text-[16px] font-bold">{headerData?.email}</div>
            </div>
            <div className="flex items-center gap-3">
              <div className="rounded-[30px] bg-[#E3EBFD] w-[48px] h-[48px] flex justify-center items-center">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" strokeWidth="evenodd" d="M6.45703 10.0007C6.45703 14.5732 8.49953 16.749 9.38786 17.4857C9.56453 17.6315 9.81036 17.709 10.1162 17.709L11.7187 17.709C12.3554 17.709 12.8662 17.0573 12.662 16.3598L12.1737 14.6932C12.042 14.2448 11.6512 13.959 11.2304 13.959L10.717 13.959L10.717 13.334C10.717 13.959 10.7162 13.959 10.7154 13.959L10.6854 13.959C10.638 13.9569 10.5909 13.9507 10.5445 13.9407C10.467 13.9257 10.372 13.899 10.267 13.8507C10.0529 13.7532 9.81703 13.579 9.60203 13.2865C9.18286 12.7148 8.87036 11.7248 8.87036 10.0007C8.87036 8.27565 9.18286 7.28648 9.60203 6.71482C9.8162 6.42232 10.052 6.24815 10.267 6.15065C10.3871 6.09583 10.5151 6.06039 10.6462 6.04565L10.6862 6.04315L10.7029 6.04315L10.7095 6.04232L10.7154 6.04232C10.7154 6.04232 10.717 6.04232 10.717 6.66732L10.717 6.04232L11.0404 6.04232C11.512 6.04232 11.9387 5.68315 12.0154 5.15898L12.2595 3.49232C12.3562 2.83815 11.8662 2.29232 11.2845 2.29232L9.5137 2.29232C9.14703 2.29232 8.8587 2.40648 8.67786 2.60898C7.92953 3.44565 6.45703 5.63898 6.45703 10.0007ZM10.777 7.29232L11.0404 7.29232C12.1629 7.29232 13.0904 6.44732 13.2529 5.34065L13.497 3.67399C13.697 2.31232 12.6762 1.04232 11.2845 1.04232L9.5137 1.04232C8.90286 1.04232 8.2287 1.23398 7.7462 1.77565C6.7962 2.83898 5.20703 5.32648 5.20703 10.0007C5.20703 15.009 7.4687 17.5182 8.59036 18.4482C9.05036 18.829 9.60703 18.959 10.1162 18.959L11.7187 18.959C13.2462 18.959 14.2845 17.4507 13.8612 16.009L13.3729 14.3423C13.0929 13.384 12.2304 12.709 11.2312 12.709L10.777 12.709C10.7102 12.6684 10.6533 12.6134 10.6104 12.5482C10.4187 12.2865 10.1204 11.6098 10.1204 10.0007C10.1204 8.39232 10.4187 7.71482 10.6104 7.45315C10.6895 7.34482 10.752 7.30482 10.777 7.29232Z" fill="#183864"/>
                </svg>
              </div>
              <div className="text-[#183864] text-[16px] font-bold">{headerData?.phone}</div>
            </div>
            <div className="flex items-center gap-3 cursor-pointer" onClick={handleOpenCart}>
              <div className="rounded-[30px] bg-[#E3EBFD] w-[48px] h-[48px] flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M7 18c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm-7-16v2h2l3.6 7.59-1.35 2.44c-.16.29-.25.63-.25.97 0 1.104.896 2 2 2h12v-2h-11.42c-.14 0-.25-.11-.25-.25l.03-.12 1.1-2h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.3.12-.48 0-.55-.45-1-1-1h-14.79l-.94-2h-3.27zm16 16c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2z"/>
                </svg>
              </div>
              <div className="text-[#183864] text-[16px] font-bold">Event Cart</div>
            </div>
            {/* <div className="flex items-center gap-3">
              <div className="rounded-[30px] bg-[#E3EBFD] w-[48px] h-[48px] flex justify-center items-center">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0004 11.1912C11.4363 11.1912 12.6004 10.0272 12.6004 8.59121C12.6004 7.15527 11.4363 5.99121 10.0004 5.99121C8.56445 5.99121 7.40039 7.15527 7.40039 8.59121C7.40039 10.0272 8.56445 11.1912 10.0004 11.1912Z" stroke="#183864" strokeWidth="1.2"/>
                  <path d="M3.01675 7.07533C4.65842 -0.141339 15.3501 -0.133006 16.9834 7.08366C17.9418 11.317 15.3084 14.9003 13.0001 17.117C11.3251 18.7337 8.67508 18.7337 6.99175 17.117C4.69175 14.9003 2.05842 11.3087 3.01675 7.07533Z" stroke="#183864" strokeWidth="1.2"/>
                </svg>
              </div>
              <div>
                <div className="text-[12px] font-bold text-[#9A9EA6]">{headerData?.location}</div>
                <div className="flex gap-1">
                  <div className="text-[#183864] text-[16px] font-bold">{headerData?.location}</div>
                  <div className="flex items-center">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.9336 8.31641H9.74194H5.06695C4.26695 8.31641 3.86695 9.28307 4.43361 9.84974L8.75028 14.1664C9.44195 14.8581 10.5669 14.8581 11.2586 14.1664L12.9003 12.5247L15.5753 9.84974C16.1336 9.28307 15.7336 8.31641 14.9336 8.31641Z" fill="#183864"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="flex items-center gap-3">
              <div className="flex justify-center items-center">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z" stroke="#183864" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.66667 2.5H7.5C5.875 7.36667 5.875 12.6333 7.5 17.5H6.66667" stroke="#183864" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M12.5 2.5C14.125 7.36667 14.125 12.6333 12.5 17.5" stroke="#183864" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M2.5 13.3333V12.5C7.36667 14.125 12.6333 14.125 17.5 12.5V13.3333" stroke="#183864" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M2.5 7.5C7.36667 5.875 12.6333 5.875 17.5 7.5" stroke="#183864" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <div className="flex">
                <div className="text-[#183864] text-[14px]">{headerData?.languages}</div>
                <div className="flex items-center">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9336 8.31641H9.74194H5.06695C4.26695 8.31641 3.86695 9.28307 4.43361 9.84974L8.75028 14.1664C9.44195 14.8581 10.5669 14.8581 11.2586 14.1664L12.9003 12.5247L15.5753 9.84974C16.1336 9.28307 15.7336 8.31641 14.9336 8.31641Z" fill="#183864"/>
                  </svg>
                </div>
              </div>
            </div>

            <div className="flex items-center">
              <div className="flex !h-max">
                <div className="text-white text-[14px] bg-[#073967] h-max py-1 px-2 rounded-lg cursor-pointer" onClick={() => navigate("/student/login")}>Login</div>
              </div>
            </div>


          </div>
        </div>
        <Navbar />
      </div>
      {/* Mobile Design */}
      <div className="w-full lg:hidden flex flex-col p-4 shadow-md" style={{ boxShadow: '0 -2px 10px 0 rgba(0, 0, 0, 1)' }}>
        <div className="flex justify-between items-center pb-4 "
      >
          <div className="cursor-pointer">
            <img src={mobileLogo} alt="Mobile logo" />
          </div>
          <div className="cursor-pointer" onClick={() => setShowSideBar(true)}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" strokeWidth="evenodd" d="M3.42857 5C2.6396 5 2 5.6396 2 6.42857C2 7.21754 2.6396 7.85714 3.42857 7.85714H26.2857C27.0747 7.85714 27.7143 7.21754 27.7143 6.42857C27.7143 5.6396 27.0747 5 26.2857 5H3.42857ZM4.85714 15C4.85714 14.211 5.49674 13.5714 6.28571 13.5714H23.4286C24.2176 13.5714 24.8571 14.211 24.8571 15C24.8571 15.789 24.2176 16.4286 23.4286 16.4286H6.28571C5.49674 16.4286 4.85714 15.789 4.85714 15ZM7.71429 23.5714C7.71429 22.7824 8.35389 22.1429 9.14286 22.1429H20.5714C21.3604 22.1429 22 22.7824 22 23.5714C22 24.3604 21.3604 25 20.5714 25H9.14286C8.35389 25 7.71429 24.3604 7.71429 23.5714Z" fill="black"/>
            </svg>
          </div>
        </div>
        {modalIsOpen &&
          <Modal
            isOpen={modalIsOpen}
            style={{
              ...customStyles,
              content: {
                ...customStyles.content,
                transform: modalIsOpen ? "translateY(0)" : "translateY(-100%)",
              },
            }}
            ariaHideApp={false}
          >
            <motion.div
              initial={{ y: -100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              className="max-w-[430px] min-w-[375px] bg-white shadow-md"
              style={{ boxShadow: '0 -2px 10px 0 rgba(0, 0, 0, 0.2)' }}
            >
              <div className="w-full p-4 bg-white z-[999999]">
                <div className="flex justify-between pb-4">
                  <div className="cursor-pointer">
                    <img src={logo} alt="Mobile logo" className="w-[80px] h-[80px]"/>
                  </div>
                  <div className="cursor-pointer" onClick={() => setShowSideBar(false)}>
                    <svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.1571 10.5067C15.1649 8.5145 13.1928 6.54242 11.2006 4.55022C10.0536 3.4032 8.36028 3.64763 7.93073 5.00285C7.64616 5.89112 7.98551 6.63842 8.6697 7.32261C10.8833 9.53616 13.0767 11.7296 15.2902 13.9432C17.0007 15.6536 18.7112 17.3641 20.4217 19.0746C21.5083 20.1612 23.0486 20.0698 23.6539 18.9413C24.0592 18.1738 23.8782 17.2279 23.1538 16.5034C21.6647 15.0143 20.1756 13.5252 18.6663 12.0159C18.1632 11.5129 17.6602 11.0098 17.1571 10.5067Z" fill="#1C1C1C"/>
                      <path d="M19.5187 18.171C21.5109 20.1632 23.483 22.1353 25.4752 24.1275C26.6222 25.2745 28.3155 25.0301 28.7451 23.6749C29.0296 22.7866 28.6903 22.0393 28.0061 21.3551C25.7925 19.1416 23.5991 16.9481 21.3855 14.7346C19.6751 13.0241 17.9646 11.3136 16.2541 9.60316C15.1675 8.51651 13.6271 8.60796 13.0219 9.73645C12.6165 10.504 12.7975 11.4499 13.522 12.1743C15.0111 13.6634 16.5002 15.1526 18.0095 16.6618C18.5125 17.1649 19.0156 17.668 19.5187 18.171Z" fill="#1C1C1C"/>
                      <path d="M19.4597 15.6926C22.2604 12.8919 25.0611 10.0912 27.8411 7.31128C28.2145 6.93785 28.5314 6.46645 28.6882 6.00079C28.9701 5.20396 28.6527 4.38864 28.0133 3.99823C27.3225 3.55633 26.4127 3.64233 25.6923 4.20822C25.5214 4.32769 25.3554 4.49366 25.2102 4.63888C19.6088 10.2403 13.9866 15.8624 8.38526 21.4638C7.47244 22.3766 7.25198 23.3179 7.71387 24.1947C8.23224 25.1695 9.58545 25.3609 10.5598 24.5926C10.7465 24.4059 10.9539 24.1984 11.1614 23.991C13.9206 21.2318 16.7005 18.4518 19.4597 15.6926Z" fill="#1C1C1C"/>
                    </svg>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-3">
                    <div className="rounded-[30px] bg-[#E3EBFD] w-[33px] h-[33px] flex justify-center items-center">
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.63626 8.46197C8.63162 8.46197 9.43853 7.65506 9.43853 6.65969C9.43853 5.66433 8.63162 4.85742 7.63626 4.85742C6.64089 4.85742 5.83398 5.66433 5.83398 6.65969C5.83398 7.65506 6.64089 8.46197 7.63626 8.46197Z" stroke="#183864" strokeWidth="0.831818"/>
                        <path d="M2.79648 5.60833C3.93445 0.605873 11.3457 0.611649 12.4779 5.61411C13.1422 8.54858 11.3168 11.0325 9.71675 12.569C8.55567 13.6897 6.71873 13.6897 5.55188 12.569C3.95756 11.0325 2.13218 8.5428 2.79648 5.60833Z" stroke="#183864" strokeWidth="0.831818"/>
                      </svg>
                    </div>
                    <div>
                      <div className="text-[10px] leading-[13px] text-[#9A9EA6]">Canada</div>
                      <div className="flex gap-1">
                        <div className="text-[#183864] text-[12px] font-bold leading-[15.12px]">Canada,WayBurnaby</div>
                        <div className="flex items-center cursor-pointer">
                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.7165 6.31641H7.11768H3.87706C3.32251 6.31641 3.04524 6.98648 3.43805 7.37928L6.43028 10.3715C6.90973 10.851 7.68956 10.851 8.16901 10.3715L9.30698 9.23355L11.1612 7.37928C11.5483 6.98648 11.271 6.31641 10.7165 6.31641Z" fill="#183864"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <div>
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.14183 13.0952C10.4292 13.0952 13.0942 10.4302 13.0942 7.14281C13.0942 3.8554 10.4292 1.19043 7.14183 1.19043C3.85442 1.19043 1.18945 3.8554 1.18945 7.14281C1.18945 10.4302 3.85442 13.0952 7.14183 13.0952Z" stroke="#183864" strokeWidth="0.857143" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.76163 1.78613H5.35686C4.19615 5.26232 4.19615 9.02423 5.35686 12.5004H4.76163" stroke="#183864" strokeWidth="0.857143" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M8.92773 1.78613C10.0884 5.26232 10.0884 9.02423 8.92773 12.5004" stroke="#183864" strokeWidth="0.857143" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1.78516 9.52395V8.92871C5.26135 10.0894 9.02325 10.0894 12.4994 8.92871V9.52395" stroke="#183864" strokeWidth="0.857143" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1.78516 5.35686C5.26135 4.19615 9.02325 4.19615 12.4994 5.35686" stroke="#183864" strokeWidth="0.857143" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                    <div className="text-[#183864] text-[10px] leading-[12.6px]">English</div>
                    <div className="flex items-center cursor-pointer">
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.7165 6.31641H7.11768H3.87706C3.32251 6.31641 3.04524 6.98648 3.43805 7.37928L6.43028 10.3715C6.90973 10.851 7.68956 10.851 8.16901 10.3715L9.30698 9.23355L11.1612 7.37928C11.5483 6.98648 11.271 6.31641 10.7165 6.31641Z" fill="#183864"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="my-10">
                  <div className="w-full flex flex-col gap-6">
                    <Link to="/training" className="w-full flex items-center justify-center font-[600] text-[20px] leading-[28.8px] text-[#183864] cursor-pointer">Training</Link>
                    <Link to="/supplies" className="flex items-center justify-center font-[600] text-[20px] leading-[28.8px] text-[#183864] cursor-pointer">Supplies</Link>
                    <Link to="/eLearning" className="flex items-center justify-center font-[600] text-[20px] leading-[28.8px] text-[#183864] cursor-pointer">eLearning</Link>
                    <Link to="/about" className="flex items-center justify-center font-[600] text-[20px] leading-[28.8px] text-[#183864] cursor-pointer">About Us</Link>
                    <Link to="/faq" className="flex items-center justify-center font-[600] text-[20px] leading-[28.8px] text-[#183864] cursor-pointer">FAQ</Link>
                    <Link to="/blog" className="flex items-center justify-center font-[600] text-[20px] leading-[28.8px] text-[#183864] cursor-pointer">Blog</Link>
                    <Link to="/contact" className="flex items-center justify-center font-[600] text-[20px] leading-[28.8px] text-[#183864] cursor-pointer">Contact</Link>
                  </div>
                </div>
                <div className="border-b-[1px] border-[#C8CACD4D]"></div>
              </div>
            </motion.div>
          </Modal>
        }
      </div>
    </>
  )
}
export default Header;
