import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const studentID = localStorage.getItem('studentID');

  if (!studentID) {
    return <Navigate to="/student/login" />;
  }

  return element; 
};

export default PrivateRoute;
