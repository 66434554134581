import React from "react";
import FaqHeroSection from "../components/HeroSection/FaqHeroSection";
import FrequentlyAskQuestion from "../components/Homepage/FrequentlyAskQuestion/FrequentlyAskQuestion";
import Layout from '../components/Layout/Layout';
import LeftBubble from '../assets/faqleftbubbles.svg'
import verticalDots from '../assets/verticalDots.svg'
import axios from 'axios';
import { useEffect, useState } from "react";

const FAQ = () => {

  const [faqData, setFaqData] = useState([]);
  const getFAQData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/faqs`)
    .then(response => {
      setFaqData(response.data)
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }
  useEffect(() => {
    getFAQData();
  }, [])

  return (
    <Layout>
      <div className="flex flex-col w-full">
        <FaqHeroSection />
        <div className="relative flex flex-col w-full itmes-center justify-center">
          <img className="absolute top-[5px] left-3" src={LeftBubble} alt="" />
          <FrequentlyAskQuestion source="faq" faqData={faqData}/>
          <div className='md:flex hidden absolute right-[0px] top-[70%]'>
             <img src={verticalDots} alt=''/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FAQ;
