import React from 'react'
import LoginPage from '../components/LoginComponents/LoginPage'

const Login = () => {
  return (
    <LoginPage />
  )
}

export default Login
