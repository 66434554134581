import React from "react";
import backgroundCover from "../../assets/learningBackground.png";
import Mask from "../../assets/dots-rectangle.svg";
import { motion } from "framer-motion";
import PrivateContact from "../../assets/privateContact.png";
const PrivateContactFAQ = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundCover})`,
    backgroundSize: "cover",
    zIndex: "0",
  };
  const dropShadowFilter = "drop-shadow(43px -33px 0px #E5EDFF)";
  return (
    <div className="lg:relative lg:h-[550px] w-[95%] lg:w-full mx-auto lg:mx-0 bg-white">
      <div>
        <div
          className="pt-20 inset-0 lg:flex hidden"
          style={backgroundStyle}
        ></div>
      </div>
      <div className="flex items-center relative lg:h-[500px] lg:pl-20">
        <div className="absolute bottom-3 -left-5 overflow-hidden lg:flex hidden">
          <img src={Mask} alt="Mask" />
        </div>
        <div className="absolute top-3 -right-0 z-[-1]">
          <img src={Mask} alt="Mask" />
        </div>
        <div className="absolute top-10 lg:right-[54%] right-10 lg:flex ">
          <svg
            width="83"
            height="83"
            viewBox="0 0 83 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="83"
              y="83"
              width="83"
              height="83"
              rx="41.5"
              transform="rotate(180 83 83)"
              fill="#E9EFFF"
            />
          </svg>
        </div>
        <div className="absolute left-0 top-10">
          <svg
            width="94"
            height="165"
            viewBox="0 0 94 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="94"
              y="165"
              width="165"
              height="165"
              rx="82.5"
              transform="rotate(180 94 165)"
              fill="#E9EFFF"
            />
          </svg>
        </div>
        <div className="absolute top-[60%] md:left-[600px] lg:flex hidden">
          <svg
            width="55"
            height="55"
            viewBox="0 0 55 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="55"
              y="55"
              width="55"
              height="55"
              rx="27.5"
              transform="rotate(180 55 55)"
              fill="#E9EFFF"
            />
          </svg>
        </div>
        <div className="absolute top-[300px] -right-0 lg:flex hidden">
          <svg
            width="34"
            height="38"
            viewBox="0 0 34 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="38"
              y="38"
              width="38"
              height="38"
              rx="19"
              transform="rotate(180 38 38)"
              fill="#E9EFFF"
            />
          </svg>
        </div>
        <div className="p-0 px-4">
          <img src={PrivateContact}></img>
        </div>
      </div>
      <div className="lg:absolute lg:top-16 lg:right-[50px] lg:z-[999] flex justify-center">
        <motion.div
          // initial={{ x: -100, opacity: 0 }}
          // whileInView={{ x: 0, opacity: 1 }}
          // transition={{ duration: 1 }}
          className="w-[90%] xl:w-[658px] lg:max-h-[581px] bg-white my-10 px-10 shadow-md rounded-[10px] m-5 lg:m-0"
          style={{ boxShadow: "0 -2px 10px 0 rgba(0, 0, 0, 0.2)" }}
        >
          <div className="w-full py-1">
            <div className="w-full text-[#0E0E0E] leading-[46.8px] text-[20.19px] md:text-[36px] font-[600] flex justify-center">
              Enter Your Details
            </div>
            <form className="w-full pb-4">
              <div className="lg:flex justify-between w-full gap-8 mb-0 lg:mb-4 mt-4">
                <div className="form-control w-full mb-8 lg:mb-0">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label><span>First Name</span></label>
                </div>
                <div className="form-control w-full mb-8 lg:mb-0">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label><span>Last Name</span></label>
                </div>
              </div>
              <div className="lg:flex justify-between w-full gap-8 mt-0 mb-0 lg:mb-4 lg:mt-4">
                <div className="form-control w-full mb-8 lg:mb-0">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label><span>Email*</span></label>
                </div>
                <div className="form-control w-full  mb-8 lg:mb-0">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label><span>Phone</span></label>
                </div>
              </div>
              <div className="flex justify-between w-full gap-8 mb-6 mt-4">
                <div className="form-control w-full">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label><span>Location</span></label>
                </div>
              </div>
              <div className="flex justify-between w-full gap-8 mb-6 mt-4">
                <div className="form-control w-full">
                  <input
                    className="text-[10.09px] md:text-[18px]"
                    type="value"
                    required
                  />
                  <label><span>Training Topics</span>
                  </label>
                </div>
              </div>
              <div className="form-control w-full mt-4">
                <input
                  className="text-[10.09px] md:text-[18px]"
                  type="value"
                  required
                />
                <label><span> Additional Notes</span>
                </label>
              </div>
              <div className="flex justify-end mt-4">
                <motion.div whileHover={{ scale: 1.05 }}>
                  <button className="bg-[#B02526] w-[100px] py-2 rounded-[6px] font-[600] text-white text-[17px] leading-[28px]  transition-colors duration-300 hover:bg-blue-500 shadow-custom transform hover:scale-110 transition-transform duration-300">
                    Send
                  </button>
                </motion.div>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
export default PrivateContactFAQ;
