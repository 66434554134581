import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../components/Layout/Layout";

const ContactDetail = () => {
  const { id } = useParams();
  const [campusData, setCampusData] = useState(null);

  useEffect(() => {
    const getContactDetail = async () => {
      if (id) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/contact/campus/${id}`);
          if (response && response.status === 200) {
            setCampusData(response.data);
          } else {
            toast.error("Failed to fetch data");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          toast.error("Error fetching data");
        }
      }
    };
    getContactDetail();
  }, [id]);

  if (!campusData) {
    return <div className="flex justify-center font-semibold items-center h-screen">Loading...</div>;
  }

  return (
    <Layout>
     {campusData &&  (<div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold text-gray-800 text-center my-8">{campusData.name || "--"}</h1>
        <div className="md:flex md:justify-between">
          <div className="w-full p-6 shadow-lg bg-slate-50 rounded-lg">
            <h2 className="text-xl md:mb-5 font-bold text-gray-800 uppercase">Contact Details</h2>
            <p className="mt-2"><span className="font-bold">Phone:</span> {campusData.phone || "--"}</p>
            <p><span className="font-bold">Address:</span> {campusData.address || "--"}</p>
            <p><span className="font-bold">Hours Weekdays:</span> {campusData.desc1 || "--"}</p>
            <p><span className="font-bold">Hours Weekends:</span> {campusData.desc2 || "--"}</p>
            <div className="mt-4 flex justify-end flex-wrap">
              <Link to="/contact"><button className="bg-red-500 sm:w-auto w-full text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300">
                General Inquiry
              </button></Link>
              <Link to="/contact-private">
              <button className="bg-gray-500 sm:w-auto w-full text-white px-4 py-2 rounded ml-2 hover:bg-gray-600 transition duration-300">
                Private Group Inquiry
              </button></Link>
            </div>
          </div>
        </div>
        <iframe
          className="w-full my-10 m-auto h-[395px]"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=-79.6393%2C43.5807%2C-79.1157%2C43.8554&amp;layer=mapnik&amp;marker=43.6532%2C-79.3832"
        ></iframe>
      </div>)}
    </Layout>
  );
};

export default ContactDetail;
