import React from "react";
import maleImage from '../../../assets/pensiveMaleCustomer.png';

const ImageSection = () => {
  return (
    <div className="flex items-end jutsify-center relative">
      <div className="">
        <img src={maleImage} alt= "maleImage" className="2xl:flex hidden"/>
        <img src={maleImage} alt= "maleImage" width={316.51} height={448.85}  className="flex 2xl:hidden"/>
      </div>
      <div className="absolute bottom-0 -right-5 2xl:-right-0 bg-[#E9EFFF] rounded-[232px] w-[369.92px] h-[369.92px] 2xl:w-[464px] 2xl:h-[464px] z-[-1]"></div>
    </div>
  )
}
export default ImageSection;
