import React from "react";
import Layout from '../components/Layout/Layout';

const AboutUs = () => {
  return (
    <Layout>
      <div>AboutUs Page</div>
    </Layout>
  )
}
export default AboutUs;
