import React, { useEffect, useState } from 'react'
import homeIcon from '../assets/homeIcon.svg'
import clock from '../assets/clock.svg'
import computerIcon from '../assets/computerIcon.svg'
import coffeeIcon from '../assets/coffeeIcon.svg'
import person from '../assets/person.svg'
import verticalRedLine from '../assets/verticalRedLine.svg'
import Testimonials from '../components/testimonials/Testimonials'
import horizontalDots from '../assets/horizontalDots.svg'
import verticalDots from '../assets/verticalDots.svg'
import WelcomeHeader from '../components/WelcomeHeader/WelcomeHeader'
import carasolImage1 from '../assets/homePageCarasolImage1.svg'
import carasolImage2 from '../assets/homePageCarasolImage2.svg'
import carasolImage3 from '../assets/homePageCarasolImage3.svg'
import carasolImage4 from '../assets/homePageCarasolImage4.svg'
import Slider from "react-slick";
import AboutHeroSection from '../components/HeroSection/AboutHeroSection'
import Layout from '../components/Layout/Layout'
import axios from 'axios';

const About = () => {
  const [aboutus, setAboutus] = useState([])
  useEffect(() => {
    const getAboutusContent = () => {
      axios.get(`${process.env.REACT_APP_BASE_URL}/aboutus`)
      .then(response => {
        setAboutus(response?.data[0])
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
    getAboutusContent()
  }, [])

  var carasolSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const bubbleSVG = (<svg viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="165" y="165" width="165" height="165" rx="82.5" transform="rotate(180 165 165)" fill="#E9EFFF"/>
  </svg>)

  const bubbleSVG2 = (<svg width="58" height="83" viewBox="0 0 58 83" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="83" height="83" rx="41.5" transform="matrix(1 0 0 -1 0 83)" fill="#E9EFFF"/>
  </svg>)


  return (
    <Layout>
      <div className='overflow-hidden'>
        <div className=''>
          <AboutHeroSection />
        </div>
        <div className='relative flex m-auto max-w-[1050px] md:h-[220px] h-[213px] lg:z-[9999]'>
          <div className='relative bg-white flex lg:justify-normal justify-center flex-wrap m-auto max-w-[1050px] md:h-[260px] h-[213px] shadow-lg rounded-md'>
            <div className='flex justify-around md:w-[205px] w-[122px]'>
              <div className='flex flex-col justify-around'>
                <div className='flex justify-center'><img className='md:w-[47px] md:h-[43px] w-[23.87px] h-[23.87px]' src={homeIcon} alt='home icon' /></div>
                <div className='md:text-[32.11px] text-[19.09px] md:leading-[32.11px] leading-[19.09px] font-semibold text-center'>{aboutus?.campuses}</div>
                <div className='md:text-[12.84px] text-[7.64px] md:leading-[19.27px] leading-[11.46px] font-medium text-center'>Campuses</div>
              </div>
              <div className='flex justify-center items-center'><img className='md:h-[128px] h-[70px]' src={verticalRedLine} alt="" /></div>
            </div>
            <div className='flex justify-around md:w-[205px] w-[122px]'>
              <div className='flex flex-col justify-around'>
                <div className='flex justify-center'><img className='md:w-[41px] md:h-[42px] w-[23.87px] h-[23.87px]' src={clock} alt='clock icon' /></div>
                <div className='md:text-[32.11px] text-[19.09px] md:leading-[32.11px] leading-[19.09px] font-semibold text-center'>{aboutus?.yearsExperience}+</div>
                <div className='md:text-[12.84px] text-[7.64px] md:leading-[19.27px] leading-[11.46px] font-medium text-center'>Years of experience</div>
              </div>
              <div className='flex justify-center items-center'><img className='md:h-[128px] h-[70px]' src={verticalRedLine} alt="" /></div>
            </div>
            <div className='flex justify-around md:w-[205px] w-[122px]'>
              <div className='flex flex-col justify-around'>
                <div className='flex justify-center'><img className='md:w-[41px] md:h-[43px] w-[23.87px] h-[23.87px]' src={person} alt='person icon' /></div>
                <div className='md:text-[32.11px] text-[19.09px] md:leading-[32.11px] leading-[19.09px] font-semibold text-center'>{aboutus?.instructors}+</div>
                <div className='md:text-[12.84px] text-[7.64px] md:leading-[19.27px] leading-[11.46px] font-medium text-center'>Instructors</div>
              </div>
              <div className='flex justify-center items-center'><img className='md:h-[128px] h-[70px]' src={verticalRedLine} alt="" /></div>
            </div>
            <div className='flex justify-around md:w-[205px] w-[122px]'>
              <div className='flex flex-col justify-around'>
                <div className='flex justify-center'><img className='md:w-[70px] md:h-[52px] w-[23.87px] h-[23.87px]' src={computerIcon} alt='computer icon' /></div>
                <div className='md:text-[32.11px] text-[19.09px] md:leading-[32.11px] leading-[19.09px] font-semibold text-center'>{aboutus?.courses}+</div>
                <div className='md:text-[12.84px] text-[7.64px] md:leading-[19.27px] leading-[11.46px] font-medium text-center'>Courses</div>
              </div>
              <div className='flex justify-center items-center'><img className='md:h-[128px] h-[70px]' src={verticalRedLine} alt="" /></div>
            </div>
            <div className='flex justify-around md:w-[205px] w-[122px]'>
              <div className='flex flex-col justify-around'>
                <div className='flex justify-center'><img className='md:w-[52px] md:h-[42px] w-[23.87px] h-[23.87px]' src={coffeeIcon} alt='coffee icon' /></div>
                <div className='md:text-[32.11px] text-[19.09px] md:leading-[32.11px] leading-[19.09px] font-semibold text-center'>{aboutus?.coffeeConsumed}</div>
                <div className='md:text-[12.84px] text-[7.64px] md:leading-[19.27px] leading-[11.46px] font-medium text-center'>Free Coffee Consumed </div>
              </div>
            </div>
          </div>

          <div className='absolute md:right-[-40px] md:bottom-[-40px] right-[-30px] bottom-[-25px]' style={{zIndex: "-10"}}>
            <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="99" height="99" rx="49.5" transform="matrix(-1 0 0 1 99 0)" fill="#E9EFFF"/>
            </svg>
          </div>

        </div>

        <div className='relative overflow-hidden'>
          <div className='md:w-[85px] w-[65px] absolute left-[-35px] top-[35%] lg:top-0 lg:left-10'><img src={verticalDots} alt="" /></div>

          <div className='mt-16 w-[85%] lg:w-full m-auto max-w-[1052px] text-sm leading-[21px] font-light md:text-base md:leading-[25px] lg:text-[26px] lg:leading-[42px] text-justify text-[#183864]'>
            {aboutus?.description}
            {/* ProSafe First Aid Training School has been providing first aid training since 1999.
            We have 6 Campuses located in Surrey, Abbotsford, Burnaby, Vancouver, Port Coquitlam, and Richmond.
            While ProSafe is known for delivering First Aid training courses, we also offer classes in health
            and safety, mobile equipment operation, workplace safety and customer service.
            In addition to training services, we also supply first aid supplies and equipment.
            Our professional and experienced instructors at ProSafe take pride in delivering the highest
            quality of training and offering flexible program scheduling in multiple languages! */}
          </div>

          <div className='md:w-[85px] w-[65px] absolute right-[-35px] lg:right-[-20px] bottom-[-25%] lg:z-50'><img src={verticalDots} alt="" /></div>
        </div>

        <div className='relative m-auto max-w-[1052px]'>
          <div className='lg:w-[165px] lg:h-[165px] w-[45px] h-[45px] absolute left-3 lg:left-[-8%] top-[-5%] lg:top-[-10%] opacity-50'>{bubbleSVG}</div>

          {/* <div className='text-[#183864] mt-16 w-[85%] lg:w-full m-auto max-w-[1052px] text-sm leading-[21px] md:text-base md:leading-[25px] lg:text-[26px] lg:leading-[42px] text-justify font-light'>
            If you are a professional instructor looking for a full-time career, or a recent graduate of one
            of our advanced classes who wants to get a foot in the door, talk to us if you are interested in
            learning more about having a career at ProSafe. We continue to look for new instructors to join our
            team who are talented and passionate in teaching and sharing. Not only we offer a flexible schedule
            and competitive compensation, but also we help you build a fulfilling career that fits your life
            goals. If you are interested in teaching for ProSafe please contact us at info@prosafefirstaid.ca
          </div> */}
        </div>

        <div className='relative overflow-hidden'>
          <div className='lg:flex hidden absolute left-[-25px]'><img src={verticalDots} alt="" /></div>

          <div className='mt-16 w-[85%] lg:w-full m-auto max-w-[1052px] text-sm leading-[21px] md:text-base md:leading-[26px] lg:text-[33.8px] lg:leading-[57.6px] text-justify font-bold text-[#183864]'>
            Customer service and strong relationships are what ProSafe is built on.
            We would like to thank and recognize some of our corporate clients and individual customers below:
          </div>

          <div className='absolute right-0 top-0'>{bubbleSVG2}</div>
        </div>

        <div className="">
            <Slider {...carasolSettings}>
              <div className="flex items-center">
                <img src={carasolImage1} className="h-[93px]"/>
              </div>
              <div className="flex items-center">
                <img src={carasolImage2} className="h-[93px]"/>
              </div>
              <div className="flex items-center">
                <img src={carasolImage3} className="h-[93px]"/>
              </div>
              <div className="flex items-center">
                <img src={carasolImage4} className="h-[93px]"/>
              </div>
              <div className="flex items-center">
              <img src={carasolImage4} className="h-[93px]"/>
              </div>
            </Slider>
          </div>

        <div className='mt-7'>
          <Testimonials />
        </div>

      </div>
    </Layout>
  )
}

export default About
