import React from "react";
import backgroundCover from '../../../assets/Section.png';
import footerLogo from '../../../assets/footerLogo.png';
import {motion} from 'framer-motion'
const About = ({heroSection}) => {
  console.log("AboutSectionAboutSectionAboutSectionAboutSection", heroSection)
  const backgroundStyle = {
    backgroundImage: `url(${backgroundCover})`,
    backgroundSize: 'cover',
  };
  const dropShadowFilter = 'drop-shadow(10px -10px 0px #E5EDFF)';
  
  return (
    <div className="w-full min-w-[329px] p-10 flex items-center justify-center relative">
      <div className="w-full bg-[#183864] p-5 lg:p-20 flex lg:flex-row flex-col justify-between rounded-[10px]" style={{ filter: dropShadowFilter, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' } } >
        <div className="lg:w-[442px]">
          <div className="leading-[24px] lg:leading-[72px] text-[16px] md:text-[30px] lg:text-[48px] font-[600] text-transparent bg-gradient-to-r from-blue-figma via-red-figma to-red-figma bg-clip-text">
            {heroSection?.aboutProsafeTitle}
          </div>
          <div className='font-[600] text-[12px] md:text-[20px] lg:text-[30px] text-white leading-[30px] mt-4'>
            {heroSection?.aboutProsafeDesc}
          </div>
        </div>
        <div className="w-full lg:w-[438px] flex lg:flex-col flex-row items-center lg:mt-0 mt-10 justify-between">
          <div className='flex items-center justify-end'>
            <img
              src={`${process.env.REACT_APP_BASE_URL}/${heroSection?.aboutProsafeImage}`}
              alt="footerLogo"
              className='w-[64px] md:w-[100px] h-[64px] md:h-[100px]'
            />
          </div>
          <div className="w-full flex justify-end">
            <div className="bg-[#B02526] w-[143.9px] md:w-[263px] h-[29px] md:h-[53px] rounded-[5.47px] md:rounded-[10px]">
              <motion.div whileHover={{scale: 1.05}} className="font-bold text-[13.13px] md:text-[24px] leading-[15.76px] md:leading-[28px] hover:bg-blue-500 text-white flex items-center justify-center h-full">{heroSection?.aboutProsafeButton}</motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default About;
