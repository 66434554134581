import React from 'react'
import { Link } from 'react-router-dom'
import {motion} from 'framer-motion'

const BlogCard = ({image,title,date,description, id}) => {
  return (
    <motion.div whileHover={{scale: 1.05}} className='w-[357px] mx-auto flex flex-col my-5 md:w-[90%] md:mx-auto md:my-8 lg:my-10 xl:my-[52px]'>
      <div className='flex flex-col gap-4 md:flex-row xl:gap-[33px]'>
        <div>
            <img src={`${process.env.REACT_APP_BASE_URL}/${image}`} className='w-[282px] h-[282px] mx-auto items-self-center rounded-[6.67px] md:min-w-[282px] lg:min-w-[340px] lg:h-[340px] xl:min-w-[448px] xl:h-[448px]' />
        </div>
        <div className='flex flex-col md:px-[20px] md:py-[20px] lg:px-[30px] lg:py-[30px]'>
            <div className='flex flex-col gap-2'>
                <h1 className='text-base font-bold leading-6 pr-3.5 text-[#183864] lg:text-[26px] lg:leading-10 lg:pr-[40px] xl:text-[33.8px] xl:leading-[67.6px] xl:pr-[55.28px]'>{title}</h1>
                <p className='text-xs font-normal leading-[15.17px] text-[#ADADAD] lg:text-[16px] lg:leading-[22px] xl:text-[22.53px] xl:leading-[29.29px]'>{date}</p>
                <p className='text-sm font-normal leading-[21px] text-[#777777] lg:text-[20px] lg:leading-[31px] xl:text-[26.29px] xl:leading-[39.43px] md:py-[10px] lg:py-[14px] xl:py-[27px]'>{description}</p>
            </div>
            <div>
              <Link to={`/blog-inside?id=${id}`} className='text-[11.67px] font-extrabold leading-[23.34px] text-[#B02526] lg:text-[16px] lg:leading-[33px] xl:text-[22.53px] xl:leading-[45.06px]'>
                Read More
              </Link>
            </div>  
        </div>
        
      </div>
    </motion.div>
  )
}

export default BlogCard
