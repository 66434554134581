import React from 'react'
import verticalDots from '../../assets/verticalDots.svg'
import trainingTopImage from '../../assets/trainingTopImage.png'
import trainingTopLeftImage from '../../assets/trainingTopLeftImage.png'
import trainingHeroVector1 from '../../assets/trainingHeroVector1.svg'
import TrainingHeroTopCircle from '../../assets/TrainingHeroTopCircle.svg'
import Vector from '../../assets/homeBannerVecotr.svg'
import Vector2 from '../../assets/homeBannerVector2.svg'
import TrainingHero from '../../assets/aboutHeroChracter.svg'
import aboutuscircle from '../../assets/aboutuscircle.svg'
import DrBag from '../../assets/DrBag.png'
import { motion } from 'framer-motion';

const SuppliersHeroSection = ({heroSection}) => {
  const bubbleSVG1 = (<svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="47" y="47" width="47" height="47" rx="23.5" transform="rotate(180 47 47)" fill="#E9EFFF"/>
  </svg>)

  return (
    <div className='relative flex w-full h-full lg:h-[604px] overflow-hidden mb-5'>
      <div className='lg:flex hidden absolute left-[54%] top-5 md:top-0'>
        <img className='md:w-[85px] w-[65px]' src={trainingTopImage} alt=''/>
      </div>
      <div className='lg:flex hidden absolute left-[35px] top-5 md:top-[55%] lg:z-[999]'>
        <img className='md:w-[85px] w-[65px]' src={trainingTopLeftImage} alt=''/>
      </div>
      <div className='absolute left-[-25px] top-5 md:top-1/4'>
        <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
      </div>
      <div className='md:flex hidden absolute right-[-25px] top-[10%]'>
        <img src={verticalDots} alt=''/>
      </div>
      {/* <div className='absolute bottom-[20%] right-0 md:right-[11%] lg:z-50'>
        <img className='md:w-[320px] md:h-[320px] w-[175px] h-[176px]' src={TrainingHeroTopCircle} alt=''/>
      </div> */}
      {/* <div className='xl:flex hidden absolute right-[35%] top-[65%]'>
        <img src={aboutuscircle} alt=''/>
      </div> */}
      <img className="lg:flex hidden absolute -bottom-[10px] lg:z-10 w-full" src={Vector} alt="" />
      <img className="lg:flex hidden absolute bottom-[0px] w-full" src={Vector2} alt="" />
      <div className='lg:flex hidden absolute top-1/3 left-1/3'>{bubbleSVG1}</div>

      <div className='lg:flex hidden absolute top-[30%] right-0 md:right-[15%] lg:z-[9999]'>
        <img className='md:w-[85px] w-[65px]' src={trainingTopLeftImage} alt=''/>
      </div>
      <div className='lg:flex hidden absolute bottom-[30%] right-0 md:right-[15%] lg:z-[9999]'>
        <motion.div
          initial={{ x: "-100%" }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.6, type: 'tween' }}
        >
          <img
            className='md:w-[250px] md:h-[250px] w-[175px] h-[176px]'
            src={`${process.env.REACT_APP_BASE_URL}/${heroSection?.image}`}
            alt=''
          />
        </motion.div>
      </div>
      <div className='flex flex-col lg:flex-row justify-start items-center w-full lg:z-[10]'>
        <div className='lg:ml-20 lg:mt-0 mt-10 w-full'>
          <div className='w-full flex items-center flex-col'>
            {/* <div className='lg:hidden flex lg:z-[9999]'>
              <img className='md:w-[85px] w-[65px]' src={trainingTopLeftImage} alt=''/>
            </div> */}
            <div className='lg:hidden flex lg:z-[9999]'>
              <img
                className='md:w-[250px] md:h-[250px] w-[175px] h-[176px]'
                src={`${process.env.REACT_APP_BASE_URL}/${heroSection?.image}`}
                alt=''
              />
            </div>
          </div>
          <div className='w-[90%] mx-auto'>
            <div className='text-[#183864] bg-[#E5EDFF] w-[155px] h-[36px] font-bold text-[14px] leading-[17.64px] rounded-[39px] flex items-center justify-center'>
              <div>
                {heroSection.capsule_text}
              </div>
            </div>
            <div className='lg:w-[500px] xl:w-[644px] text-[#183864] font-semibold text-[32px] md:text-[40px] lg:text-[50px] xl:text-[71px] leading-[40.32px] md:leading-[43.55px] lg:leading-[71px]'>
              {heroSection.heading}
            </div>
            <div className='font-[500] text-[20px] leading-[30px] text-[#5E6282] mt-10'>{heroSection.tagLine}</div>
            <motion.div whileHover={{scale:1.05}} className='w-[163.06px] h-[60px] flex items-center justify-center bg-[#B02526] rounded-[8px] text-[20px] leading-[30px] hover:bg-blue-500 font-bold text-white mt-10 cursor-pointer'>{heroSection.button_text}</motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuppliersHeroSection;
