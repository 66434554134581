import React from "react";
import Layout from '../components/Layout/Layout';
import SuppliersProductsHeroSection from '../components/HeroSection/SuppliersProductsHeroSection'
import Stories from '../components/Supplier/Stories';
import backgroundCover from '../assets/Section.png';
import FAQ2 from "../components/Supplier/Faq2";

const SuppliesProduct = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundCover})`,
    backgroundSize: 'cover',
  };
  return (
    <Layout>
      <div className='overflow-hidden'>
        <div className=''>
          <SuppliersProductsHeroSection />
        </div>
        <div className='mt-10' style={backgroundStyle}>
          <FAQ2 />
          <div className='p-10'>
            <Stories />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default SuppliesProduct;
