import React from "react";
import Layout from '../components/Layout/Layout';
import GeneralHeroSection from "../components/HeroSection/GeneralHeroSection";
import backgroundCover from '../assets/Section.png';
import FAQ from "../components/Supplier/FAQ";
import PrivateContactFAQ from "../components/PrivateContact/privateContactFAQ";
import HeroSectionImage from "../assets/privateContactBanner.png";
const PrivateContact = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundCover})`,
    backgroundSize: 'cover',
  };
  return (
    <Layout>
      <div>
      <GeneralHeroSection image={HeroSectionImage} heading="Private Class Booking" imageStyle="object-cover"/>
      </div>
      <div className='my-10' style={backgroundStyle}>
        <PrivateContactFAQ />
    </div>
    </Layout>
  )
}
export default PrivateContact;
