import React from 'react';

const Loader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <div className="flex justify-center items-center">
          <div className="w-16 h-16 border-4 border-t-4 border-purple-900 border-dashed rounded-full animate-spin"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
