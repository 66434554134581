import React, { useState, useEffect } from "react";
import StripeImage from "../../../src/assets/stripe.png";
import PaypalImage from "../../../src/assets/paypal.png";
import axios from "axios";
import moment from "moment";
import { useCart } from "../Event/CartContext"; // Import the context for the cart
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import Loader from "../common/loader";

const PaymentModal = ({ setStraper, onClose }) => {
  const { cart, dispatch } = useCart(); // Use the context to get the cart
  const [tax, setTax] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    calculateTotalPrice();
  }, [cart]);

  useEffect(() => {
    calculateAndUpdateTax();
  }, [totalPrice]);

  useEffect(() => {
    calculateGrandTotal();
  }, [tax]);

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  const calculateTotalPrice = () => {
    let total = 0;
    cart.forEach((item) => {
      total += item.course.price * item.seats;
    });
    setTotalPrice(total);
  };

  const calculateAndUpdateTax = () => {
    const taxValue = totalPrice * 0.05;
    setTax(taxValue);
  };

  const calculateGrandTotal = () => {
    const value = totalPrice + tax;
    setGrandTotal(value);
  };

  const getInitialFormData = (cart) => {
    return cart.reduce((acc, item) => {
      const savedFormData = JSON.parse(
        localStorage.getItem(`attendees_${item.event._id}`)
      );
      acc[item.event._id] = savedFormData || Array(item.seats).fill({});
      return acc;
    }, {});
  };

  const addAttendee = async (initialFormData, dispatch, paymentAmount) => {
    try {
      const addeventIdentifier = uuidv4();
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/event/addAttendees`,
        {
          attendees: initialFormData,
          addeventIdentifier,
          paymentAmount
        }
      );
      console.log("Attendees added successfully:", response.data);
      await dispatch({ type: "CLEAR_CART" });
      localStorage.clear();

      return response.data;
    } catch (error) {
      console.error("Error adding the attendees!", error);
      toast.error(
        "There was an error adding the attendees, please try again later."
      );
    }
  };

  function transformData(data, addeventIdentifier) {
    const result = [];
  
    for (const [eventId, attendees] of Object.entries(data)) {
      const newAttendeesEmails = attendees?.newAttendees?.map((attendee) => attendee.email) || [];
      const existingAttendeesEmails = attendees?.existingAttendees?.map((attendee) => attendee.email) || [];
      const attendeesEmail = Array.from(new Set([...newAttendeesEmails, ...existingAttendeesEmails]));
        
      const billingEmail = attendees?.newAttendees?.[0]?.billingEmail 
        ? [attendees.newAttendees[0].billingEmail] 
        : attendees?.existingAttendees?.[0]?.billingEmail 
          ? [attendees.existingAttendees[0].billingEmail]
          : [];
  
      result.push({ attendeesEmail, eventId, billingEmail, addeventIdentifier });
    }
  
    return result;
  }
  

  const handlePaymentClick = async () => {
    setIsLoading(true)
    if (selectedPayment !== "card") {
      setIsLoading(false)
      toast.error("Please select card payment method");
      return;
    }
    const initialFormData = getInitialFormData(cart);
    try {
      const attendees = await addAttendee(initialFormData, dispatch, grandTotal);
      if (
        !attendees.eventAttendees ||
        Object.keys(attendees.eventAttendees).length === 0
      ) {
        setIsLoading(false)
        toast.error(
          "Not enough seats available for event. Please try reducing your number of seats."
        );
        return; // Exit the function if there are not enough seats
      }
      // onClose();
      const data = transformData(attendees.eventAttendees, attendees?.addeventIdentifier);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/stripe/checkout/create-checkout-session`,
        { data, orderPrice: grandTotal}
      );
      // console.log("Payment link created:", response.data);
      setIsLoading(false)
      onClose();
      window.location.href = response.data.url;
    } catch (error) {
      setIsLoading(false)
      console.error("There was an error creating the payment link!", error);
      toast.error(
        "There was an error creating the payment link, please try again later."
      );
    }
  };
  return (
    <div className="relative w-[90%] sm:w-[80%] md:w-[90%] h-[50vh] m-auto mt-10 pb-4 z-[9999]">
      <div className="mt-2 p-4">
        <h2 className="sm:text-2xl text-lg font-bold w-full flex justify-center text-slate-600 uppercase">Payment Info</h2>
        <h3 className="text-xl font-semibold mb-5 text-slate-600">Registrations</h3>
        <div className="overflow-x-auto rounded-lg">
          <table className="min-w-[500px] w-full border-0 rounded-lg overflow-hidden ">
            <thead className="bg-slate-600 text-white">
              <tr className="text-center">
                <th className="p-2 text-sm font-bold uppercase">Name and Description</th>
                <th className="p-2 text-sm font-bold border-x  uppercase">Price</th>
                <th className="p-2 text-sm font-bold border-x uppercase">Qty</th>
                <th className="p-2 text-sm font-bold uppercase">Total</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {cart.map((item, index) => (
                <tr key={index} className="text-center border-t border-[#9A9EA6]">
                  <td className="p-2 text-[14px] text-[#9A9EA6]">{`${item?.course?.name} - ${moment(item.event.startDate).format("MMMM DD, YYYY")} - ${item.event.campus}`}</td>
                  <td className="p-2 text-[14px] border-x text-[#9A9EA6]">${item.course.price} CAD*</td>
                  <td className="p-2 text-[14px] border-x text-[#9A9EA6]">{item.seats}</td>
                  <td className="p-2 text-[14px] text-[#9A9EA6]">${item.course.price * item.seats}</td>
                </tr>
              ))}
              <tr className="text-center border-t border-[#9A9EA6]">
                <td className="p-2 text-[14px] text-[#9A9EA6]">GST * taxable items</td>
                <td className="p-2 text-[14px] border-x text-[#9A9EA6]">5%</td>
                <td className="p-2 text-[14px] border-x text-[#9A9EA6]"></td>
                <td className="p-2 text-[14px] text-[#9A9EA6]">${tax}</td>
              </tr>
              <tr className="text-center border-t border-[#9A9EA6]">
                <td className="p-2 text-[14px] text-[#9A9EA6]"></td>
                <td className="p-2 text-[14px] border-x text-[#9A9EA6]">Tax Total</td>
                <td className="p-2 text-[14px] border-x text-[#9A9EA6]"></td>
                <td className="p-2 text-[14px] text-[#9A9EA6]">${tax}</td>
              </tr>
              <tr className="text-center border-t border-[#9A9EA6] rounded-bl-lg rounded-br-lg">
                <td className="p-2 text-[14px] text-[#9A9EA6]"></td>
                <td className="p-2 text-[14px] border-x text-[#9A9EA6]"></td>
                <td className="p-2 text-[14px] border-x text-[#9A9EA6]">Total</td>
                <td className="p-2 text-[14px] text-[#9A9EA6]">${grandTotal}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-5">
          <h1 className="text-xl font-semibold mb-2 text-[#183864]">Select Your Payment Method</h1>
          <form>
            <div className="flex gap-4">
              {/* <div>
                <label>
                  <div className="flex gap-3 items-center">
                    <input
                      type="radio"
                      value="paypal"
                      checked={selectedPayment === "paypal"}
                      onChange={handlePaymentChange}
                      className="mr-2"
                    />
                    <img src={PaypalImage} alt="Paypal" width={150} height={150} />
                  </div>
                </label>
              </div> */}
              <div>
                <label>
                  <div className="flex gap-3 items-center">
                    <input
                      type="radio"
                      value="card"
                      checked={selectedPayment === "card"}
                      onChange={handlePaymentChange}
                      className="mr-2"
                    />
                    <img src={StripeImage} alt="Stripe" width={150} height={150} />
                  </div>
                </label>
              </div>
            </div>
          </form>
        </div>
        <div className="flex justify-end gap-2 mt-5">
          <button
            type="button"
            className="py-2 px-4 bg-[#B02526] text-white font-medium rounded hover:bg-[#931d1d] transition duration-200"
            onClick={() => setStraper(4)}
          >
            View Events Cart
          </button>
          <button
            type="button"
            className="py-2 px-4 bg-[#B02526] text-white font-medium rounded hover:bg-[#931d1d] transition duration-200"
            onClick={handlePaymentClick}
          >
            {isLoading && <Loader />}
            {isLoading ? "Processing" : "Proceed to Check Out"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
