import React from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Header = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('studentID');

    navigate('/student/login');
  };

  return (
    <div className="flex justify-end items-center bg-white shadow-md p-4 sticky top-0 z-10 w-full">
      {/* <button className="lg:hidden text-2xl" onClick={toggleSidebar}>
        <FaBars />
      </button> */}
      <button
        onClick={handleLogout}
        className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-md flex items-center"
      >
        <FaSignOutAlt className="mr-2" /> Logout
      </button>
    </div>
  );
};

export default Header;
