import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Layout/Header';
import About from './pages/About';
import Home from './pages/Home';
import Courses from './pages/Courses';
import Suppliers from './pages/Supplies';
import Learning from './pages/Learning';
import AboutUs from './pages/AboutUs';
import FAQ from './pages/FAQ';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import CourseAbout from './pages/CourseAbout';
import TrainingOFA from './pages/TrainingOFA';
import BlogInside from './components/Blog/BlogInside';
import PrivateContact from './pages/PrivateContact';
import SuppliesProduct from './pages/SuppliesProduct';
import SuppliersProductView from './pages/SuppliersProductView';
import { CartProvider } from './components/Event/CartContext';
import CartModal from './components/Event/CartModal';
import Cart from './pages/Cart';
import PaymentSuccessfull from './pages/paymentSuccess';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactDetail from './pages/ContactDetail';
import AttendeeRegistrationForm from './components/Event/private-events/attendeeInfoForm';
import Login from './pages/Login';
import StundentDetails from './pages/StundentDetails';
import PrivateRoute from './PrivateRoute';

function App() {
  return (
    <CartProvider>
      <ToastContainer className="!z-[9999999]" />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/all-courses" element={<Courses />} />
          <Route path="/training-worksafe/:name" element={<TrainingOFA />} />
          <Route path="/course-about/:name" element={<CourseAbout />} />
          <Route path="/supplies" element={<Suppliers />} />
          <Route path="/eLearning" element={<Learning />} />
          <Route
            path="/event/:eventId/add-attendees/:maxSeats"
            element={<AttendeeRegistrationForm />}
          />
          {/* <Route path='/about_us' element={<AboutUs />} /> */}
          <Route path="/faq" element={<FAQ />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-inside" element={<BlogInside />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/:id" element={<ContactDetail />} />
          <Route path="/contact-private" element={<PrivateContact />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/supplies_products" element={<SuppliesProduct />} />
          <Route
            path="/supplies-product-view"
            element={<SuppliersProductView />}
          />
          <Route path="/supplies-cart" element={<Cart />} />
          <Route path="/payment-successfull" element={<PaymentSuccessfull />} />
          <Route path="/student/login" element={<Login />} />
          <Route path="/student/dashboard" element={<PrivateRoute element={<StundentDetails />} />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
