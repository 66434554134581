import { Types } from "../actionTypes";

const initialState = {
  userAccountData: {},
  loading: false
};

const accoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_ACCOUNT_DETAILS:
      return {
        ...state,
        loading: true
      };
    case Types.GET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        userAccountData : action.payload,
        loading: false
      };
    default:
      return state;
  }
};
  
  export default accoutReducer;
  