import React from 'react';
import { useCart } from './CartContext';
import CartItems from './CartItems';
import { toast } from 'react-toastify';

const CartModal = ({ onClose, setStraper }) => {
  const { cart, dispatch } = useCart();
  const getInitialFormData = () => {
    if (Object.keys(localStorage).filter((item) => item.includes('attendees_')).length > 0) {
      return cart.reduce((acc, item) => {
        const savedFormData = JSON.parse(localStorage.getItem(`attendees_${item.event._id}`));
        acc[item.event._id] = savedFormData || Array(item.seats).fill({});
        return acc;
      }, {});
    }
    return {};
  };
  const checkAttendeeData = async () => {
    const initialFormData = await getInitialFormData();
    if (Object.keys(initialFormData).length !== cart.length) {
      return false;
    }
    for (let item of cart) {
      if (item.seats !== initialFormData[item.id].length) {
        return false;
      }
    }
    return true;
  }
  const redirectToAttendeeInfo = async () => {
    const initialFormData = await getInitialFormData();
    if (Object.keys(initialFormData).length === 0 && cart.length === 0) {
      onClose();
      return;
    }
    if (Object.keys(initialFormData).length !== cart.length) {
      setStraper(2);
      return;
    }
    for (let item of cart) {
      if (item.seats !== initialFormData[item.id].length) {
        setStraper(2);
        return;
      }
    
      if (initialFormData[item.id].some(obj => Object.keys(obj).length === 0)) {
        setStraper(2);
        return;
      }

      const isVerified = localStorage.getItem("isAllEmailVerified");
      if (isVerified !== null && isVerified === "false") {
        setStraper(2);
        return;
      }
    }
    setStraper(3);
    // return true;
    // if (res) {
    //   setStraper(3);
    // } else {
    //   setStraper(2);
    // }
  }
  const handleRemoveFromCart = (id) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id } });
  };
  const clearCart = () => {
    localStorage.clear();
    dispatch({ type: 'CLEAR_CART'});
  };
  const increaseNoSeats = (id, seat) => {
    const cartItem = {
      id: id,
      seats: seat+1
    }
    dispatch({ type: 'UPDATE_EVENT_SEATS_CART', payload: cartItem });
    toast.success("Event updated cart!");
  }
  const decreaseNoSeats = async (id, seat) => {
    const initialFormData = await getInitialFormData();
    if ((initialFormData[id]?.length >= seat)) {
      initialFormData[id].pop();
      localStorage.setItem(`attendees_${id}`, JSON.stringify(initialFormData[id]));
    }

    if (seat > 1) {
      const cartItem = {
        id: id,
        seats: seat-1
      }
      dispatch({ type: 'UPDATE_EVENT_SEATS_CART', payload: cartItem });
      toast.success("Event updated cart!");
    } else {
      localStorage.removeItem(`attendees_${id}`);
      handleRemoveFromCart(id)
    }
  }
  return (
    // <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999] w-full">
      <div className="w-[90%] m-auto mt-10 pb-4 z-[9999]">
      <div className=" p-4 rounded-lg w-full"> {/* Increased max-width to 3xl */}
        <h2 className="text-2xl font-bold mb-4">Event Cart</h2>
        {cart.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          cart.map((item, index) => (
            <CartItems
              item={item}
              index={index}
              handleRemoveFromCart={handleRemoveFromCart}
              increaseNoSeat={increaseNoSeats}
              decreaseNoSeats={decreaseNoSeats}
            />
          ))
        )}
        <div className='flex items-center gap-3'>
          <button className="mt-4 bg-blue-500 text-white p-2 rounded cursor-pointer" onClick={() => redirectToAttendeeInfo()}>
            Return to Registrations
          </button>
          <button className="mt-4 bg-green-500 text-white p-2 rounded cursor-pointer" onClick={() => onClose()}>
            Close Cart
          </button>
          <button className="mt-4 bg-red-500 text-white p-2 rounded cursor-pointer" onClick={() => clearCart()}>
            Empty Events Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
