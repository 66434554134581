import React from "react";
import backgroundCover from '../../assets/learningBackground.png';
import Mask from '../../assets/dots-rectangle.svg';
import telephoneVector from '../../assets/telephone.svg'
import mailVector from '../../assets/mailVector.svg'
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom';

const FAQ = ({contact}) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundCover})`,
    backgroundSize: 'cover',
    zIndex: '0'
  };
  const dropShadowFilter = 'drop-shadow(43px -33px 0px #E5EDFF)';
  return (
    <div className="lg:relative  lg:h-[550px] w-[95%] lg:w-full mx-auto lg:mx-0 bg-white">
      <div>
        <div className="pt-20 inset-0 lg:flex hidden" style={backgroundStyle}></div>
      </div>

      <div className="lg:absolute lg:top-16 lg:left-[50px] lg:z-[999] flex justify-center">
        <motion.div
          // initial={{ x: -100, opacity: 0 }}
          // whileInView={{ x: 0, opacity: 1 }}
          // transition={{ duration: 1 }}
          className="w-[90%] xl:w-[658px] lg:max-h-[581px] bg-white my-10 px-10 shadow-md rounded-[10px] m-5 lg:m-0"
          style={{ boxShadow: '0 -2px 10px 0 rgba(0, 0, 0, 0.2)' }}
        >
          <div className="w-full py-1">
            <div className="w-full text-[#0E0E0E] leading-[46.8px] text-[20.19px] md:text-[36px] font-[600] flex justify-center">Enter Your Details</div>
            <form className="w-full pb-4">
              <div className="lg:flex justify-between w-full gap-8 mb-0 lg:mb-4 mt-4">
               <div className="form-control w-full mb-8 lg:mb-0">
                <input className="text-[10.09px] md:text-[18px]" type="value" required />
                <label>
                 <span>First Name</span>
                </label>
               </div>
                <div className="form-control w-full mb-8 lg:mb-0">
                  <input className="text-[10.09px] md:text-[18px]" type="value" required />
                  <label>
                  <span>Last Name</span>
                  </label>
                </div>
              </div>
              <div className="lg:flex justify-between w-full gap-8 mt-0 mb-0 lg:mb-4 lg:mt-4">
                <div className="form-control w-full mb-8 lg:mb-0">
                  <input className="text-[10.09px] md:text-[18px]" type="value" required />
                  <label>
                    <span>Email*</span>
                  </label>
               </div>
               <div className="form-control w-full  mb-8 lg:mb-0">
                  <input className="text-[10.09px] md:text-[18px]" type="value" required />
                  <label>
                  <span>Phone</span>
                  </label>
               </div>
              </div>
              <div className="flex justify-between w-full gap-8 mb-6 mt-4">
              <div className="form-control w-full">
                  <input className="text-[10.09px] md:text-[18px]" type="value" required />
                  <label>
                   <span>Location</span>
                  </label>
              </div>
              </div>
              <div className="flex justify-between w-full gap-8 mb-6 mt-4">
                <div className="form-control w-full">
                    <input className="text-[10.09px] md:text-[18px]" type="value" required />
                    <label>
                      <span>Training Topics</span>
                    </label>
                </div>
              </div>
              <div className="form-control w-full mt-4">
                  <input className="text-[10.09px] md:text-[18px]" type="value" required />
                  <label>
                   <span>Additional Topics</span>
                  </label>
              </div>
              <div className="flex justify-end mt-4">
                <motion.div
                whileHover={{ scale: 1.05 }}
                >
                <button className="bg-[#B02526] w-[100px] py-2 rounded-[6px] font-[600] text-white text-[17px] leading-[28px]  transition-colors duration-300 hover:bg-blue-500 shadow-custom transform hover:scale-110 transition-transform duration-300">
                  Send
                </button>
                </motion.div>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
      <div className="flex flex-row-reverse  items-center justify-center lg:justify-start relative lg:h-[500px] lg:pl-20 md:mr-10">
        <div className="sm:w-[31.8rem] w-[80%] h-[20.2rem] p-2 sm:p-0">
            <h2 className="font-bold text-xl leading-7 text-[#183864]">Contact Info</h2>
            <div className="flex mb-6 mt-12">
                <div className="flex w-[20%] mr-2">
                  <button className="w-12 h-12 flex items-center justify-center rounded-[30px]" style={{ backgroundColor: 'rgba(176, 38, 39, 0.16)' }}><img src={telephoneVector}></img></button>
                </div>
                <div className="">
                  <div className="flex md:grid grid-cols-2">
                    {contact?.contactInfo?.map((campus, index) => (
                      <p className="text-[#183864] text-wrap">{contact?.contactInfo[index]}</p>
                    ))}
                      {/* {contact &&
                        <div className="gap-2">
                          {contact.contactInfo.map((item, index) => {
                            <p className="text-[#183864] text-nowrap">(604) 585 – SAFE (7233) dfsfdsdfsdfsdfsdf,</p>
                          })
                          }
                        </div>

                      } */}
                        {/* <p className="text-[#183864] text-nowrap">(604) 585 – SAFE (7233) ,</p>
                        <p className="text-[#183864] text-nowrap">1 (833) 585 – SAFE (7233)</p> */}
                    </div>
                    {/* <div>
                        <p className="text-[#183864]">(604) 501 – 2060 (Fax)</p>
                    </div> */}
                </div>
            </div>
            <div className="flex mt=6 mb-12">
                <div className="flex w-[20%]">
                    <button className="w-12 h-12 flex items-center justify-center rounded-[30px]" style={{ backgroundColor: 'rgba(176, 38, 39, 0.16)' }}><img src={mailVector}></img></button>
                </div>
                <div>
                  <div className="flex md:grid grid-cols-2 items-center">
                    {contact?.emailInfo?.map((campus, index) => (
                      <p className="text-[#183864] text-wrap">{contact?.emailInfo[index]}</p>
                    ))}
                  </div>
                    {/* <div className="flex flex-col sm:flex-row">
                        <p className="text-[#183864] mr-2">info@prosafefirstaid.ca</p>
                        <p className="text-[#183864]">careers@prosafefirstaid.ca</p>
                    </div> */}
                </div>
            </div>
            <Link to="/contact-private" className="font-bold text-xl leading-6 text-[#183864]">Private Group Inquiry</Link>

        </div>
      </div>

    </div>
  )
}
export default FAQ;
