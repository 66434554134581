import React from 'react';
import { FaUser, FaChartLine, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div
      className={`bg-[#183864] z-20 text-white min-h-screen fixed top-0 left-0 bottom-0 transition-all duration-300 ease-in-out
      ${isOpen ? 'w-64' : 'w-16'}`}
    >
      <div className="flex justify-between items-center p-4">
      <button
        className={`text-white absolute ${isOpen ? 'top-4 right-4' : "left-1/4 top-2"}`}
        onClick={toggleSidebar}
        >
        {isOpen ? <FaArrowLeft /> : <FaArrowRight />}
        </button>

        <div className={`transition-all duration-300 ${isOpen ? 'flex' : 'hidden'}`}>
          <img
            src="/images/logo-prosafe.png"
            alt="Logo"
            className="w-20"
          />
        </div>
      </div>

      <div className="flex flex-col">
        <ul className={`${isOpen ? 'space-y-2' : 'space-y-1'}`}>
          <li className="px-4 py-2 hover:opacity-90 cursor-pointer flex items-center">
            <Link to="/student/dashboard" className="flex items-center">
              <FaUser className={`${isOpen ? 'mr-3' : ''}`} />
              <span className={`text-md ${isOpen ? '' : 'hidden'}`}>Student Details</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
