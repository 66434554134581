import React, { useState, useEffect } from "react";
import Bubble from "../../../assets/buttonBubble.svg";
import DotRectangle from "../../../assets/dots-rectangle.svg";
import DotRectangle2 from "../../../assets/dots-rectangle2.svg";
import ButtonVector from "../../../assets/buttonVector.svg";
import { motion } from "framer-motion";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const Cources = () => {
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formattedCategoryName, setFormattedCategoryName] = useState("");
  const [isCategorySet, setIsCategorySet] = useState(false);

  const location = useLocation();
  const pathParts = location.pathname?.split("/");
  const activePath = pathParts[pathParts?.length - 1];

  useEffect(() => {
    if (category?.length > 0 && !isCategorySet) {
      setSelectedCategory(category[0]);
      setIsCategorySet(true);
    }
  }, [category, isCategorySet]);

  useEffect(() => {
    if (selectedCategory) {
      setFormattedCategoryName(formatCategoryName(selectedCategory.name));
    }
  }, [selectedCategory]);

  const handleCategoryClick = (clickedItem) => {
   localStorage.setItem("selectedCat",JSON.stringify(clickedItem));
    setSelectedCategory(clickedItem);
  };

  useEffect(() => {
    const selectedCat = JSON.parse(localStorage.getItem("selectedCat"));
    if (selectedCat && !isCategorySet) {
      setSelectedCategory(selectedCat);
      setIsCategorySet(true);
    }
  }, [isCategorySet]);

  useEffect(() => {
    const getCategory = () => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/category`)
        .then((response) => {
          setCategory(response?.data?.data?.categories);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    getCategory();
  }, []);
  
  const gradientTextStyle = {
    background: "linear-gradient(90deg, #0B57E3 0%, #DF0A0A 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  const formatCategoryName = (name) => {
    return name.replace(/\s+/g, "-");
  };

const courseClickHandle = (subjectName,courseId)=>{
  navigate(`/course-about/${subjectName}`,{state:{courseId}})
}

  return (
    <>
      <div className="relative w-full lg:p-14 xl:p-0">
        <div className="absolute right-0">
          <img src={DotRectangle} alt="Mask" className="lg:flex hidden" />
        </div>
        <div className="absolute left-8 -top-[10px]">
          <img
            className="bubbling-svg lg:flex w-[40px]"
            src={DotRectangle2}
            alt="Mask"
          />
        </div>
        <div className="absolute -bottom-0 right-[550px]">
          <img src={Bubble} alt="Bubble" />
        </div>
        <div className="absolute bottom-[220px] right-[350px]">
          <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="39"
              y="39"
              width="39"
              height="39"
              rx="19.5"
              transform="rotate(180 39 39)"
              fill="#E9EFFF"
            />
          </svg>
        </div>
        <div>
          <h1 className="text-center font-[800] leading-[50px] text-[27.69px] lg:text-5xl mb-2 lg:mb-5">
            Course offering
          </h1>
          <p className="text-lg font-[800] leading-[26.5px] text-[12px] lg:text-[#5E6282] text-center lg:w-full w-[90%] mx-auto lg:mx-0">
            Discover our comprehensive course schedule across our multiple
            campuses!
          </p>
        </div>
        <div className="w-full flex flex-col lg:flex-row p-2  md:p-14 lg:p-4 xl:p-14 items-center justify-center xl:gap-0 gap-10">
          <div className="w-full lg:w-[50%] h-full flex items-center justify-center">
            <div className="w-[351.93px] lg:w-[70rem] xl:w-[74rem] h-[32.44rem] bg-[#183864] rounded-[5px]">
              <motion.div
                initial={{ x: -28, y: -28 }}
                whileInView={{ x: 0, y: 0 }}
                transition={{ duration: 1 }}
                className="relative flex justify-between top-2 lg:top-7 left-2 lg:left-7 w-[351.93px] lg:w-[70rem] xl:w-[74rem] h-[32.44rem] lg:z-10 bg-[#FFFFFF] rounded-[5px] p-2"
                style={{ boxShadow: "6px 7px 10px 0px rgba(3,27,78,0.15)" }}
              >
                <div className="h-full overflow-hidden p-4">
                  <p
                    className="text-[15.89px] lg:text-xl leading-[19.07px] lg:leading-6 inline font-[600]"
                    style={gradientTextStyle}
                  >
                    WHAT WE DO
                  </p>
                  <div className="h-full overflow-y-auto p-4">
                    <div className="w-full flex flex-col justify-evenly pl-12 flex-grow">
                      {category &&
                        category.map((items, index) => {
                          return (
                            <div
                              className={` ${
                                selectedCategory?._id === items._id
                                  ? "bg-[#B02526]"
                                  : "hover:bg-[#1b1313]"
                              } cursor-pointer w-[21.4rem] border-2 border-[#E7E7E7] border-solid rounded-[7.94px] lg:rounded-[0.7rem]`}
                              onClick={() => handleCategoryClick(items)}
                              key={index}
                            >
                              <p
                                className={`${
                                  selectedCategory?._id === items._id
                                    ? "text-white"
                                    : "text-black"
                                } hover:text-[#FFFFFF] min-h-[51px] flex justify-center items-center w-full text-center text-[12.71px] leading-[22.24px] lg:text-base font-semibold m-auto`}
                              >
                                <div className="w-[80%]">{items.name}</div>
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="relative w-full lg:w-[50%] h-full flex flex-col justify-start pl-6 mt-20">
                  {selectedCategory?.courses &&
                    selectedCategory?.courses?.map((item, index) => {
                      // const subjectName = item.name.replace(/\s+/g, "-");
                      const generateURLName = (name) => {
                        return name?.trim()?.replace(/–/g, "-")?.replace(/\s+/g, "-")?.replace(/-+/g, "-");
                      };
                      const subjectName = generateURLName(item.name);
                      return (
                        <div
                          onClick={()=>courseClickHandle(item.slug,item?._id)}
                          className="flex items-center cursor-pointer"
                          key={index}
                        >
                          <div className="inline-block mr-2 w-2 h-2 rounded-full bg-[#183864]"></div>
                          <p className="font-[600] text-[13.55px] md:text-[20px] leading-[20.33px] md:leading-[36px] text-[#183864]">
                            {item.name}
                          </p>
                        </div>
                      );
                    })}
                  {activePath !== "all-courses" && (
                    <div className="flex justify-end h-[20%] items-end mt-10 md:mt-0">
                      <motion.div whileHover={{ scale: 1.05 }}>
                        <Link
                          to={`/all-courses`}
                          className="flex font-[600] text-[13.05px] md:text-[20px] leading-[18.27px] md:leading-[28px] items-center justify-end gap-1 text-[#B02526]"
                        >
                          See All Courses
                          <img
                            className=""
                            src={ButtonVector}
                            alt="See All Courses"
                          />
                          <div className="border-b-2 border-[#E5E5E5] h-[2px] w-[100px] md:w-[222px]"></div>
                        </Link>
                      </motion.div>
                    </div>
                  )}
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cources;
