import React from "react";
import backgroundCover from '../../../assets/closeUpWomen.png';
import backgroundCoverMobile from '../../../assets/closeUpMobile.png';
import wifi from '../../../assets/wifi.png';
import {motion} from 'framer-motion'

const Connect = ({data}) => {
  const backgroundStyle = {
    backgroundImage: `url(${data.image})`,
    backgroundSize: 'cover',
    zIndex: '0',
  };
  const backgroundStyles = {
    backgroundImage: `url(${data.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center', // Adjust this as needed
    height: '150px',
    zIndex: '0',
};

  const textContainerStyle = {
    position: 'relative', // Ensure the text container is positioned relative to the parent
  };

  return (
    <>
      <div className="my-20 md:flex hidden">
        <div
          className="max-w-[1311.68px] h-[368px] bg-[#183864] opacity-85 mix-blend-multiply w-[90%] mx-auto rounded-[12px]"
          style={backgroundStyle}
        >
          <div className="ml-10 pt-20" style={textContainerStyle}>
            <div className="flex text-white items-center">
              <div className="font-[600] text-[50px] lg:text-[64px]">{data.heading}</div>
              <div>
                <img src={wifi} alt="wifi" className="" />
              </div>
            </div>
            <div className="text-[18px] leading-[25px] text-white">{data.tagLine}</div>
              <motion.div
                whileHover={{ scale: 1.05 }}
              >
                <button className="mt-10 bg-[#B02526] w-[134.38px] py-4 text-white rounded-[8px] font-[500] text-[14px] leading-[14px]  transition-colors duration-300 hover:bg-blue-500 shadow-custom transform hover:scale-110 transition-transform duration-300" style={textContainerStyle}>{data.button_text}</button>
              </motion.div>
          </div>
        </div>
      </div>
      <div className="my-5 md:hidden flex">
        <div
          className="bg-[#183864] opacity-85 mix-blend-multiply w-[90%] mx-auto rounded-[12px]"
          style={backgroundStyles}
        >
          <div className="ml-5 pt-10" style={textContainerStyle}>
            <div className="flex text-white items-center">
              <div className="font-[600] text-[18.26px] leading-[18.26px]">{data.heading}</div>
              <div>
                <img src={wifi} alt="wifi" className="" width={30} height={30} />
              </div>
            </div>
            <div className="text-[5.14px] leading-[7.2px] text-white">{data.tagLine}</div>
            <motion.div
                whileHover={{ scale: 1.05 }}
              >
              <button className="mt-2 bg-[#B02526] w-[37.94px] md:w-[134.38px] py-2 md:py-4 text-white rounded-[2px] md:rounded-[8px] font-[500] text-[4.11px] md:text-[14px] leading-[4.11px] md:leading-[14px]  transition-colors duration-300 hover:bg-blue-500 shadow-custom transform hover:scale-110 transition-transform duration-300" style={textContainerStyle}>{data.button_text}</button>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Connect;
