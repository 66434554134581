import React from 'react'

const CartItems = ({item, index, handleRemoveFromCart, increaseNoSeat, decreaseNoSeats}) => {
  return (
    <div key={index} className="mb-4">
    <div className="flex justify-between border border-1 border-b-gray-300 border-t-0 border-l-0 border-r-0 pb-2 shadow-md px-5 py-3 rounded-lg">
      <div className='flex flex-col gap-2'>
        <h3 className="font-bold">{item?.name}</h3>
        <div className='flex items-center gap-3 mt-2'>
          <p className='font-semibold'>Seats:</p>
          <input value={item?.seats} className="w-[40px] rounded-md shadow-lg bg-gray-100 pl-2"/>
          <div className='bg-blue-600 p-1 rounded-lg ml-3 cursor-pointer' onClick={() => increaseNoSeat(item?.id, item?.seats)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth="2.5" stroke="white" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </div>
          <div className='bg-blue-600 p-1 rounded-lg cursor-pointer' onClick={() => decreaseNoSeats(item?.id, item?.seats)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="white" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
            </svg>
          </div>
        </div>
        <p className='mt-2'><span className='font-semibold'>Price:</span> ${item?.price * item?.seats} CAD</p>
        <p className='mt-2'><span className='font-semibold'>Total Price:</span> ${item?.price * item?.seats} CAD</p>
      </div>
      <button
        className="bg-red-500 text-white rounded h-fit px-2 py-2"
        onClick={() => handleRemoveFromCart(item?.id)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
      </button>
    </div>
  </div>
  )
}

export default CartItems
