import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import axios from 'axios';

const Stories = () => {
  const [stories, setStories] = useState([])
  useEffect(() => {
    const getStoriesData = () => {
      console.log("${process.env.REACT_PUBLIC_BASE_URL}", process.env.REACT_APP_BASE_URL)
      axios.get(`${process.env.REACT_APP_BASE_URL}/story`)
      .then(response => {
        setStories(response?.data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
    getStoriesData()
  }, [])
  console.log("storiesstoriesstories", stories)
  return (
    <>
      <div className='font-[800] text-[27.69px] md:text-[32px] lg:text-[40px] leading-[62px] text-white mb-10'>Our Stores</div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 justify-items-center'>
        {stories?.map((item, index) => (
          <>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className='w-[210px] h-[198px] rounded-[10px] bg-white'>
              <div className='text-[#183864] font-[800] text-[15.96px] md:text-[20px] leading-[25.2px] pt-10 pl-5'>{item.name}</div>
              <div className='font-[400] text-[12.77px] md:text-[16px] leading-[22px] text-[#777777] pt-5 pl-5'>
                {item.address}
              </div>
            </motion.div>
          </>
        )
      )}
      </div>
      {/* <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 justify-items-center'>
        <motion.div
          whileHover={{ scale: 1.05 }}
          className='w-[210px] h-[198px] rounded-[10px] bg-white'>
          <div className='text-[#183864] font-[800] text-[15.96px] md:text-[20px] leading-[25.2px] pt-10 pl-5'>Surrey</div>
          <div className='font-[400] text-[12.77px] md:text-[16px] leading-[22px] text-[#777777] pt-5 pl-5'>
            Unit 208 – 8433 132 StreetSurrey, BC V3W 4N8
          </div>
        </motion.div>
        <motion.div 
          whileHover={{ scale: 1.05 }} 
          className='w-[210px] h-[198px] rounded-[10px] bg-white'
          >
          <div className='text-[#183864] font-[800] text-[15.96px] md:text-[20px] leading-[25.2px] pt-10 pl-5'>Abbotsford</div>
          <div className='font-[400] text-[12.77px] md:text-[16px] leading-[22px] text-[#777777] pt-5 pl-5'>
            Unit 212 – 31838 S Fraser WayAbbotsford BC V2T 1V3
          </div>
        </motion.div>
        <motion.div 
          whileHover={{ scale: 1.05 }}
          className='w-[210px] h-[198px] rounded-[10px] bg-white'>
          <div className='text-[#183864] font-[800] text-[15.96px] md:text-[20px] leading-[25.2px] pt-10 pl-5'>Port Coquitlam</div>
          <div className='font-[400] text-[12.77px] md:text-[16px] leading-[22px] text-[#777777] pt-5 pl-5'>
            Unit 101 – 1320 Kingsway AvePort Coquitlam BC V3C 6P4
          </div>
        </motion.div>
        <motion.div 
          whileHover={{ scale: 1.05 }}
          className='w-[210px] h-[198px] rounded-[10px] bg-white'>
          <div className='text-[#183864] font-[800] text-[15.96px] md:text-[20px] leading-[25.2px] pt-10 pl-5'>Richmond</div>
          <div className='font-[400] text-[12.77px] md:text-[16px] leading-[22px] text-[#777777] pt-5 pl-5'>
            Unit 280 – 4320 Viking WayRichmond, BC V6V 2L5
          </div>
        </motion.div>
        <motion.div 
          whileHover={{ scale: 1.05 }}
          className='w-[210px] h-[198px] rounded-[10px] bg-white'>
          <div className='text-[#183864] font-[800] text-[15.96px] md:text-[20px] leading-[25.2px] pt-10 pl-5'>Burnaby</div>
          <div className='font-[400] text-[12.77px] md:text-[16px] leading-[22px] text-[#777777] pt-5 pl-5'>
            Unit 100 – 5151 Canada WayBurnaby BC V5E 3N1
          </div>
        </motion.div>
      </div> */}
    </>
  )
}
export default Stories;
