import React, { useState, useEffect, useRef } from 'react';

const SearchableSelect = ({ options, selectedValue, onChange, placeholder = "Select a location" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectOption = (option) => {
    if (selectedValue === option) {
      onChange('All locations');
    } else {
      onChange(option);
    }
    setIsOpen(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = options.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative md:min-w-[600px] min-w-[300px] w-full" ref={dropdownRef}>
      <div
        onClick={handleToggleDropdown}
        className="w-full h-[50px] px-4 border border-gray-300 rounded-lg shadow-sm bg-white cursor-pointer flex items-center justify-between text-lg"
      >
        <span className="text-gray-700">{selectedValue || placeholder}</span>
        <svg
          className={`w-5 h-5 text-gray-400 transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      {isOpen && (
        <div className="absolute w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search..."
            className="w-full px-4 py-3 border-b border-gray-200 focus:outline-none text-lg"
          />
          <div className="max-h-48 overflow-y-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleSelectOption(option)}
                  className="px-4 py-3 cursor-pointer flex justify-between items-center hover:bg-blue-100"
                >
                  <span>{option}</span>
                  {option === selectedValue && (
                    <svg
                      className="w-5 h-5 text-blue-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  )}
                </div>
              ))
            ) : (
              <div className="px-4 py-3 text-gray-500">No results found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchableSelect;
