import React from "react";
import EventModal from "../Event/EventModal";
import PaymentModal from "../Event/PaymentModal";
import CartModal from "../Event/CartModal";
import AttendeeInfo from "../Event/AttendeeInfo.Modal";
import { useStrapperContext } from '../Strapper/StrapperContext'; // Import the context
import ConfirmEmail from "../Event/ConfirmEmail.Modal";

const StrapperFrom = ({
  event,
  course,
  spiltCourseName,
  registerSeats,
  setRegisterSeats,
  seats,
  onClose,
  setSeats,
  handleClosRegistereModal,
  back
}) => {
  const { straper, setStraper } = useStrapperContext(); // Use the context
  return (
    <>
      {straper === 1 &&
        <EventModal
          event={event}
          course={course}
          spiltCourseName={spiltCourseName}
          registerSeats={registerSeats}
          setRegisterSeats={setRegisterSeats}
          setStraper={setStraper}
        />
      }
      {straper === 2 &&
        <AttendeeInfo
          // seats={registerSeats}
          // event={event}
          // course={course}
          // spiltCourseName={spiltCourseName}
          setStraper={setStraper}
          // handleClosRegistereModal={handleClosRegistereModal}
          // setSeats={setSeats}
          back={back}
        />
      }
      {straper === 3 &&
        <PaymentModal
          seats={seats}
          event={event}
          course={course}
          spiltCourseName={spiltCourseName}
          registerSeats={registerSeats}
          setRegisterSeats={setRegisterSeats}
          setStraper={setStraper}
          onClose={onClose}
        />
      }
      {straper === 4 &&
        <CartModal
          onClose={onClose}
          setStraper={setStraper}
        />
      }
      {straper === 5 &&
        <ConfirmEmail
          onClose={onClose}
          setStraper={setStraper}
        />
      }
    </>
  );
};

export default StrapperFrom;
