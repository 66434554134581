import React from 'react'

const WelcomeHeader = () => {
  return (
    <div className="bg-white p-4 flex items-center justify-center">
      <div className="max-w-4xl mx-auto flex items-center">
        <div className="flex-1">
          <div className="bg-blue-100 p-2 rounded-full w-10 h-10 flex items-center justify-center">
            {/* Placeholder for blue circle */}
          </div>
          <h1 className="text-xl font-bold text-gray-800 my-2">
            About Us
          </h1>
          <div className="bg-pink-100 p-2 rounded w-10 h-10 flex items-center justify-center">
            {/* Placeholder for pink arc shape */}
          </div>
        </div>
        <div className="flex-1">
          <img 
            src="path-to-your-image.jpg" 
            alt="Person holding a heart" 
            className="rounded-full w-32 h-32 object-cover"
          />
        </div>
        <div className="flex-1">
          <div className="bg-blue-100 p-2 rounded-full w-10 h-10 flex items-center justify-center">
            {/* Placeholder for the dotted pattern */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeHeader