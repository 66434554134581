import React, { useState } from "react";
import Modal from "react-modal";
import { useStrapperContext } from '../Strapper/StrapperContext'; // Import the context
import StrapperFrom from '../StraperFrom'; // Ensure correct import path

const Event = ({
  event,
  course,
  spiltCourseName,
  isRegister,
  handleClosRegistereModal,
  stepperValue
}) => {
  const { straper, setStraper } = useStrapperContext(); // Use the context
  const [registerSeats, setRegisterSeats] = useState(1);
  const [seats, setSeats] = useState(0);
  const handleCloseCart = () => setStraper(0);

  
  return (
    <>
      <Modal
        isOpen={isRegister}
        onRequestClose={handleClosRegistereModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            overflowY: 'auto',
            zIndex: '99999'
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '900px',
            height: '700px',
            backgroundColor: '#f8f8f8',
            padding: '0',
            paddingBottom: '10px',
            marginBottom: '10px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }
        }}
        ariaHideApp={false}
      >
        <StrapperFrom
          event={event}
          course={course}
          spiltCourseName={spiltCourseName}
          registerSeats={registerSeats}
          setRegisterSeats={setRegisterSeats}
          onClose={handleClosRegistereModal}
          seats={seats}
          setSeats={setSeats}
          back={true}
        />
      </Modal>
    </>
  );
};

export default Event;
