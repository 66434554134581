import React from "react";
import { Link } from "react-router-dom";

const Connect = () => {
  return (
    <>
      <div className="mb-20">
        <div className="flex justify-between w-[90%] m-auto  lg:ml-auto lg:mr-14 mt-10 lg:my-14">

          <div className="text-[24.38px] md:text-3xl lg:text-5xl leading-[33.31px] lg:leading-[50px] font-extrabold">Campuses</div>
          <div className="flex justify-center items-center gap-3">
            <Link to={`/contact`} className="text-[17px] leading-[60px] font-semibold text-[#B02526]">See All Campuses</Link>
            <div>
              <svg className="bubbling-svg" width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.73242 1.9043L6.00004 7.22249L1.73242 12.5407" stroke="#B02526" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>

          {/* <div className="hidden md:flex justify-center items-center gap-3">
            <div className="lg:text-[17px] text-sm leading-[33.31px] lg:leading-[60px] font-semibold text-[#B02526]">View In Details</div>
            <div>
              <svg  className="bubbling-svg" width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.73242 1.9043L6.00004 7.22249L1.73242 12.5407" stroke="#B02526" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div> */}
        </div>
        <div className="flex">
          <iframe className="w-[95%] m-auto h-[500px]" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=-79.6393%2C43.5807%2C-79.1157%2C43.8554&amp;layer=mapnik&amp;marker=43.6532%2C-79.3832"></iframe>
        </div>
      </div>
    </>
  )
}
export default Connect;
