import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from "../../src/components/common/loader"
import { formatDate } from '../components/common/dateFormatter';
import Layout from '../components/StudentSection/Layout';

const StudentDetails = () => {
  const [studentDetails, setStudentDetails] = useState(null);

  useEffect(() => {
    const StundentID = localStorage.getItem("studentID")? JSON.parse(localStorage.getItem("studentID")):null
    const fetchStudentDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/students/history/${StundentID}`);
        setStudentDetails(response.data);
      } catch (error) {
        console.error("Error fetching student details:", error);
      }
    };

    fetchStudentDetails();
  }, []);

  return (
    <Layout>
      {studentDetails ? (
        <div className="container mx-auto p-4">
          <h1 className="text-3xl text-center font-semibold my-6">Courses Details</h1>
          <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
            <table className="min-w-full table-auto">
              <thead className="bg-[#183864] text-white">
                <tr>
                  <th className="px-4 py-2 border border-white font-semibold text-left">#</th>
                  <th className="px-4 py-2 border border-white font-semibold text-left">Event Name</th>
                  <th className="px-4 py-2 border border-white font-semibold text-left">Course Name</th>
                  <th className="px-4 py-2 border border-white font-semibold text-left">Start Date</th>
                  <th className="px-4 py-2 border border-white font-semibold text-left">End Date</th>
                  <th className="px-4 py-2 border border-white font-semibold text-left">Registration Date</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {studentDetails.history?.length <= 0 ? (
                  <tr>
                    <td colSpan="6" className="text-center text-xl text-gray-500 py-4">
                      No data available
                    </td>
                  </tr>
                ) : (
                  studentDetails.history?.map((historyItem, index) => (
                    <tr key={index} className="hover:bg-gray-100">
                      <td className="px-4 py-2 text-left border">{index + 1}</td>
                      <td className="px-4 py-2 text-left border">{historyItem.eventName}</td>
                      <td className="px-4 py-2 text-left border">{historyItem.courseName}</td>
                      <td className="px-4 py-2 text-left border">
                        {formatDate(historyItem.startDate)}
                      </td>
                      <td className="px-4 py-2 text-left border">
                        {formatDate(historyItem.endDate)}
                      </td>
                      <td className="px-4 py-2 text-left border">
                        {formatDate(historyItem.registrationDate)}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        null// <Loader/>null
      )}
    </Layout>
  );
};

export default StudentDetails;
