import React from 'react'
import verticalDots from '../../assets/verticalDots.svg'
import trainingTopImage from '../../assets/trainingTopImage.png'
import trainingTopLeftImage from '../../assets/trainingTopLeftImage.png'
import trainingHeroVector1 from '../../assets/trainingHeroVector1.svg'
import TrainingHeroTopCircle from '../../assets/TrainingHeroTopCircle.svg'
import Vector from '../../assets/homeBannerVecotr.svg'
import Vector2 from '../../assets/homeBannerVector2.svg'
import TrainingHero from '../../assets/aboutHeroChracter.svg'
import aboutuscircle from '../../assets/aboutuscircle.svg'
import LearningHeroHand from '../../assets/learningHand.png'
import { motion } from 'framer-motion';

const AboutHeroSection = ({data}) => {

  const bubbleSVG1 = (<svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="47" y="47" width="47" height="47" rx="23.5" transform="rotate(180 47 47)" fill="#E9EFFF"/>
  </svg>
  )
  return (
    <div className='relative flex h-[280px] lg:h-[404px] overflow-hidden mb-5 w-full'>
      <div className='absolute left-[54%] top-5 md:top-0'>
        <img className='md:w-[85px] w-[65px]' src={trainingTopImage} alt=''/>
      </div>
      <div className='absolute left-[35px] top-5 md:top-[55%] lg:z-[999]'>
        <img className='md:w-[85px] w-[65px]' src={trainingTopLeftImage} alt=''/>
      </div>
      {/* <div className='absolute w-full left-[-25px] top-5  md:top-[75%]'>
        <img className='w-full' src={trainingHeroVector1} alt=''/>
      </div> */}
      <div className='lg:flex hidden absolute left-[-25px] top-5 md:top-1/4'>
        <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
      </div>
      <div className='lg:flex hidden absolute right-[-25px] top-[10%]'>
        <img src={verticalDots} alt=''/>
      </div>
      {/* <div className='absolute bottom-10'>
        <img src={heroSectionVectorBg} alt=''/>
      </div>
      <div className='absolute bottom-0'>
        <img src={heroSectionVectorBG2} alt=''/>
      </div> */}
      {/* <div className='absolute bottom-0 right-0 md:right-[11%] lg:z-50'>
        <img className='md:w-[320px] md:h-[320px] w-[175px] h-[176px]' src={TrainingHeroTopCircle} alt=''/>
      </div> */}
      <div className='absolute bottom-5 lg:bottom-1 right-0 lg:z-[9999]'>
      <motion.div
        initial={{ x: "-50%" }}
        whileInView={{ x: 0 }}
        transition={{ duration: 0.6, type: 'tween' }}
      >
        <img className='' src={`${process.env.REACT_APP_BASE_URL}/${data?.image}`} alt=''/>
        </motion.div>
      </div>
      <img className="lg:flex hidden absolute bottom-[0px] lg:z-10" src={Vector} alt="" />
      <img className="flex absolute bottom-[-10px]" src={Vector2} alt="" />
      <div className='lg:flex hidden absolute top-1/3 left-1/3'>{bubbleSVG1}</div>
      <div className='flex flex-col justify-center items-center w-2/3 md:w-2/3 lg:w-full'>
        <div className='flex justify-end'>
          <div className='w-[91px] h-[36px] bg-[#E5EDFF] text-[14px] rounded-[39px] leading-[17.64px] font-[700] text-[#183864] flex items-center justify-center'>Online</div>
        </div>
        <div className='text-[#183864] font-semibold text-[40px] md:text-[55px] lg:text-[71px] leading-[43.55px] lg:leading-[71px] text-center'>
          <div>{data?.main_heading}</div>
        </div>
      </div>
    </div>
  )
}

export default AboutHeroSection
