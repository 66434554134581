import React from "react";
import ButtonVector from '../../../assets/buttonVector.svg'
import carasolImage1 from '../../../assets/homePageCarasolImage1.svg'
import carasolImage2 from '../../../assets/homePageCarasolImage2.svg'
import carasolImage3 from '../../../assets/homePageCarasolImage3.svg'
import carasolImage4 from '../../../assets/homePageCarasolImage4.svg'
import Slider from "react-slick";
import {motion} from 'framer-motion'
import { Link, useLocation } from 'react-router-dom'


const Training = ({data}) => {
  const location=useLocation()
  const pathParts = location.pathname.split('/');
  const activePath = pathParts[pathParts.length - 1];

  var carasolSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <div className="w-[90%] mx-auto relative flex flex-col md:mb-20">
        <div className="m-auto mb-5 md:mb-10">
          <div className="flex md:justify-between flex-col md:flex-row">
            <h1 className="font-[800] text-[27.69px] md:text-[48px] leading-[28.85px] md:leading-[50px] text-[#14183E]">{data?.content?.Training?.Heading}</h1>
              <motion.div
                whileHover={{ scale: 1.05 }}
              >

         { activePath !=="all-courses" &&  <Link to={"/all-courses"} className="flex items-center gap-1 text-[#B02526] text-[20px] leading-[60px]">See All Course <img className="" src={ButtonVector} /></Link>}
            </motion.div>
          </div>
          <div className="leading-[30px] text-[14px] md:text-[20px] font-medium text-[#5E6282] md:w-[70%] w-full">
          {data?.content?.Training?.SubHeading}
          </div>
        </div>
        <div className="">
          <Slider {...carasolSettings}>
          {data?.content?.Training?.Images.map((image, index) => (
              <div key={index} className="flex items-center">
                <img src={`${process.env.REACT_APP_BASE_URL}/${image}`} className="h-[93px]" />
              </div>
            ))}
            {/* <div className="flex items-center">
              <img src={carasolImage1} className="h-[93px]"/>
            </div>
            <div className="flex items-center">
              <img src={carasolImage2} className="h-[93px]"/>
            </div>
            <div className="flex items-center">
              <img src={carasolImage3} className="h-[93px]"/>
            </div>
            <div className="flex items-center">
              <img src={carasolImage4} className="h-[93px]"/>
            </div>
            <div className="flex items-center">
              <img src={carasolImage5} className="h-[93px]"/>
            </div> */}
          </Slider>
        </div>
      </div>
    </>
  )
}
export default Training;
