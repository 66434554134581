import React, { useState, useEffect } from 'react';
import RegistrationForm from './RegistrationForm';
import { IoIosArrowDown } from 'react-icons/io';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useCart } from '../Event/CartContext'; // Import the context for the cart
import moment from "moment";


const AttendeeInfo = ({ setStraper, back }) => {
  const { cart } = useCart(); // Use the context to get the cart
  const [activeIndex, setActiveIndex] = useState(null);
  const [allFormData, setAllFormData] = useState({});
  const [billingEmail, setBillingEmail] = useState("");

  // Load existing form data from localStorage if it exists
  useEffect(() => {
    const initialFormData = {};
    cart.forEach(item => {
      const savedFormData = JSON.parse(localStorage.getItem(`attendees_${item.event._id}`));
      if (savedFormData) {
        initialFormData[item.event._id] = savedFormData;
      } else {
        initialFormData[item.event._id] = Array(item.seats).fill({});
      }
    });
    setAllFormData(initialFormData);

    if (cart.length > 0) {
      setActiveIndex(`${cart[0].event._id}_0`);
    }
    
  }, [cart]);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleFormSubmit = (eventId, seatIndex, formData) => {
    if (formData?.email !== formData?.confirmEmail) {
      toast.error("Emails do not match");
      return;
    }

    const updatedFormData = { ...allFormData };

    // Initialize the array for the eventId if it doesn't exist
    if (!Array.isArray(updatedFormData[eventId])) {
      const eventItem = cart.find(item => item.event._id === eventId);
      updatedFormData[eventId] = Array(eventItem.seats).fill({});
    }

    updatedFormData[eventId][seatIndex] = {
      ...formData,
      billingEmail, // Ensure billing email is included
    };
    setAllFormData(updatedFormData);

    // Save the updated form data to localStorage
    localStorage.setItem(`attendees_${eventId}`, JSON.stringify(updatedFormData[eventId]));

    setActiveIndex(null);

    console.log('All Forms Data:', updatedFormData);
  };

  const handlePaymentClick = async () => {
    const allValid = Object.values(allFormData).every(eventData =>
      Array.isArray(eventData) && eventData.every(data => data.email && data.confirmEmail)
    );

    let totalSeats = 0;
    cart.forEach(item => {
      totalSeats += item.seats;
    });

    let totalFormSeats = 0;
    Object.keys(allFormData).forEach(eventId => {
      totalFormSeats += allFormData[eventId].length;
    });

    const checkAllSeats = totalFormSeats !== totalSeats;

    if (!allValid || checkAllSeats) {
      toast.error("Please fill out all the attendee information before proceeding to payment.");
      return;
    }

    if (!billingEmail) {
      toast.error("Please Selecte Billing Email");
      return;
    }

    const enrolledEmails = Object.values(allFormData)
    .flatMap(eventData =>
      Array.isArray(eventData)
        ? eventData.filter(data => data.isEnrolled === true).map(data => data.email)
        : []
    );

    if (enrolledEmails.length > 0) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/event/checkAttendees`, {
          attendees: enrolledEmails
        });
      
        const { details } = response.data;
      
        const successfulEmails = details.filter(item => item.success);
        const failedEmails = details.filter(item => !item.success);
      
        if (successfulEmails.length === enrolledEmails.length) {
          localStorage.setItem("emailsToVerify", JSON.stringify(enrolledEmails));
          setStraper(5);
        } else if (failedEmails.length > 0) {
          const failedEmailsList = failedEmails.map(item => item.email).join(", ");
          toast.error(`The following emails were not found: ${failedEmailsList}`);
        }
      } catch (error) {
        toast.error("There was an error adding the attendees, please try again later.");
        console.error('Error adding the attendees!', error);
      }      
    } else {
      setStraper(3);
    }
  };

  const handleBillingEmailChange = (e) => {
    setBillingEmail(e.target.value);
  };

  useEffect(() => {
    setAllFormData((prevData) => {
      const updatedData = { ...prevData };
      Object.keys(updatedData).forEach((eventId) => {
        const eventData = updatedData[eventId].map((attendee) => ({
          ...attendee,
          billingEmail: billingEmail,
        }));
        // Update local storage for each event
        localStorage.setItem(`attendees_${eventId}`, JSON.stringify(eventData));
        updatedData[eventId] = eventData;
      });
      return updatedData;
    });
  }, [billingEmail]);

  return (
    <div className="relative w-full h-full p-6">
      <div className="mt-2">
        <h2 className="sm:text-2xl text-lg uppercase py-2 font-bold w-full flex justify-center text-slate-600">Register</h2>
        <h2 className="text-xl font-semibold text-slate-600">Attendee Information</h2>
        <p className="mt-2 mb-4 text-slate-600">
          To process your registration, please provide the following information.
          Note that all fields marked with an asterisk (*) are required.
        </p>
        <div>
          {cart.length > 0 &&
            cart.map((item) => (
              <div key={item.event._id}>
                <div className='flex pb-2'>
                  <div>{item?.course?.name}</div>
                  <div>-{item?.event?.campus} - {moment.utc(item.event.startDate).format("MMMM DD, YYYY")}</div>
                </div>
                {Array.from({ length: item.seats }, (_, seatIndex) => (
                  <div key={seatIndex}>
                    <div
                      className={`py-3 px-2 rounded-tl-[6px] rounded-tr-[6px] bg-slate-600 text-[16px] leading-5 font-bold text-[#ffffff] cursor-pointer flex items-center justify-between ${activeIndex === `${item.event._id}_${seatIndex}` ? 'mb-0' : 'mb-1'}`}
                      onClick={() => toggleAccordion(`${item.event._id}_${seatIndex}`)}
                    >
                      {`ATTENDEE ${seatIndex + 1}`}
                      <IoIosArrowDown className={`ml-2 transform ${activeIndex === `${item?.event?._id}_${seatIndex}` ? 'rotate-180' : ''}`} />
                    </div>
                    {activeIndex === `${item?.event?._id}_${seatIndex}` && (
                      <div className="bg-[#E9EFFF]">
                        <RegistrationForm
                          onSubmit={(formData) => handleFormSubmit(item?.event?._id, seatIndex, formData)}
                          // initialValues={allFormData[item?.event?._id]?.[seatIndex] || {},}
                          initialValues={{
                            ...allFormData[item?.event?._id]?.[seatIndex],
                            billingEmail, // Ensure the billing email is synced across forms
                          }}
                          setBillingEmail={setBillingEmail}
                          billingEmail={billingEmail}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
            <div>
              <label
                htmlFor="billingEmail"
                className="block text-sm font-medium text-gray-700"
              >
                Enter Payer's email address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={billingEmail}
                onChange={handleBillingEmailChange}
                required
              />
            </div>
        </div>
        <div className="flex justify-between my-5 py-4">
          {back &&
            <button
              type="button"
              className="w-max flex justify-center py-2 px-4 border border-transparent rounded-md shadow-md text-medium font-medium text-black hover:text-white hover:bg-[#931d1d] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => setStraper(1)}
            >
              Back
            </button>
          }
          <div className='flex items-center gap-2'>
            <button
              type="button"
              className="w-max flex justify-center py-2 px-4 border border-transparent rounded-md shadow-md text-medium font-medium text-black hover:text-white hover:bg-[#931d1d] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => setStraper(4)}
            >
              View Events Cart
            </button>
            <button
              type="button"
              className="w-max flex justify-center py-2 px-4 border border-transparent rounded-md shadow-md text-medium font-medium text-green-600 hover:text-white hover:bg-[#931d1d] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handlePaymentClick}
            >
              Proceed to Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendeeInfo;
