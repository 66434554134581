import React, {useState, useEffect} from "react";
import Layout from '../components/Layout/Layout';
import backgroundCover from '../assets/learningBackground.png';
import Mask from '../assets/faqMask.png';
import LearningHeroSection from '../components/HeroSection/LearningHeroSection';
import {motion} from 'framer-motion'
import axios from "axios";

const Learning = () => {
  const [data, setData] = useState([]);
  const getData = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/elearning`)
    .then(response => {
      setData(response.data)
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }
  useEffect(() => {
    getData();
  }, [])

  console.log(data[0])

  const backgroundStyle = {
    backgroundImage: `url(${backgroundCover})`,
    backgroundSize: 'cover',
  };
  const dropShadowFilter = 'drop-shadow(15px -10px 0px #E5EDFF)';
  return (
    <Layout>
      <div className='overflow-hidden'>
        <div className=''>
          <LearningHeroSection data={data[0]}/>
        </div>
        <div className="p-5 lg:p-10 flex items-center justify-center relative" style={backgroundStyle}>
          <div className="lg:flex hidden absolute top-3 left-3">
            <img src={Mask} alt="Mask" />
          </div>
          <div className="lg:flex hidden absolute bottom-0 right-0">
            <img src={Mask} alt="Mask" />
          </div>
          <div className="bg-white p-3 lg:p-20 flex flex-col lg:flex-row justify-between rounded-[10px]" style={{ filter: dropShadowFilter }}>
            <div className="lg:w-[442px] flex lg:flex-col justify-between">
              <div className="leading-[30px] lg:leading-[72px] text-[20px] lg:text-[48px] font-[600] text-transparent bg-gradient-to-r from-blue-figma via-red-figma to-red-figma bg-clip-text">
                {data[0]?.banner_heading}
              </div>
              <div className="w-full flex justify-end lg:hidden">
                <div className="bg-[#B02526] w-[64px] h-[29px] lg:w-[162px] lg:h-[53px] rounded-[10px]">
                  <div className="font-bold text-[13.13px] lg:text-[24px] leading-[28px] text-white flex items-center justify-center h-full">{data[0]?.button_text}</div>
                </div>
              </div>
            </div>
            <div className="lg:w-[438px] flex flex-col justify-between">
              <div className="font-[600] text-[12px] lg:text-[20px] leading-[18px] lg:leading-[30px] lg:text-end">{data[0]?.banner_sub_heading}</div>
              <div className="w-full lg:flex justify-end hidden">
                <motion.div whileHover={{scale: 1.05}} className="bg-[#B02526] hover:bg-blue-500 w-[162px] h-[53px] rounded-[10px]">
                  <div className="font-bold text-[13.13px] lg:text-[24px] leading-[28px] text-white flex items-center justify-center h-full">{data[0]?.button_text}</div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Learning;
