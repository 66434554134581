import React,{ useState, useEffect} from 'react'
import RbuImg1 from '../../assets/RbuImg1.png'
import Layout from '../Layout/Layout'
import verticalDots from '../../assets/verticalDots.svg'
import RbuImg3 from '../../assets/RbuImg3.png'
import RbuImg5 from '../../assets/RbuImg5.png'
import axios from 'axios';

const BlogInside = () => {
  const [blog, setBlog] = useState({});
  useEffect(() => {
    const getQueryParamId = () => {
      const queryParams = new URLSearchParams(window.location.search);
      return queryParams.get('id');
    };
    const getAllBlogs = () => {
      const id = getQueryParamId();
      console.log("${process.env.REACT_PUBLIC_BASE_URL}", process.env.REACT_APP_BASE_URL)
      axios.get(`${process.env.REACT_APP_BASE_URL}/blogs/${id}`)
      .then(response => {
        console.log("Bloggggggggggg", response?.data?.data);
        setBlog(response?.data?.data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
    getAllBlogs()
  }, [])
  console.log("blogsblogsblogsblogs", blog)
  
  const bubbleSVG1 = (<svg width="165" height="165" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="165" y="165" width="165" height="165" rx="82.5" transform="rotate(180 165 165)" fill="#E9EFFF"/>
  </svg>
  )

  const bubbleSVG2 = (<svg width="58" height="83" viewBox="0 0 58 83" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="83" height="83" rx="41.5" transform="matrix(1 0 0 -1 0 83)" fill="#E9EFFF"/>
  </svg>
  )

  const bubbleSVG3 = (<svg width="83" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="83" y="83" width="83" height="83" rx="41.5" transform="rotate(180 83 83)" fill="#E9EFFF"/>
  </svg>  
  )

  const bubbleSVG4 = (<svg width="348" height="339" viewBox="0 0 348 339" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="348" height="339" rx="169.5" fill="#E9EFFF"/>
  </svg>
  )

  const bubbleSVG5 = (<svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="113" y="113" width="113" height="113" rx="56.5" transform="rotate(180 113 113)" fill="#E9EFFF"/>
  </svg>
  )

  const bubbleSVG6 = (<svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="47" y="47" width="47" height="47" rx="23.5" transform="rotate(180 47 47)" fill="#E9EFFF"/>
  </svg>
  )

  const bubbleSVG7 = (<svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="97" y="97" width="97" height="97" rx="48.5" transform="rotate(180 97 97)" fill="#E9EFFF"/>
  </svg>
  )

  const cardsData = [
    {
      img: RbuImg1,
      title: "A Comprehensive Guide to Building Your First Aid Kit For Home",
      date: "September 23, 2023",
      comments: "No Comments",
      content: "Every second matters during times of emergency. It is therefore essential to have a well-stocked first aid kit at home. A first aid kit provides"
    },
    {
      img: RbuImg3,
      title: "A Comprehensive Guide to Building Your First Aid Kit For Home",
      date: "September 23, 2023",
      comments: "No Comments",
      content: "Every second matters during times of emergency. It is therefore essential to have a well-stocked first aid kit at home. A first aid kit provides"
    },
    {
      img: RbuImg5,
      title: "A Comprehensive Guide to Building Your First Aid Kit For Home",
      date: "September 23, 2023",
      comments: "No Comments",
      content: "Every second matters during times of emergency. It is therefore essential to have a well-stocked first aid kit at home. A first aid kit provides"
    }
  ];
  
  const Card = ({ img, title, date, comments, content }) => (
    <div className="w-[300px] h-[475px] mx-auto bg-[#FFFFFF] rounded-[13.34px] shadow-xl">
      <img className="object-cover w-[262.43px] h-[262.43px] mx-auto rounded-[6.67px]" src={img} alt="childrens" />
      <div className="px-[20px] mt-[25px]">
        <h2 className="font-bold text-[12.01px] leading-[24.02px] text-[#183864]">{title}</h2>
        <span className="text-[8.01px] leading-[10.41px] text-[#ADADAD]">{date} /// {comments}</span>
        <p className="my-[5px] text-[9.34px] leading-[14.01px] text-[#777777]">{content}</p>
        <span className="text-[8.01px] leading-[16.01px] font-semibold text-[#B02526]">Read More »</span>
      </div>
    </div>
  );
  
  const CardList = () => (
    // <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full max-w-[880px]">
    <div className="flex flex-wrap justify-around w-[90%] m-auto">
        {cardsData?.map((card, index) => (
          <div className="">
            <Card key={index} {...card} />
          </div>
        ))}
    </div>
  );

  return (
    <Layout>
      <div className='overflow-hidden'>
        <section className='relative'>
          <img
            className="md:mt-[15px] mt-[5px] mx-auto object-cover w-[80%] max-w-[880px] md:h-[330px] h-[133.8px] md:left-[269px] left-[37px] md:top-[37px] top-[28.1px] md:rounded-[18px] rounded-[7.3px]"
            src={`${process.env.REACT_APP_BASE_URL}/${blog?.image}`}
            alt=""
          />

          <div className="absolute left-0 top-[15%] -z-30">
            <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
          </div>

          <div className="lg:flex hidden absolute right-[-15px] top-0 -z-30">
            <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
          </div>
        </section>
      </div>

      <section className="relative md:mt-[35px] mt-[17px] ">
        <div className="w-[80%] mx-auto max-w-[880px]">
          <div className="w-[85%]">
            <h2 className="fond-bold md:text-[33.8px] text-[15px] md:leading-[67.6px] leading-[27.32px] text-[#183864] ">
              {blog?.title}
            </h2>
            <span className="mt-[30px] font-normal md:text-[22.53px] text-[10px] md:leading-[29.29px] leading-[15px] text-[#ADADAD]">
              {blog?.date}
            </span>
          </div>
          <p className="font-normal md:text-[26.29px] text-[12px] md:leading-[39.43px] leading-[16.39px] text-[#777777] md:my-[30px] my-[15px]">
            {blog?.desc}
          </p>
        </div>

        <div className="absolute left-2 top-1/2 -z-30">
          <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
        </div>

        <div className="lg:flex hidden absolute right-0 bottom-[-25%] -z-30">
          <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
        </div>
      </section>


      <section className="relative">
        <div className="w-[80%] mx-auto max-w-[880px]">
          {blog?.sections?.length > 0 && blog?.sections?.map((item, index) => (
            <>
              <h2 className="mt-5 font-bold md:text-[33.8px] text-[20px] md:leading-[67.6px] leading-[30px] text-[#183864]">
                {item.title}
              </h2>
              <p className="mt-[10px] md:mb-[30px] mb-[15px] text-[#777777] md:text-[26.29px] text-[14px] md:leading-[40px] leading-[25px]">
                {item.desc}
              </p>
              <ol className="text-[#777777] md:text-[26.29px] text-[14px] md:leading-[40px] leading-[25px]">
                {item?.subSections?.map((sub, index) => (
                  <li key={index}>
                    <span className="font-bold text-[#183864]">{index + 1} {sub.key}:</span> {sub.value}
                  </li>
                ))}
              </ol>
            </>
          )
          )}
        </div>

        <div className="lg:flex hidden absolute left-[43%] top-1/2 -z-30">
          {bubbleSVG1}
        </div>

        <div className="lg:flex hidden absolute right-0 top-1/2 -z-30">
          {bubbleSVG2}
        </div>
      </section>

      <div className='overflow-hidden relative'>
        <section className="w-[80%] mx-auto max-w-[880px] md:mb-[40px] mb-[20px]">
          <h2 className="md:text-[33.8px] text-[20px] font-bold md:leading-[67.6px] leading-[30px] text-[#183864] md:my-[25px] my-[12.5]">
            Conclusion
          </h2>
          <p className="md:text-[26.29px] text-[14px] md:leading-[39.43px] leading-[21px] text-[#777777]">
            {blog?.conclusion}
          </p>
        </section>
        <div className="lg:flex hidden absolute right-[-15px] top-0 -z-30">
          <img className='md:w-[85px] w-[65px]' src={verticalDots} alt=''/>
        </div>
      </div>

      <div className='overflow-hidden pb-20 pt-16 relative'>
        <section className='w-[80%] mx-auto relative'>
          <div className='flex justify-center items-center mb-10 text-[#183864] lg:text-[32px] md:text-[24px] text-lg font-extrabold lg:leading-[32px] md:leading-[24px] leading-lg'>Recommended For
            <span className='text-[#B02526]'>You</span>
          </div>
          <CardList />
          
          <div className='lg:flex hidden absolute left-[15%] top-[-10%]'>{bubbleSVG7}</div>
        </section>
        <div className="lg:flex hidden absolute right-0 top-1/2 -z-30">
          {bubbleSVG2}
        </div>
      </div>
      
    </Layout>
  );
}

export default BlogInside
