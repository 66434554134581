import React, { useState } from "react";
import largeEllipse from '../../../assets/large-ellipse.svg';
import smallEllipse from '../../../assets/small-ellipse.svg';
import vectorMan from '../../../assets/vector-man.svg';
import DotRectangle from '../../../assets/dots-rectangle.svg'
import DotRectangle2 from '../../../assets/dots-rectangle2.svg'
import Vector from '../../../assets/homeBannerVecotr.svg'
import Vector2 from '../../../assets/homeBannerVector2.svg'
import PlayButton from "../../../assets/playButton.svg"
import icon4 from "../../../assets/proSafeIcon4.png"
import { motion } from 'framer-motion';
import ExcitingNewsModal from './ExcitingNewsModal';
import { useNavigate } from "react-router-dom";

const HomeBanner = ({data}) => {      //Compoent bna dya hn unha use kr li
  const [isModalOpen, setModalOpen] = useState(false);
const navigate=useNavigate()
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  }

  return (
    <>
      <div className="relative w-[100%] h-[750px] md:h-[1200px] xl:h-[52.25rem] xl:min-h-[52.25rem]">
        <div>
          <img className="absolute left-1" src={DotRectangle} alt="" />
        </div>
        <div className="container flex flex-col-reverse xl:flex-row xl:h-[39.25rem] mx-auto">
          <div className=" relative w-full xl:w-[50%] flex flex-col h-[39.25rem] xl:h-full justify-evenly px-4 pt-5 md:pt-14 ">
            <div>
              <motion.div
                whileHover={{ scale: 1.05 }}
              >
              <button onClick={toggleModal}className="w-[9.7rem] h-[2.25rem] rounded-[39px] bg-[#E5EDFF]"><div className="inline-block mr-2 w-2 h-2 rounded-full bg-[#183864]"></div> EXCITING NEWS</button>
              </motion.div>
            </div>
            <div className="inline">
              <h1 className="text-[42.61px] leading-[53.68px] md:text-[4.5rem] inline md:leading-[5.5rem] text-[#183864] font-extrabold">{data.first_heading}, <span className="text-[#B02526]"> {data.middle_heading},</span> {data.last_heading}. <img className="inline w-[45.97px] h-[44.85px] md:w-[82px] md:h-[80px] z-10 " src={icon4} alt="" /></h1>
            </div>
            <div><p className="font-medium text-[11.21px] md:text-xl leading-[16.82px] md:leading-[30px] text-[#5E6282]">{data.tag_line}</p></div>
            <div className="flex mt-8 ">
            <button onClick={()=>{navigate("courses")}} className="cursor-pointer font-bold text-[11.21px] md:text-xl leading-[16.82px] md:leading-8 rounded-lg text-white bg-red-600 w-[111px] h-[33.64px] md:w-40 md:h-16 mr-4 transition-colors duration-300 hover:bg-blue-500 shadow-custom transform hover:scale-110 transition-transform duration-300" style={{ boxShadow: '0px 20px 35px 0px rgba(68, 117, 242, 0.22)' }}>
              {data.main_button}
            </button>
            <button className="flex items-start md:items-center font-bold text-[1.1rem] leading-5 h-[4rem] w-[9rem]">
              <img className="text-center self-end h-[50px] w-[50px] object-contain md:flex hidden" src={PlayButton} alt=""/>
              <div className="flex md:hidden items-center">
                <svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_1_15281)">
                  <circle cx="25.9995" cy="17.3472" r="14.0152" fill="white"/>
                  <circle cx="25.9995" cy="17.3472" r="13.7348" stroke="#183864" strokeWidth="0.560606"/>
                  </g>
                  <path d="M29.9238 17.4647L23.8596 20.9659L23.8596 13.9635L29.9238 17.4647Z" fill="#183864"/>
                  <defs>
                  <filter id="filter0_d_1_15281" x="0.772254" y="0.529001" width="50.4555" height="50.4545" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="8.40909"/>
                  <feGaussianBlur stdDeviation="5.60606"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.219608 0 0 0 0 0.392157 0 0 0 0.2 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_15281"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_15281" result="shape"/>
                  </filter>
                  </defs>
                </svg>
              </div>
              <div className="flex mt-1">
                {data.secondary_button}
              </div>
            </button>
            </div>
          </div>
          <div className="relative w-full h-[400px] md:h-[39.25rem] xl:h-full xl:w-[50%]">
            <img className="absolute left-[15%] top-[18%] md:w-[513px] md:h-[513px] w-[290.54px] h-[290.54px]" src={largeEllipse} alt="" />
            <img className="md:flex hidden absolute left-[16%] top-[15%] " src={smallEllipse} alt="" />
            <img className="absolute left-[20%] bottom-[10%] md:bottom-0 md:w-[448.64px] md:h-[623px] w-[254.09px] h-[352.83px]" src={`${process.env.REACT_APP_BASE_URL}/${data.main_image}`} alt="" />
            <img className="absolute right-[12%] top-[10%] lg:z-10 md:w-[148.06px] md:h-[146.78px] w-[83.85px] h-[83.13px]" src={icon4} alt="" />
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.6 }}
              className="absolute bottom-[20%] left-10 xl:left-[0] xl:bottom-[10%] md:w-[182px] md:h-[114px] w-[103.08px] h-[64.56px]"
            >
              <img src={`${process.env.REACT_APP_BASE_URL}/${data.image1}`} alt="" />
            </motion.div>

            {/* <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.7, delay: 0.7 }}
              className="absolute bottom-[10%] right-[35%] md:bottom-[10%] md:right-[5%] lg:bottom-[10%] lg:right-[25%] xl:bottom-[0%] xl:right-[0%] md:w-[120px] w-[67.96px] md:h-[131px] h-[74.19px]"
            >
              <img src={`${process.env.REACT_APP_BASE_URL}/${data.image2}`} alt="" />
            </motion.div> */}

            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.7, delay: 0.7 }}
              className="absolute bottom-[10%] right-[22%] md:bottom-[10%] md:right-[20%] lg:bottom-[10%] lg:right-[35%] xl:bottom-[0%] xl:right-[20%] md:w-[120px] w-[67.96px] md:h-[131px] h-[74.19px]"
            >
              <img src={`${process.env.REACT_APP_BASE_URL}/${data.image3}`} alt="" />
            </motion.div>
          </div>
        </div>
        <img className="absolute bottom-[0px] z-10" src={Vector} alt="" />
        <img className="absolute bottom-[0px]" src={Vector2} alt="" />
        <div><img className="absolute right-0 bottom-0 z-20" src={DotRectangle2} alt="" /></div>
      </div>
      <ExcitingNewsModal
          isOpen={isModalOpen}
          onRequestClose={toggleModal}
        />
    </>
  )
}
export default HomeBanner;
