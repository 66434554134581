import React, { useEffect, useState } from "react";
import Cources from "../components/Homepage/Courses";
import TrainingPartner from "../components/Homepage/Training";
import FAQ from "../components/Homepage/FAQ";
import Layout from '../components/Layout/Layout';
import Testimonials from "../components/testimonials/Testimonials";
import HeroSection from "../components/HeroSection/HeroSection";
import GeneralHeroSection from "../components/HeroSection/GeneralHeroSection";
import TrainingHero from '../assets/TrainingHero.png'
import axios from "axios";


const Courses = () => {

  const [HomeData, setHomeData] = useState();

  useEffect(() => {
    const getHomeDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/home`);
        setHomeData(response?.data.data[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    getHomeDetails();
  }, []);

  return (
    <Layout>
      {/* <HeroSection /> */}
      <GeneralHeroSection image={TrainingHero} heading="All Courses" imageStyle="object-cover"/>
      <Cources />
      <TrainingPartner data={HomeData}/>
      <FAQ />
      <Testimonials />

    </Layout>
  )
}
export default Courses;
