import React, { useState, useEffect} from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import "./Testimonials.css"
import horizontalDots from '../../assets/horizontalDots.svg'
import verticalDots from '../../assets/verticalDots.svg'
import TestimonialImage1 from '../../assets/TestimonialImage1.svg'
import TestimonialImage2 from '../../assets/TestimonialImage2.svg'
import TestimonialImage3 from '../../assets/TestimonialImage3.svg'
import axios from 'axios';

const   Testimonials = () => {
  const [testimonialsData, setTestimonialsData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/testimonial`);
        console.log(response.data);
        setTestimonialsData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getData();
  }, []);
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    focusOnSelect: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    beforeChange: (current, next) => setActiveSlide(next),
  };

  // Define your slides content here
  const slidesContent = [
    {
      imageLink: TestimonialImage1,
      comment: 'I joined the Standard First Aid course and Foodsafe level 1 as well. They were all amazing~ This is a good place for learning courses like these, they have good facilities and helpful and knowledgeable instructors. I hope to do more courses here in the future.',
      starsSVG: <svg width="132" height="23" viewBox="0 0 132 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_225_8024)">
        <path d="M21.0969 8.41866C21.0392 8.24885 20.9331 8.09964 20.7916 7.98945C20.6501 7.87926 20.4795 7.81292 20.3007 7.79861L15.0716 7.3831L12.8087 2.37409C12.7367 2.21276 12.6195 2.07573 12.4712 1.97955C12.323 1.88336 12.1501 1.83213 11.9734 1.83203C11.7968 1.83193 11.6238 1.88298 11.4755 1.979C11.3272 2.07502 11.2098 2.21192 11.1375 2.37317L8.87473 7.3831L3.64558 7.79861C3.46989 7.81253 3.30193 7.87677 3.16179 7.98364C3.02165 8.09052 2.91527 8.23551 2.85537 8.40126C2.79548 8.56701 2.7846 8.74651 2.82405 8.91828C2.8635 9.09005 2.9516 9.24682 3.07781 9.36983L6.94212 13.1369L5.57544 19.0549C5.53394 19.234 5.54724 19.4215 5.61361 19.593C5.67999 19.7645 5.79637 19.9121 5.94765 20.0166C6.09894 20.1211 6.27815 20.1777 6.46202 20.1791C6.6459 20.1805 6.82595 20.1266 6.97881 20.0244L11.9731 16.6949L16.9675 20.0244C17.1237 20.1282 17.308 20.1816 17.4955 20.1774C17.683 20.1733 17.8647 20.1119 18.0162 20.0014C18.1678 19.8909 18.2819 19.7367 18.3431 19.5595C18.4044 19.3822 18.4099 19.1904 18.3589 19.01L16.6813 13.1397L20.8419 9.39551C21.1143 9.1497 21.2143 8.76629 21.0969 8.41866Z" fill="#E77C40"/>
        <path d="M48.1086 8.41866C48.0509 8.24885 47.9448 8.09964 47.8033 7.98945C47.6618 7.87926 47.4912 7.81292 47.3124 7.79861L42.0833 7.3831L39.8205 2.37409C39.7484 2.21276 39.6312 2.07573 39.483 1.97955C39.3347 1.88336 39.1619 1.83213 38.9852 1.83203C38.8085 1.83193 38.6355 1.88298 38.4872 1.979C38.3389 2.07502 38.2215 2.21192 38.1493 2.37317L35.8864 7.3831L30.6573 7.79861C30.4816 7.81253 30.3136 7.87677 30.1735 7.98364C30.0334 8.09052 29.927 8.23551 29.8671 8.40126C29.8072 8.56701 29.7963 8.74651 29.8358 8.91828C29.8752 9.09005 29.9633 9.24682 30.0895 9.36983L33.9538 13.1369L32.5872 19.0549C32.5457 19.234 32.559 19.4215 32.6253 19.593C32.6917 19.7645 32.8081 19.9121 32.9594 20.0166C33.1107 20.1211 33.2899 20.1777 33.4737 20.1791C33.6576 20.1805 33.8377 20.1266 33.9905 20.0244L38.9849 16.6949L43.9792 20.0244C44.1354 20.1282 44.3197 20.1816 44.5072 20.1774C44.6947 20.1733 44.8764 20.1119 45.028 20.0014C45.1795 19.8909 45.2936 19.7367 45.3548 19.5595C45.4161 19.3822 45.4216 19.1904 45.3706 19.01L43.693 13.1397L47.8536 9.39551C48.126 9.1497 48.226 8.76629 48.1086 8.41866Z" fill="#E77C40"/>
        <path d="M75.1203 8.41866C75.0627 8.24885 74.9565 8.09964 74.8151 7.98945C74.6736 7.87926 74.5029 7.81292 74.3242 7.79861L69.095 7.3831L66.8322 2.37409C66.7601 2.21276 66.6429 2.07573 66.4947 1.97955C66.3465 1.88336 66.1736 1.83213 65.9969 1.83203C65.8202 1.83193 65.6472 1.88298 65.4989 1.979C65.3506 2.07502 65.2332 2.21192 65.161 2.37317L62.8982 7.3831L57.669 7.79861C57.4933 7.81253 57.3254 7.87677 57.1852 7.98364C57.0451 8.09052 56.9387 8.23551 56.8788 8.40126C56.8189 8.56701 56.808 8.74651 56.8475 8.91828C56.8869 9.09005 56.975 9.24682 57.1012 9.36983L60.9656 13.1369L59.5989 19.0549C59.5574 19.234 59.5707 19.4215 59.6371 19.593C59.7034 19.7645 59.8198 19.9121 59.9711 20.0166C60.1224 20.1211 60.3016 20.1777 60.4855 20.1791C60.6693 20.1805 60.8494 20.1266 61.0022 20.0244L65.9966 16.6949L70.9909 20.0244C71.1472 20.1282 71.3314 20.1816 71.5189 20.1774C71.7064 20.1733 71.8881 20.1119 72.0397 20.0014C72.1912 19.8909 72.3053 19.7367 72.3666 19.5595C72.4278 19.3822 72.4333 19.1904 72.3824 19.01L70.7047 13.1397L74.8653 9.39551C75.1377 9.1497 75.2377 8.76629 75.1203 8.41866Z" fill="#E77C40"/>
        <path d="M102.132 8.41866C102.074 8.24885 101.968 8.09964 101.827 7.98945C101.685 7.87926 101.515 7.81292 101.336 7.79861L96.1067 7.3831L93.8439 2.37409C93.7718 2.21276 93.6546 2.07573 93.5064 1.97955C93.3582 1.88336 93.1853 1.83213 93.0086 1.83203C92.8319 1.83193 92.659 1.88298 92.5106 1.979C92.3623 2.07502 92.2449 2.21192 92.1727 2.37317L89.9099 7.3831L84.6807 7.79861C84.505 7.81253 84.3371 7.87677 84.1969 7.98364C84.0568 8.09052 83.9504 8.23551 83.8905 8.40126C83.8306 8.56701 83.8198 8.74651 83.8592 8.91828C83.8987 9.09005 83.9868 9.24682 84.113 9.36983L87.9773 13.1369L86.6106 19.0549C86.5691 19.234 86.5824 19.4215 86.6488 19.593C86.7151 19.7645 86.8315 19.9121 86.9828 20.0166C87.1341 20.1211 87.3133 20.1777 87.4972 20.1791C87.6811 20.1805 87.8611 20.1266 88.014 20.0244L93.0083 16.6949L98.0026 20.0244C98.1589 20.1282 98.3431 20.1816 98.5306 20.1774C98.7181 20.1733 98.8999 20.1119 99.0514 20.0014C99.2029 19.8909 99.317 19.7367 99.3783 19.5595C99.4395 19.3822 99.4451 19.1904 99.3941 19.01L97.7165 13.1397L101.877 9.39551C102.149 9.1497 102.249 8.76629 102.132 8.41866Z" fill="#E77C40"/>
        <path d="M129.148 8.41866C129.09 8.24885 128.984 8.09964 128.842 7.98945C128.701 7.87926 128.53 7.81292 128.351 7.79861L123.122 7.3831L120.86 2.37409C120.787 2.21276 120.67 2.07573 120.522 1.97955C120.374 1.88336 120.201 1.83213 120.024 1.83203C119.848 1.83193 119.675 1.88298 119.526 1.979C119.378 2.07502 119.261 2.21192 119.188 2.37317L116.926 7.3831L111.696 7.79861C111.521 7.81253 111.353 7.87677 111.213 7.98364C111.072 8.09052 110.966 8.23551 110.906 8.40126C110.846 8.56701 110.835 8.74651 110.875 8.91828C110.914 9.09005 111.002 9.24682 111.129 9.36983L114.993 13.1369L113.626 19.0549C113.585 19.234 113.598 19.4215 113.664 19.593C113.731 19.7645 113.847 19.9121 113.998 20.0166C114.15 20.1211 114.329 20.1777 114.513 20.1791C114.697 20.1805 114.877 20.1266 115.03 20.0244L120.024 16.6949L125.018 20.0244C125.175 20.1282 125.359 20.1816 125.546 20.1774C125.734 20.1733 125.915 20.1119 126.067 20.0014C126.219 19.8909 126.333 19.7367 126.394 19.5595C126.455 19.3822 126.461 19.1904 126.41 19.01L124.732 13.1397L128.893 9.39551C129.165 9.1497 129.265 8.76629 129.148 8.41866Z" fill="#E77C40"/>
        </g>
        <defs>
        <clipPath id="clip0_225_8024">
        <rect width="130.068" height="22.0136" fill="white" transform="translate(0.964844)"/>
        </clipPath>
        </defs>
        </svg>,
      name: 'Sandhika Galih',
    },
    {
      imageLink: TestimonialImage2,
      comment: 'I have had a great experience getting my certification for myself and my staff with ProSafe. Last minute Colin set up a course for us. The instructor Kamal was mazing!!! She used a variety of teaching techniques to keep the class engaged and encourage learning. Overall the experience of working with ProSafe was seamless. Thank you.”',
      starsSVG: <svg width="132" height="23" viewBox="0 0 132 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_225_8058)">
      <path d="M21.0969 8.41866C21.0392 8.24885 20.9331 8.09964 20.7916 7.98945C20.6501 7.87926 20.4795 7.81292 20.3007 7.79861L15.0716 7.3831L12.8087 2.37409C12.7367 2.21276 12.6195 2.07573 12.4712 1.97955C12.323 1.88336 12.1501 1.83213 11.9734 1.83203C11.7968 1.83193 11.6238 1.88298 11.4755 1.979C11.3272 2.07502 11.2098 2.21192 11.1375 2.37317L8.87473 7.3831L3.64558 7.79861C3.46989 7.81253 3.30193 7.87677 3.16179 7.98364C3.02165 8.09052 2.91527 8.23551 2.85537 8.40126C2.79548 8.56701 2.7846 8.74651 2.82405 8.91828C2.8635 9.09005 2.9516 9.24682 3.07781 9.36983L6.94212 13.1369L5.57544 19.0549C5.53394 19.234 5.54724 19.4215 5.61361 19.593C5.67999 19.7645 5.79637 19.9121 5.94765 20.0166C6.09894 20.1211 6.27815 20.1777 6.46202 20.1791C6.6459 20.1805 6.82595 20.1266 6.97881 20.0244L11.9731 16.6949L16.9675 20.0244C17.1237 20.1282 17.308 20.1816 17.4955 20.1774C17.683 20.1733 17.8647 20.1119 18.0162 20.0014C18.1678 19.8909 18.2819 19.7367 18.3431 19.5595C18.4044 19.3822 18.4099 19.1904 18.3589 19.01L16.6813 13.1397L20.8419 9.39551C21.1143 9.1497 21.2143 8.76629 21.0969 8.41866Z" fill="#E77C40"/>
      <path d="M48.1086 8.41866C48.0509 8.24885 47.9448 8.09964 47.8033 7.98945C47.6618 7.87926 47.4912 7.81292 47.3124 7.79861L42.0833 7.3831L39.8205 2.37409C39.7484 2.21276 39.6312 2.07573 39.483 1.97955C39.3347 1.88336 39.1619 1.83213 38.9852 1.83203C38.8085 1.83193 38.6355 1.88298 38.4872 1.979C38.3389 2.07502 38.2215 2.21192 38.1493 2.37317L35.8864 7.3831L30.6573 7.79861C30.4816 7.81253 30.3136 7.87677 30.1735 7.98364C30.0334 8.09052 29.927 8.23551 29.8671 8.40126C29.8072 8.56701 29.7963 8.74651 29.8358 8.91828C29.8752 9.09005 29.9633 9.24682 30.0895 9.36983L33.9538 13.1369L32.5872 19.0549C32.5457 19.234 32.559 19.4215 32.6253 19.593C32.6917 19.7645 32.8081 19.9121 32.9594 20.0166C33.1107 20.1211 33.2899 20.1777 33.4737 20.1791C33.6576 20.1805 33.8377 20.1266 33.9905 20.0244L38.9849 16.6949L43.9792 20.0244C44.1354 20.1282 44.3197 20.1816 44.5072 20.1774C44.6947 20.1733 44.8764 20.1119 45.028 20.0014C45.1795 19.8909 45.2936 19.7367 45.3548 19.5595C45.4161 19.3822 45.4216 19.1904 45.3706 19.01L43.693 13.1397L47.8536 9.39551C48.126 9.1497 48.226 8.76629 48.1086 8.41866Z" fill="#E77C40"/>
      <path d="M75.1203 8.41866C75.0627 8.24885 74.9565 8.09964 74.8151 7.98945C74.6736 7.87926 74.5029 7.81292 74.3242 7.79861L69.095 7.3831L66.8322 2.37409C66.7601 2.21276 66.6429 2.07573 66.4947 1.97955C66.3465 1.88336 66.1736 1.83213 65.9969 1.83203C65.8202 1.83193 65.6472 1.88298 65.4989 1.979C65.3506 2.07502 65.2332 2.21192 65.161 2.37317L62.8982 7.3831L57.669 7.79861C57.4933 7.81253 57.3254 7.87677 57.1852 7.98364C57.0451 8.09052 56.9387 8.23551 56.8788 8.40126C56.8189 8.56701 56.808 8.74651 56.8475 8.91828C56.8869 9.09005 56.975 9.24682 57.1012 9.36983L60.9656 13.1369L59.5989 19.0549C59.5574 19.234 59.5707 19.4215 59.6371 19.593C59.7034 19.7645 59.8198 19.9121 59.9711 20.0166C60.1224 20.1211 60.3016 20.1777 60.4855 20.1791C60.6693 20.1805 60.8494 20.1266 61.0022 20.0244L65.9966 16.6949L70.9909 20.0244C71.1472 20.1282 71.3314 20.1816 71.5189 20.1774C71.7064 20.1733 71.8881 20.1119 72.0397 20.0014C72.1912 19.8909 72.3053 19.7367 72.3666 19.5595C72.4278 19.3822 72.4333 19.1904 72.3824 19.01L70.7047 13.1397L74.8653 9.39551C75.1377 9.1497 75.2377 8.76629 75.1203 8.41866Z" fill="#E77C40"/>
      <path d="M102.132 8.41866C102.074 8.24885 101.968 8.09964 101.827 7.98945C101.685 7.87926 101.515 7.81292 101.336 7.79861L96.1067 7.3831L93.8439 2.37409C93.7718 2.21276 93.6546 2.07573 93.5064 1.97955C93.3582 1.88336 93.1853 1.83213 93.0086 1.83203C92.8319 1.83193 92.659 1.88298 92.5106 1.979C92.3623 2.07502 92.2449 2.21192 92.1727 2.37317L89.9099 7.3831L84.6807 7.79861C84.505 7.81253 84.3371 7.87677 84.1969 7.98364C84.0568 8.09052 83.9504 8.23551 83.8905 8.40126C83.8306 8.56701 83.8198 8.74651 83.8592 8.91828C83.8987 9.09005 83.9868 9.24682 84.113 9.36983L87.9773 13.1369L86.6106 19.0549C86.5691 19.234 86.5824 19.4215 86.6488 19.593C86.7151 19.7645 86.8315 19.9121 86.9828 20.0166C87.1341 20.1211 87.3133 20.1777 87.4972 20.1791C87.6811 20.1805 87.8611 20.1266 88.014 20.0244L93.0083 16.6949L98.0026 20.0244C98.1589 20.1282 98.3431 20.1816 98.5306 20.1774C98.7181 20.1733 98.8999 20.1119 99.0514 20.0014C99.2029 19.8909 99.317 19.7367 99.3783 19.5595C99.4395 19.3822 99.4451 19.1904 99.3941 19.01L97.7165 13.1397L101.877 9.39551C102.149 9.1497 102.249 8.76629 102.132 8.41866Z" fill="#E77C40"/>
      <path d="M114.998 13.1384L113.631 19.0564C113.589 19.2356 113.601 19.4234 113.667 19.5953C113.734 19.7671 113.85 19.9151 114.001 20.0198C114.153 20.1245 114.332 20.1811 114.516 20.1822C114.701 20.1833 114.881 20.1288 115.033 20.0259L120.028 16.6964L125.022 20.0259C125.178 20.1297 125.363 20.1831 125.55 20.1789C125.738 20.1748 125.919 20.1134 126.071 20.0029C126.222 19.8924 126.337 19.7382 126.398 19.5609C126.459 19.3837 126.465 19.1919 126.414 19.0115L124.736 13.1412L128.897 9.39701C129.03 9.27702 129.125 9.12061 129.17 8.94712C129.216 8.77362 129.209 8.59065 129.151 8.42083C129.094 8.25102 128.988 8.1018 128.846 7.99164C128.705 7.88148 128.534 7.81521 128.355 7.80102L123.126 7.3846L120.863 2.37558C120.791 2.21429 120.674 2.07733 120.526 1.98123C120.377 1.88512 120.205 1.83398 120.028 1.83398C119.851 1.83398 119.678 1.88512 119.53 1.98123C119.382 2.07733 119.264 2.21429 119.192 2.37558L116.929 7.3846L111.7 7.8001C111.525 7.81402 111.357 7.87826 111.216 7.98514C111.076 8.09201 110.97 8.237 110.91 8.40275C110.85 8.56851 110.839 8.748 110.879 8.91977C110.918 9.09154 111.006 9.24831 111.132 9.37133L114.998 13.1384ZM117.615 9.17045C117.778 9.15755 117.936 9.10085 118.07 9.00628C118.204 8.91171 118.31 8.78276 118.378 8.63295L120.028 4.98144L121.678 8.63295C121.745 8.78276 121.852 8.91171 121.986 9.00628C122.12 9.10085 122.277 9.15755 122.441 9.17045L126.084 9.45938L123.084 12.1597C122.824 12.3945 122.72 12.7559 122.815 13.0935L123.965 17.1155L120.538 14.8307C120.387 14.7298 120.21 14.6758 120.029 14.6758C119.848 14.6758 119.671 14.7298 119.521 14.8307L115.94 17.2183L116.903 13.0485C116.938 12.8952 116.933 12.7353 116.889 12.5843C116.845 12.4333 116.762 12.2962 116.65 12.1863L113.863 9.46947L117.615 9.17045Z" fill="#E77C40"/>
      </g>
      <defs>
      <clipPath id="clip0_225_8058">
      <rect width="130.068" height="22.0136" fill="white" transform="translate(0.964844)"/>
      </clipPath>
      </defs>
      </svg>,
      name: 'Syauqizaidan Khairan Khalaf',
    },
    {
      imageLink: TestimonialImage3,
      comment: '“I’ve taken many first aid/CPR classes over the years and this was one of the best! Ishan is a wonderful instructor! He has a nice way of explaining everything and making the lass interesting and engaging. I learned a lot!”',
      starsSVG: <svg width="132" height="23" viewBox="0 0 132 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_225_8058)">
      <path d="M21.0969 8.41866C21.0392 8.24885 20.9331 8.09964 20.7916 7.98945C20.6501 7.87926 20.4795 7.81292 20.3007 7.79861L15.0716 7.3831L12.8087 2.37409C12.7367 2.21276 12.6195 2.07573 12.4712 1.97955C12.323 1.88336 12.1501 1.83213 11.9734 1.83203C11.7968 1.83193 11.6238 1.88298 11.4755 1.979C11.3272 2.07502 11.2098 2.21192 11.1375 2.37317L8.87473 7.3831L3.64558 7.79861C3.46989 7.81253 3.30193 7.87677 3.16179 7.98364C3.02165 8.09052 2.91527 8.23551 2.85537 8.40126C2.79548 8.56701 2.7846 8.74651 2.82405 8.91828C2.8635 9.09005 2.9516 9.24682 3.07781 9.36983L6.94212 13.1369L5.57544 19.0549C5.53394 19.234 5.54724 19.4215 5.61361 19.593C5.67999 19.7645 5.79637 19.9121 5.94765 20.0166C6.09894 20.1211 6.27815 20.1777 6.46202 20.1791C6.6459 20.1805 6.82595 20.1266 6.97881 20.0244L11.9731 16.6949L16.9675 20.0244C17.1237 20.1282 17.308 20.1816 17.4955 20.1774C17.683 20.1733 17.8647 20.1119 18.0162 20.0014C18.1678 19.8909 18.2819 19.7367 18.3431 19.5595C18.4044 19.3822 18.4099 19.1904 18.3589 19.01L16.6813 13.1397L20.8419 9.39551C21.1143 9.1497 21.2143 8.76629 21.0969 8.41866Z" fill="#E77C40"/>
      <path d="M48.1086 8.41866C48.0509 8.24885 47.9448 8.09964 47.8033 7.98945C47.6618 7.87926 47.4912 7.81292 47.3124 7.79861L42.0833 7.3831L39.8205 2.37409C39.7484 2.21276 39.6312 2.07573 39.483 1.97955C39.3347 1.88336 39.1619 1.83213 38.9852 1.83203C38.8085 1.83193 38.6355 1.88298 38.4872 1.979C38.3389 2.07502 38.2215 2.21192 38.1493 2.37317L35.8864 7.3831L30.6573 7.79861C30.4816 7.81253 30.3136 7.87677 30.1735 7.98364C30.0334 8.09052 29.927 8.23551 29.8671 8.40126C29.8072 8.56701 29.7963 8.74651 29.8358 8.91828C29.8752 9.09005 29.9633 9.24682 30.0895 9.36983L33.9538 13.1369L32.5872 19.0549C32.5457 19.234 32.559 19.4215 32.6253 19.593C32.6917 19.7645 32.8081 19.9121 32.9594 20.0166C33.1107 20.1211 33.2899 20.1777 33.4737 20.1791C33.6576 20.1805 33.8377 20.1266 33.9905 20.0244L38.9849 16.6949L43.9792 20.0244C44.1354 20.1282 44.3197 20.1816 44.5072 20.1774C44.6947 20.1733 44.8764 20.1119 45.028 20.0014C45.1795 19.8909 45.2936 19.7367 45.3548 19.5595C45.4161 19.3822 45.4216 19.1904 45.3706 19.01L43.693 13.1397L47.8536 9.39551C48.126 9.1497 48.226 8.76629 48.1086 8.41866Z" fill="#E77C40"/>
      <path d="M75.1203 8.41866C75.0627 8.24885 74.9565 8.09964 74.8151 7.98945C74.6736 7.87926 74.5029 7.81292 74.3242 7.79861L69.095 7.3831L66.8322 2.37409C66.7601 2.21276 66.6429 2.07573 66.4947 1.97955C66.3465 1.88336 66.1736 1.83213 65.9969 1.83203C65.8202 1.83193 65.6472 1.88298 65.4989 1.979C65.3506 2.07502 65.2332 2.21192 65.161 2.37317L62.8982 7.3831L57.669 7.79861C57.4933 7.81253 57.3254 7.87677 57.1852 7.98364C57.0451 8.09052 56.9387 8.23551 56.8788 8.40126C56.8189 8.56701 56.808 8.74651 56.8475 8.91828C56.8869 9.09005 56.975 9.24682 57.1012 9.36983L60.9656 13.1369L59.5989 19.0549C59.5574 19.234 59.5707 19.4215 59.6371 19.593C59.7034 19.7645 59.8198 19.9121 59.9711 20.0166C60.1224 20.1211 60.3016 20.1777 60.4855 20.1791C60.6693 20.1805 60.8494 20.1266 61.0022 20.0244L65.9966 16.6949L70.9909 20.0244C71.1472 20.1282 71.3314 20.1816 71.5189 20.1774C71.7064 20.1733 71.8881 20.1119 72.0397 20.0014C72.1912 19.8909 72.3053 19.7367 72.3666 19.5595C72.4278 19.3822 72.4333 19.1904 72.3824 19.01L70.7047 13.1397L74.8653 9.39551C75.1377 9.1497 75.2377 8.76629 75.1203 8.41866Z" fill="#E77C40"/>
      <path d="M102.132 8.41866C102.074 8.24885 101.968 8.09964 101.827 7.98945C101.685 7.87926 101.515 7.81292 101.336 7.79861L96.1067 7.3831L93.8439 2.37409C93.7718 2.21276 93.6546 2.07573 93.5064 1.97955C93.3582 1.88336 93.1853 1.83213 93.0086 1.83203C92.8319 1.83193 92.659 1.88298 92.5106 1.979C92.3623 2.07502 92.2449 2.21192 92.1727 2.37317L89.9099 7.3831L84.6807 7.79861C84.505 7.81253 84.3371 7.87677 84.1969 7.98364C84.0568 8.09052 83.9504 8.23551 83.8905 8.40126C83.8306 8.56701 83.8198 8.74651 83.8592 8.91828C83.8987 9.09005 83.9868 9.24682 84.113 9.36983L87.9773 13.1369L86.6106 19.0549C86.5691 19.234 86.5824 19.4215 86.6488 19.593C86.7151 19.7645 86.8315 19.9121 86.9828 20.0166C87.1341 20.1211 87.3133 20.1777 87.4972 20.1791C87.6811 20.1805 87.8611 20.1266 88.014 20.0244L93.0083 16.6949L98.0026 20.0244C98.1589 20.1282 98.3431 20.1816 98.5306 20.1774C98.7181 20.1733 98.8999 20.1119 99.0514 20.0014C99.2029 19.8909 99.317 19.7367 99.3783 19.5595C99.4395 19.3822 99.4451 19.1904 99.3941 19.01L97.7165 13.1397L101.877 9.39551C102.149 9.1497 102.249 8.76629 102.132 8.41866Z" fill="#E77C40"/>
      <path d="M114.998 13.1384L113.631 19.0564C113.589 19.2356 113.601 19.4234 113.667 19.5953C113.734 19.7671 113.85 19.9151 114.001 20.0198C114.153 20.1245 114.332 20.1811 114.516 20.1822C114.701 20.1833 114.881 20.1288 115.033 20.0259L120.028 16.6964L125.022 20.0259C125.178 20.1297 125.363 20.1831 125.55 20.1789C125.738 20.1748 125.919 20.1134 126.071 20.0029C126.222 19.8924 126.337 19.7382 126.398 19.5609C126.459 19.3837 126.465 19.1919 126.414 19.0115L124.736 13.1412L128.897 9.39701C129.03 9.27702 129.125 9.12061 129.17 8.94712C129.216 8.77362 129.209 8.59065 129.151 8.42083C129.094 8.25102 128.988 8.1018 128.846 7.99164C128.705 7.88148 128.534 7.81521 128.355 7.80102L123.126 7.3846L120.863 2.37558C120.791 2.21429 120.674 2.07733 120.526 1.98123C120.377 1.88512 120.205 1.83398 120.028 1.83398C119.851 1.83398 119.678 1.88512 119.53 1.98123C119.382 2.07733 119.264 2.21429 119.192 2.37558L116.929 7.3846L111.7 7.8001C111.525 7.81402 111.357 7.87826 111.216 7.98514C111.076 8.09201 110.97 8.237 110.91 8.40275C110.85 8.56851 110.839 8.748 110.879 8.91977C110.918 9.09154 111.006 9.24831 111.132 9.37133L114.998 13.1384ZM117.615 9.17045C117.778 9.15755 117.936 9.10085 118.07 9.00628C118.204 8.91171 118.31 8.78276 118.378 8.63295L120.028 4.98144L121.678 8.63295C121.745 8.78276 121.852 8.91171 121.986 9.00628C122.12 9.10085 122.277 9.15755 122.441 9.17045L126.084 9.45938L123.084 12.1597C122.824 12.3945 122.72 12.7559 122.815 13.0935L123.965 17.1155L120.538 14.8307C120.387 14.7298 120.21 14.6758 120.029 14.6758C119.848 14.6758 119.671 14.7298 119.521 14.8307L115.94 17.2183L116.903 13.0485C116.938 12.8952 116.933 12.7353 116.889 12.5843C116.845 12.4333 116.762 12.2962 116.65 12.1863L113.863 9.46947L117.615 9.17045Z" fill="#E77C40"/>
      </g>
      <defs>
      <clipPath id="clip0_225_8058">
      <rect width="130.068" height="22.0136" fill="white" transform="translate(0.964844)"/>
      </clipPath>
      </defs>
      </svg>,
      name: 'Petrik Sesat',
    },
    {
      imageLink: TestimonialImage2,
      comment: 'Bikini Bottom menjadi sangat ramai dikunjungi saat saya memasukkannya di Destinize',
      starsSVG: <svg width="132" height="23" viewBox="0 0 132 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_225_8058)">
      <path d="M21.0969 8.41866C21.0392 8.24885 20.9331 8.09964 20.7916 7.98945C20.6501 7.87926 20.4795 7.81292 20.3007 7.79861L15.0716 7.3831L12.8087 2.37409C12.7367 2.21276 12.6195 2.07573 12.4712 1.97955C12.323 1.88336 12.1501 1.83213 11.9734 1.83203C11.7968 1.83193 11.6238 1.88298 11.4755 1.979C11.3272 2.07502 11.2098 2.21192 11.1375 2.37317L8.87473 7.3831L3.64558 7.79861C3.46989 7.81253 3.30193 7.87677 3.16179 7.98364C3.02165 8.09052 2.91527 8.23551 2.85537 8.40126C2.79548 8.56701 2.7846 8.74651 2.82405 8.91828C2.8635 9.09005 2.9516 9.24682 3.07781 9.36983L6.94212 13.1369L5.57544 19.0549C5.53394 19.234 5.54724 19.4215 5.61361 19.593C5.67999 19.7645 5.79637 19.9121 5.94765 20.0166C6.09894 20.1211 6.27815 20.1777 6.46202 20.1791C6.6459 20.1805 6.82595 20.1266 6.97881 20.0244L11.9731 16.6949L16.9675 20.0244C17.1237 20.1282 17.308 20.1816 17.4955 20.1774C17.683 20.1733 17.8647 20.1119 18.0162 20.0014C18.1678 19.8909 18.2819 19.7367 18.3431 19.5595C18.4044 19.3822 18.4099 19.1904 18.3589 19.01L16.6813 13.1397L20.8419 9.39551C21.1143 9.1497 21.2143 8.76629 21.0969 8.41866Z" fill="#E77C40"/>
      <path d="M48.1086 8.41866C48.0509 8.24885 47.9448 8.09964 47.8033 7.98945C47.6618 7.87926 47.4912 7.81292 47.3124 7.79861L42.0833 7.3831L39.8205 2.37409C39.7484 2.21276 39.6312 2.07573 39.483 1.97955C39.3347 1.88336 39.1619 1.83213 38.9852 1.83203C38.8085 1.83193 38.6355 1.88298 38.4872 1.979C38.3389 2.07502 38.2215 2.21192 38.1493 2.37317L35.8864 7.3831L30.6573 7.79861C30.4816 7.81253 30.3136 7.87677 30.1735 7.98364C30.0334 8.09052 29.927 8.23551 29.8671 8.40126C29.8072 8.56701 29.7963 8.74651 29.8358 8.91828C29.8752 9.09005 29.9633 9.24682 30.0895 9.36983L33.9538 13.1369L32.5872 19.0549C32.5457 19.234 32.559 19.4215 32.6253 19.593C32.6917 19.7645 32.8081 19.9121 32.9594 20.0166C33.1107 20.1211 33.2899 20.1777 33.4737 20.1791C33.6576 20.1805 33.8377 20.1266 33.9905 20.0244L38.9849 16.6949L43.9792 20.0244C44.1354 20.1282 44.3197 20.1816 44.5072 20.1774C44.6947 20.1733 44.8764 20.1119 45.028 20.0014C45.1795 19.8909 45.2936 19.7367 45.3548 19.5595C45.4161 19.3822 45.4216 19.1904 45.3706 19.01L43.693 13.1397L47.8536 9.39551C48.126 9.1497 48.226 8.76629 48.1086 8.41866Z" fill="#E77C40"/>
      <path d="M75.1203 8.41866C75.0627 8.24885 74.9565 8.09964 74.8151 7.98945C74.6736 7.87926 74.5029 7.81292 74.3242 7.79861L69.095 7.3831L66.8322 2.37409C66.7601 2.21276 66.6429 2.07573 66.4947 1.97955C66.3465 1.88336 66.1736 1.83213 65.9969 1.83203C65.8202 1.83193 65.6472 1.88298 65.4989 1.979C65.3506 2.07502 65.2332 2.21192 65.161 2.37317L62.8982 7.3831L57.669 7.79861C57.4933 7.81253 57.3254 7.87677 57.1852 7.98364C57.0451 8.09052 56.9387 8.23551 56.8788 8.40126C56.8189 8.56701 56.808 8.74651 56.8475 8.91828C56.8869 9.09005 56.975 9.24682 57.1012 9.36983L60.9656 13.1369L59.5989 19.0549C59.5574 19.234 59.5707 19.4215 59.6371 19.593C59.7034 19.7645 59.8198 19.9121 59.9711 20.0166C60.1224 20.1211 60.3016 20.1777 60.4855 20.1791C60.6693 20.1805 60.8494 20.1266 61.0022 20.0244L65.9966 16.6949L70.9909 20.0244C71.1472 20.1282 71.3314 20.1816 71.5189 20.1774C71.7064 20.1733 71.8881 20.1119 72.0397 20.0014C72.1912 19.8909 72.3053 19.7367 72.3666 19.5595C72.4278 19.3822 72.4333 19.1904 72.3824 19.01L70.7047 13.1397L74.8653 9.39551C75.1377 9.1497 75.2377 8.76629 75.1203 8.41866Z" fill="#E77C40"/>
      <path d="M102.132 8.41866C102.074 8.24885 101.968 8.09964 101.827 7.98945C101.685 7.87926 101.515 7.81292 101.336 7.79861L96.1067 7.3831L93.8439 2.37409C93.7718 2.21276 93.6546 2.07573 93.5064 1.97955C93.3582 1.88336 93.1853 1.83213 93.0086 1.83203C92.8319 1.83193 92.659 1.88298 92.5106 1.979C92.3623 2.07502 92.2449 2.21192 92.1727 2.37317L89.9099 7.3831L84.6807 7.79861C84.505 7.81253 84.3371 7.87677 84.1969 7.98364C84.0568 8.09052 83.9504 8.23551 83.8905 8.40126C83.8306 8.56701 83.8198 8.74651 83.8592 8.91828C83.8987 9.09005 83.9868 9.24682 84.113 9.36983L87.9773 13.1369L86.6106 19.0549C86.5691 19.234 86.5824 19.4215 86.6488 19.593C86.7151 19.7645 86.8315 19.9121 86.9828 20.0166C87.1341 20.1211 87.3133 20.1777 87.4972 20.1791C87.6811 20.1805 87.8611 20.1266 88.014 20.0244L93.0083 16.6949L98.0026 20.0244C98.1589 20.1282 98.3431 20.1816 98.5306 20.1774C98.7181 20.1733 98.8999 20.1119 99.0514 20.0014C99.2029 19.8909 99.317 19.7367 99.3783 19.5595C99.4395 19.3822 99.4451 19.1904 99.3941 19.01L97.7165 13.1397L101.877 9.39551C102.149 9.1497 102.249 8.76629 102.132 8.41866Z" fill="#E77C40"/>
      <path d="M114.998 13.1384L113.631 19.0564C113.589 19.2356 113.601 19.4234 113.667 19.5953C113.734 19.7671 113.85 19.9151 114.001 20.0198C114.153 20.1245 114.332 20.1811 114.516 20.1822C114.701 20.1833 114.881 20.1288 115.033 20.0259L120.028 16.6964L125.022 20.0259C125.178 20.1297 125.363 20.1831 125.55 20.1789C125.738 20.1748 125.919 20.1134 126.071 20.0029C126.222 19.8924 126.337 19.7382 126.398 19.5609C126.459 19.3837 126.465 19.1919 126.414 19.0115L124.736 13.1412L128.897 9.39701C129.03 9.27702 129.125 9.12061 129.17 8.94712C129.216 8.77362 129.209 8.59065 129.151 8.42083C129.094 8.25102 128.988 8.1018 128.846 7.99164C128.705 7.88148 128.534 7.81521 128.355 7.80102L123.126 7.3846L120.863 2.37558C120.791 2.21429 120.674 2.07733 120.526 1.98123C120.377 1.88512 120.205 1.83398 120.028 1.83398C119.851 1.83398 119.678 1.88512 119.53 1.98123C119.382 2.07733 119.264 2.21429 119.192 2.37558L116.929 7.3846L111.7 7.8001C111.525 7.81402 111.357 7.87826 111.216 7.98514C111.076 8.09201 110.97 8.237 110.91 8.40275C110.85 8.56851 110.839 8.748 110.879 8.91977C110.918 9.09154 111.006 9.24831 111.132 9.37133L114.998 13.1384ZM117.615 9.17045C117.778 9.15755 117.936 9.10085 118.07 9.00628C118.204 8.91171 118.31 8.78276 118.378 8.63295L120.028 4.98144L121.678 8.63295C121.745 8.78276 121.852 8.91171 121.986 9.00628C122.12 9.10085 122.277 9.15755 122.441 9.17045L126.084 9.45938L123.084 12.1597C122.824 12.3945 122.72 12.7559 122.815 13.0935L123.965 17.1155L120.538 14.8307C120.387 14.7298 120.21 14.6758 120.029 14.6758C119.848 14.6758 119.671 14.7298 119.521 14.8307L115.94 17.2183L116.903 13.0485C116.938 12.8952 116.933 12.7353 116.889 12.5843C116.845 12.4333 116.762 12.2962 116.65 12.1863L113.863 9.46947L117.615 9.17045Z" fill="#E77C40"/>
      </g>
      <defs>
      <clipPath id="clip0_225_8058">
      <rect width="130.068" height="22.0136" fill="white" transform="translate(0.964844)"/>
      </clipPath>
      </defs>
      </svg>,
      name: 'Petrik Sesat',
    },
  ];

  const [activeSlide, setActiveSlide] = React.useState(0);

  // Map over the slidesContent array to render slides
  const slides = testimonialsData?.map((content, index) => (
    <div key={index} className={`flex flex-col justify-around items-center bg-white mt-10 h-[300px] md:h-[550px] py-[40px] px-[35px] gap-6 transform transition duration-300 ease-in-out rounded-[8px]
      ${index === activeSlide ? 'scale-100 mb-5' : 'scale-90'}`}
      style={{width: "300px"}}
      // style={{maxWidth: window.innerWidth <= 375 || window.innerHeight <= 375 ? "366px" : "182px",}}`
    >
      <div className='flex justify-center items-center'><img className='w-[75.56px] h-[75.56px] md:w-[149px] md:h-[149px] rounded-full' src={`${process.env.REACT_APP_BASE_URL}/${content.image}`} alt='Image' /></div>
      <div className='flex justify-center items-center text-justify text-[8.11px] md:text-[16px]'>{content?.remarks}</div>
      <div className='flex justify-center items-center text '>
        <svg width="132" height="23" viewBox="0 0 132 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_225_8058)">
          <path d="M21.0969 8.41866C21.0392 8.24885 20.9331 8.09964 20.7916 7.98945C20.6501 7.87926 20.4795 7.81292 20.3007 7.79861L15.0716 7.3831L12.8087 2.37409C12.7367 2.21276 12.6195 2.07573 12.4712 1.97955C12.323 1.88336 12.1501 1.83213 11.9734 1.83203C11.7968 1.83193 11.6238 1.88298 11.4755 1.979C11.3272 2.07502 11.2098 2.21192 11.1375 2.37317L8.87473 7.3831L3.64558 7.79861C3.46989 7.81253 3.30193 7.87677 3.16179 7.98364C3.02165 8.09052 2.91527 8.23551 2.85537 8.40126C2.79548 8.56701 2.7846 8.74651 2.82405 8.91828C2.8635 9.09005 2.9516 9.24682 3.07781 9.36983L6.94212 13.1369L5.57544 19.0549C5.53394 19.234 5.54724 19.4215 5.61361 19.593C5.67999 19.7645 5.79637 19.9121 5.94765 20.0166C6.09894 20.1211 6.27815 20.1777 6.46202 20.1791C6.6459 20.1805 6.82595 20.1266 6.97881 20.0244L11.9731 16.6949L16.9675 20.0244C17.1237 20.1282 17.308 20.1816 17.4955 20.1774C17.683 20.1733 17.8647 20.1119 18.0162 20.0014C18.1678 19.8909 18.2819 19.7367 18.3431 19.5595C18.4044 19.3822 18.4099 19.1904 18.3589 19.01L16.6813 13.1397L20.8419 9.39551C21.1143 9.1497 21.2143 8.76629 21.0969 8.41866Z" fill="#E77C40"/>
          <path d="M48.1086 8.41866C48.0509 8.24885 47.9448 8.09964 47.8033 7.98945C47.6618 7.87926 47.4912 7.81292 47.3124 7.79861L42.0833 7.3831L39.8205 2.37409C39.7484 2.21276 39.6312 2.07573 39.483 1.97955C39.3347 1.88336 39.1619 1.83213 38.9852 1.83203C38.8085 1.83193 38.6355 1.88298 38.4872 1.979C38.3389 2.07502 38.2215 2.21192 38.1493 2.37317L35.8864 7.3831L30.6573 7.79861C30.4816 7.81253 30.3136 7.87677 30.1735 7.98364C30.0334 8.09052 29.927 8.23551 29.8671 8.40126C29.8072 8.56701 29.7963 8.74651 29.8358 8.91828C29.8752 9.09005 29.9633 9.24682 30.0895 9.36983L33.9538 13.1369L32.5872 19.0549C32.5457 19.234 32.559 19.4215 32.6253 19.593C32.6917 19.7645 32.8081 19.9121 32.9594 20.0166C33.1107 20.1211 33.2899 20.1777 33.4737 20.1791C33.6576 20.1805 33.8377 20.1266 33.9905 20.0244L38.9849 16.6949L43.9792 20.0244C44.1354 20.1282 44.3197 20.1816 44.5072 20.1774C44.6947 20.1733 44.8764 20.1119 45.028 20.0014C45.1795 19.8909 45.2936 19.7367 45.3548 19.5595C45.4161 19.3822 45.4216 19.1904 45.3706 19.01L43.693 13.1397L47.8536 9.39551C48.126 9.1497 48.226 8.76629 48.1086 8.41866Z" fill="#E77C40"/>
          <path d="M75.1203 8.41866C75.0627 8.24885 74.9565 8.09964 74.8151 7.98945C74.6736 7.87926 74.5029 7.81292 74.3242 7.79861L69.095 7.3831L66.8322 2.37409C66.7601 2.21276 66.6429 2.07573 66.4947 1.97955C66.3465 1.88336 66.1736 1.83213 65.9969 1.83203C65.8202 1.83193 65.6472 1.88298 65.4989 1.979C65.3506 2.07502 65.2332 2.21192 65.161 2.37317L62.8982 7.3831L57.669 7.79861C57.4933 7.81253 57.3254 7.87677 57.1852 7.98364C57.0451 8.09052 56.9387 8.23551 56.8788 8.40126C56.8189 8.56701 56.808 8.74651 56.8475 8.91828C56.8869 9.09005 56.975 9.24682 57.1012 9.36983L60.9656 13.1369L59.5989 19.0549C59.5574 19.234 59.5707 19.4215 59.6371 19.593C59.7034 19.7645 59.8198 19.9121 59.9711 20.0166C60.1224 20.1211 60.3016 20.1777 60.4855 20.1791C60.6693 20.1805 60.8494 20.1266 61.0022 20.0244L65.9966 16.6949L70.9909 20.0244C71.1472 20.1282 71.3314 20.1816 71.5189 20.1774C71.7064 20.1733 71.8881 20.1119 72.0397 20.0014C72.1912 19.8909 72.3053 19.7367 72.3666 19.5595C72.4278 19.3822 72.4333 19.1904 72.3824 19.01L70.7047 13.1397L74.8653 9.39551C75.1377 9.1497 75.2377 8.76629 75.1203 8.41866Z" fill="#E77C40"/>
          <path d="M102.132 8.41866C102.074 8.24885 101.968 8.09964 101.827 7.98945C101.685 7.87926 101.515 7.81292 101.336 7.79861L96.1067 7.3831L93.8439 2.37409C93.7718 2.21276 93.6546 2.07573 93.5064 1.97955C93.3582 1.88336 93.1853 1.83213 93.0086 1.83203C92.8319 1.83193 92.659 1.88298 92.5106 1.979C92.3623 2.07502 92.2449 2.21192 92.1727 2.37317L89.9099 7.3831L84.6807 7.79861C84.505 7.81253 84.3371 7.87677 84.1969 7.98364C84.0568 8.09052 83.9504 8.23551 83.8905 8.40126C83.8306 8.56701 83.8198 8.74651 83.8592 8.91828C83.8987 9.09005 83.9868 9.24682 84.113 9.36983L87.9773 13.1369L86.6106 19.0549C86.5691 19.234 86.5824 19.4215 86.6488 19.593C86.7151 19.7645 86.8315 19.9121 86.9828 20.0166C87.1341 20.1211 87.3133 20.1777 87.4972 20.1791C87.6811 20.1805 87.8611 20.1266 88.014 20.0244L93.0083 16.6949L98.0026 20.0244C98.1589 20.1282 98.3431 20.1816 98.5306 20.1774C98.7181 20.1733 98.8999 20.1119 99.0514 20.0014C99.2029 19.8909 99.317 19.7367 99.3783 19.5595C99.4395 19.3822 99.4451 19.1904 99.3941 19.01L97.7165 13.1397L101.877 9.39551C102.149 9.1497 102.249 8.76629 102.132 8.41866Z" fill="#E77C40"/>
          <path d="M114.998 13.1384L113.631 19.0564C113.589 19.2356 113.601 19.4234 113.667 19.5953C113.734 19.7671 113.85 19.9151 114.001 20.0198C114.153 20.1245 114.332 20.1811 114.516 20.1822C114.701 20.1833 114.881 20.1288 115.033 20.0259L120.028 16.6964L125.022 20.0259C125.178 20.1297 125.363 20.1831 125.55 20.1789C125.738 20.1748 125.919 20.1134 126.071 20.0029C126.222 19.8924 126.337 19.7382 126.398 19.5609C126.459 19.3837 126.465 19.1919 126.414 19.0115L124.736 13.1412L128.897 9.39701C129.03 9.27702 129.125 9.12061 129.17 8.94712C129.216 8.77362 129.209 8.59065 129.151 8.42083C129.094 8.25102 128.988 8.1018 128.846 7.99164C128.705 7.88148 128.534 7.81521 128.355 7.80102L123.126 7.3846L120.863 2.37558C120.791 2.21429 120.674 2.07733 120.526 1.98123C120.377 1.88512 120.205 1.83398 120.028 1.83398C119.851 1.83398 119.678 1.88512 119.53 1.98123C119.382 2.07733 119.264 2.21429 119.192 2.37558L116.929 7.3846L111.7 7.8001C111.525 7.81402 111.357 7.87826 111.216 7.98514C111.076 8.09201 110.97 8.237 110.91 8.40275C110.85 8.56851 110.839 8.748 110.879 8.91977C110.918 9.09154 111.006 9.24831 111.132 9.37133L114.998 13.1384ZM117.615 9.17045C117.778 9.15755 117.936 9.10085 118.07 9.00628C118.204 8.91171 118.31 8.78276 118.378 8.63295L120.028 4.98144L121.678 8.63295C121.745 8.78276 121.852 8.91171 121.986 9.00628C122.12 9.10085 122.277 9.15755 122.441 9.17045L126.084 9.45938L123.084 12.1597C122.824 12.3945 122.72 12.7559 122.815 13.0935L123.965 17.1155L120.538 14.8307C120.387 14.7298 120.21 14.6758 120.029 14.6758C119.848 14.6758 119.671 14.7298 119.521 14.8307L115.94 17.2183L116.903 13.0485C116.938 12.8952 116.933 12.7353 116.889 12.5843C116.845 12.4333 116.762 12.2962 116.65 12.1863L113.863 9.46947L117.615 9.17045Z" fill="#E77C40"/>
          </g>
          <defs>
          <clipPath id="clip0_225_8058">
          <rect width="130.068" height="22.0136" fill="white" transform="translate(0.964844)"/>
          </clipPath>
          </defs>
        </svg>
      </div>
      <div className='flex justify-center items-center text-[#23A6F0] text-[7.1px] md:text-sm leading-6 font-semibold'>{content?.name}</div>
    </div>
  ));
  return (
    <>
      <div className='relative testimonials-BG z-50'>
        <div className='flex flex-col justify-center items-center text-white pt-7'>
          <div className='font-extrabold md:text-[27.63px] lg:text-[56px] leading-[30.59px] md:leading-[62px] text-white'>Our Testimonial</div>
          <div className='font-normal text-[10px] md:text-lg leading-[15.79px] md:leading-[32px] pt-5 text-center'>Real stories, real results: Discover what our happy clients are saying about ProSafe Training</div>
        </div>
        <div className="relative max-h-[400px] md:max-h-[600px] lg:max-h-[900px]">
          <Slider {...settings}>
            {slides}
          </Slider>
        </div>

        <div className='absolute bottom-[-63px] left-0 lg:flex hidden'>
          <img src={horizontalDots} alt='' />
        </div>

        <div className='absolute bottom-[-7%] lg:bottom-[-13%] right-0 -z-10'>
          <img src={verticalDots} alt='' />
        </div>
      </div>
    </>
  )
}

export default Testimonials
