import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import Occupational from "../assets/Occupational.png";
import verticalDots from "../assets/verticalDots.svg";
import FAQ from "./FAQ";
import Frequently from "../components/Homepage/FrequentlyAskQuestion";
import trainingCarousalImage from "../assets/trainingCarousalImage.png";
import { motion } from "framer-motion";
import axios from "axios";
import { useLocation } from "react-router-dom";
import TrainingHero from "../assets/TrainingHero.png";
import Cources from "../components/Homepage/Courses";
import Event from '../components/Event';
import { useStrapperContext } from '../components/Strapper/StrapperContext';
import SearchableSelect from "../components/Event/SearchableSelect";
import { useParams } from "react-router-dom";
const moment = require('moment');

const CourseAbout = () => {
  const location = useLocation();
  const { straper, setStraper } = useStrapperContext();
  const id = location.state?.courseId;
  const [course, setCourse] = useState([])
  const [isRegister, setRegister] = useState(false);
  const handleClosRegistereModal = () => setRegister(false);
  const [language, setLanguage] = useState("English");
  const [selectedVenue, setSelectedVenue] = useState("All locations");
  const [venueOptions, setVenueOptions] = useState([]);
  const [registerEvent, setRegisterEvent] = useState({});
  const [eventCourse, setEventCourse] = useState({});
  const { name } = useParams();
  const bubbleSVG = (
    <svg
      width="135"
      height="128"
      viewBox="0 0 135 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="165"
        y="128"
        width="165"
        height="165"
        rx="82.5"
        transform="rotate(180 165 128)"
        fill="#E9EFFF"
      />
    </svg>
  );

  const bubbleSVG2 = (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="65"
        y="65"
        width="65"
        height="65"
        rx="32.5"
        transform="rotate(180 65 65)"
        fill="#E9EFFF"
      />
    </svg>
  );

  const bubbleSVG3 = (
    <svg
      width="60"
      height="165"
      viewBox="0 0 60 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="165"
        y="165"
        width="165"
        height="165"
        rx="82.5"
        transform="rotate(180 165 165)"
        fill="#E9EFFF"
      />
    </svg>
  );

  const bubbleSVG4 = (
    <svg
      width="47"
      height="165"
      viewBox="0 0 47 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="47"
        y="165"
        width="165"
        height="165"
        rx="82.5"
        transform="rotate(180 47 165)"
        fill="#E9EFFF"
      />
    </svg>
  );

  const bubbleSVG5 = (
    <svg
      width="130"
      height="100"
      viewBox="0 0 130 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="130"
        y="100"
        width="130"
        height="130"
        rx="65"
        transform="rotate(180 130 100)"
        fill="#E9EFFF"
      />
    </svg>
  );

  const bubbleSVG6 = (
    <svg
      width="98"
      height="58"
      viewBox="0 0 98 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="98"
        y="98"
        width="98"
        height="98"
        rx="49"
        transform="rotate(180 98 98)"
        fill="#E9EFFF"
      />
    </svg>
  );

  const bulletPointSVG = (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.61932 9.85227L0.676136 6.90909L3.61932 4.00568L6.5625 6.90909L3.61932 9.85227ZM3.61932 8.65909L5.36932 6.90909L3.61932 5.15909L1.86932 6.90909L3.61932 8.65909ZM7.39773 13.5909L4.45455 10.6875L7.39773 7.74432L10.3011 10.6875L7.39773 13.5909ZM7.39773 12.3977L9.10795 10.6875L7.39773 8.9375L5.64773 10.6875L7.39773 12.3977ZM11.1364 9.85227L8.23295 6.90909L11.1364 4.00568L14.0398 6.90909L11.1364 9.85227ZM11.1364 8.65909L12.8864 6.90909L11.1364 5.15909L9.38636 6.90909L11.1364 8.65909ZM7.39773 6.07386L4.45455 3.17045L7.39773 0.227272L10.3011 3.17045L7.39773 6.07386ZM7.39773 4.88068L9.10795 3.17045L7.39773 1.42045L5.64773 3.17045L7.39773 4.88068Z"
        fill="#073967"
      />
    </svg>
  );
  const handleOpenRegistereModal = (event, course) => {
    setStraper(1);
    setRegisterEvent(event);
    setEventCourse(course);
    setRegister(true);
  };
  useEffect(() => {
    const getCategory = () => {
      if (id) {
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/course/${id}`)
        .then((response) => {;
          setCourse(response?.data?.data?.category);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/course/name/${name}`)
        .then((response) => {
          setCourse(response?.data?.data?.category);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }
    getCategory();
  }, []);
  useEffect(() => {
    if (course && course.campuses) {
      // Split the comma-separated string into an array
      const optionsArray = course?.campuses;
      // Set the state of venueOptions to the array of options
      setVenueOptions(optionsArray);
    }
  }, [course]);

  // Event handler for when the dropdown value changes
  const handleVenueChange = (event) => {
    setSelectedVenue(event);
    // setSelectedVenue(event.target.value);
  };
  const spiltCourseName = (name) => {
    const res = name.split('—');
    return res[0]
  }

  const checkCampusFromSelectedVenue = (venue, campus) => {
        return venue.includes(campus)
  }

  const [uniqueLanguages, setUniqueLanguages] = useState([]);

  useEffect(() => {
    if (course?.events) {
      const languages = [
        ...new Set(course.events.map(event => event?.language)),
      ];
      const filteredLanguages = languages?.filter((value) => {
        if (value) return value
      })
      setUniqueLanguages(filteredLanguages);
      setLanguage("English");
    }
  }, [course]);
  return (
    <>
      {isRegister && (
        <Event
          event={registerEvent}
          course={eventCourse}
          spiltCourseName={spiltCourseName}
          isRegister={isRegister}
          handleClosRegistereModal={handleClosRegistereModal}
          straper={straper}
          setStraper={setStraper}
        />
      )}
      <Layout>
        <section className="relative overflow-hidden">
          <div className="lg:flex hidden absolute left-[-35px] md:left-[-20px] top-8">
            <img
              className="md:w-[85px] w-[65px] z-0"
              src={verticalDots}
              alt=""
            />
          </div>
          <div className="lg:flex hidden absolute right-[-30px] md:right-[-20px] bottom-[-50px]">
            <img className="md:w-[85px] w-[65px]" src={verticalDots} alt="" />
          </div>
          <div className="lg:flex hidden absolute right-0 top-0">
            {bubbleSVG}
          </div>
          <div className="lg:flex hidden absolute left-20 bottom-[-20px]">
            {bubbleSVG2}
          </div>
          <div className="relative">
            <img
              className="md:w-[90%] w-[342px] md:h-[470px] h-[158.54px] mx-[auto] rounded-[20px] opacity-75 md:mt-[10px] mt-[5px] md:mb-[60px] mb-[30px]"
              src={`${process.env.REACT_APP_BASE_URL}/${course?.image}`}
              alt="Occupational First Aid Level 1"
            />
            <h3
              className={`${
                course?.image ? "text-white " : "text-black"
              } absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  md:text-[48px] text-[18.7px] font-[800] md:leading-[50px] leading-[20px] text-center`}
            >
              {course?.name}
            </h3>
          </div>
        </section>
        <section className="relative">
          <div className="lg:flex hidden absolute right-0 top-[35%]">
            {bubbleSVG3}
          </div>
          <div className="lg:flex hidden absolute left-0 top-[35%]">
            {bubbleSVG4}
          </div>
          <div className="lg:flex hidden absolute left-[-45px] top-[80%]">
            <img className="md:w-[85px] w-[65px]" src={verticalDots} alt="" />
          </div>
          {!isRegister && (
            <>
              <div className="w-[90%] md:w-[85%] m-auto">
                <div>
                  <h2 className="md:text-[48px] text-[20.23px] font-extrabold md:leading-[50px] leading-[21.07px] text-center">
                    About this Course
                  </h2>
                  {/* <p className="text-[#5E6282] text-center font-semibold md:text-[16px] text-[10px] md:leading-[21.86px] leading-[13.66px] tracking-[1.52px] my-[20px]">
                    {course.description}
                  </p> */}
                  <div
                    className="text-[#5E6282] font-semibold md:text-[16px]  xl:text-[20px] text-[10px] md:leading-[21.86px] leading-[13.66px] tracking-[1.52px] my-[20px]"
                    dangerouslySetInnerHTML={{ __html: course?.description }}
                  ></div>
                </div>
                <div className="md:mt-[10px] mt-[7px] flex w-full justify-between">
                  <div className="w-[70%]">
                    <h4 className="text-[#183864] text-[14px] md:text-[18px] xl:text-[24px] md:text-base leading-[16.39px] font-extrabold">
                      What you will learn:
                    </h4>
                    <ul className="ml-[10px] list-disc pl-4">
                      {course?.objectives?.length > 0 &&
                        course?.objectives?.map((item, index) => (
                          <li key={index} className="mt-[10px] text-[12px] md:text-[18px] xl:text-[22px] leading-[18.66px] font-medium text-[#183864] tracking-[9.5%]">
                            {item}
                          </li>
                        ))}
                    </ul>
                  </div>
                  {/* <div className="w-[30%]">
                    <img
                      src={TrainingHero}
                      alt="TrainingHero"
                      className="w-[70%]"
                    />
                  </div> */}
                </div>
                <div className="mt-[20px] md:w-full flex flex-col w-full gap-3">
                  <div className="w-full">
                    <h3 className="font-bold m-[5px] md:text-[20px] xl:text-[24px] text-[16px] text-[#183864] leading-6">
                      Campuses
                    </h3>
                    <p className="flex gap-1 md:mt-[10px] mt-[5px] md:text-[18px] xl:text-[22px] text-base text-[#183864] leading-[24px] font-normal ml-5">
                      <svg
                        fill="#183864"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        viewBox="0 0 395.71 395.71"
                      >
                        <g>
                          <path
                            d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
                    c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
                    C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
                    c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
                          />
                        </g>
                      </svg>
                      Available at {course?.campuses?.join(",")} Must be
                      physically able to perform all of the First Aid tasks
                      required to complete the learning tasks
                    </p>
                  </div>
                  <div className="w-full">
                    <h3 className="font-bold m-[5px] md:text-[20px] xl:text-[24px] text-[16px] text-[#183864] leading-6">
                      Prerequisites
                    </h3>
                    <ul className="list-disc">
                      {course?.prerequisites?.length > 0 &&
                        course?.prerequisites.map((item, index) => (
                          <li key={index} className="mt-[10px] text-base md:text-[18px] xl:text-[22px] leading-[18.66px] font-medium text-[#183864] tracking-[9.5%] ml-10">
                            {item}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="flex justify-between mr-[10%] flex-wrap md:flex-row flex-col w-full mt-5">
                  <div className="md:block flex justify-between mt-5 shadow-lg p-5 rounded-lg min-w-[20%] transition-transform transform hover:scale-105">
                    <h4 className="font-bold md:text-[20px] xl:text-[22px] text-[12px]">
                      Course Duration
                    </h4>
                    <h4 className="md:text-[18px]  xl:text-[20px] text-[12px] mt-1">
                      {course?.duration}
                    </h4>
                  </div>
                  <div className="md:block flex justify-between mt-5 shadow-lg p-5 rounded-lg min-w-[20%] transition-transform transform hover:scale-105">
                    <h4 className="font-bold md:text-[20px]  xl:text-[22px] text-[12px]">
                      Certificate
                    </h4>
                    <h4 className="md:text-[18px]  xl:text-[20px] text-[12px] mt-1">
                      {course?.certificateValidity}
                    </h4>
                  </div>
                  <div className="md:block flex justify-between mt-5 shadow-lg p-5 rounded-lg min-w-[20%] transition-transform transform hover:scale-105">
                    <h4 className="font-bold md:text-[20px]  xl:text-[22px] text-[12px]">
                      Course Price
                    </h4>
                    <h4 className="md:text-[18px]  xl:text-[20px] text-[12px] mt-1">
                      ${course?.price} + GST
                    </h4>
                  </div>
                  <div className="md:block flex justify-between mt-5 shadow-lg p-5 rounded-lg min-w-[20%] transition-transform transform hover:scale-105">
                    <h4 className="font-bold md:text-[20px]  xl:text-[22px] text-[12px] mt-1">
                      Language
                    </h4>
                    <div className="flex flex-wrap gap-3">
                      {course?.language &&
                        course?.language.length > 0 &&
                        course?.language.map((item, index) => (
                          <div key={index}>
                            <h4 className="md:text-[18px]  xl:text-[20px] text-[12px]">
                              {item}
                            </h4>
                            {index < course?.language.length - 1 && <p>|</p>}
                          </div>
                        ))}
                      {/* <h4 className="md:text-[16px] text-[12px]">English</h4>
                      <h4 className="md:text-[16px] text-[12px]">Punjabi</h4> */}
                    </div>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col justify-center items-center md:justify-between md:mt-20 mt-1">
                  <div>
                    <h4 className="md:text-[16px] text-[12px] font-bold leading-6 text-[#183864]">
                      Could your company benefit from this training? 
                    </h4>
                  </div>
                  <motion.div whileHover={{ scale: 1.05 }}>
                    <button className="md:w-[353px] py-3 px-6 rounded-[10px] bg-red-700 font-medium text-center text-white md:text-[15px] text-[12px] leading-[15px]">
                      Click here to book private group training
                    </button>
                  </motion.div>
                </div>
              </div>
              <section className="lg:flex hidden mt-20 relative">
                <div className="lg:flex hidden absolute -top-10 left-28">
                  {bubbleSVG5}
                </div>
                <div className="xl:flex hidden absolute  left-0 top-60">
                  <img
                    className="md:w-[85px] w-[65px]"
                    src={verticalDots}
                    alt=""
                  />
                </div>
                <div className="lg:flex hidden absolute right-0 top-[70%]">
                  <img
                    className="md:w-[85px] w-[65px]"
                    src={verticalDots}
                    alt=""
                  />
                </div>
              </section>

              <section>
                <div className="w-[90%] md:w-[85%] m-auto">
                  <h2 className="text-[#14183E] text-center font-extrabold md:text-[48px] text-[27.63px] leading-[27.63px] md:mb-[15px] mb-[8.5px]">
                    Course Languages
                  </h2>
                  <div className="flex justify-center gap-[20px] md:my-[20px] my-[10px]">
                    {uniqueLanguages?.map((item, index) => (
                      <motion.div key={index} whileHover={{ scale: 1.05 }}>
                        <button
                          className={`${
                            language === item
                              ? "bg-[#B02526] text-white "
                              : "bg-white text-black "
                          } md:w-[129px] w-[72px] md:h-[58px] h-[33px] md:text-[20px] text-[11px] rounded-[6px] border-[0.56px] border-[#D9DBE9]`}
                          onClick={() => setLanguage(item)}
                        >
                          {item}
                        </button>
                      </motion.div>
                    ))}
                  </div>
                  <div className="flex w-full">
                    <div className="flex justify-center items-center gap-3">
                      <label
                        htmlFor="Venue"
                        className="font-bold md:text-[24px] text-[14px] md:ml-[5px] ml-[2.5px]"
                      >
                        Venue:{" "}
                      </label>
                      {/* <select
                        className="min-w-[270px] w-full h-[33px] border-[0.71px] border-[#818181] rounded-[5px]"
                        name="venuelist"
                        id="Venues"
                        value={selectedVenue} // Set the value of the select to the state
                        onChange={handleVenueChange} // Handle changes to the select
                      >
                        <option value="All locations">All locations</option>
                        {venueOptions?.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select> */}
                      <div className="p-4">
                        <SearchableSelect
                          options={venueOptions}
                          selectedValue={selectedVenue}
                          onChange={handleVenueChange}
                          placeholder="Select a location"
                        />
                      </div>

                    </div>
                  </div>
                  <div className="">
                    <div className="w-full text-[#183864] bg-blue-600 rounded-t-[5px] bg-opacity-10 my-[20px] border border-black border-opacity-5 pb-[20px] pt-[20px] pl-[7px]">
                      <h3 className="leading-4 font-semibold py-[10px] pl-[5px] text-xl">
                        Event
                      </h3>
                    </div>
                    {course?.events
                    ?.filter((item) => {
                      const currentDate = moment();
                      const onSaleDate = moment.utc(item.onSale);
                      const endDate = moment.utc(item.endDate);
                  
                      return (
                        item.language?.toLowerCase() === language?.toLowerCase() &&
                        (selectedVenue === "All locations" ||
                          checkCampusFromSelectedVenue(selectedVenue, item?.campus)) &&
                        item.eventType?.toLowerCase() === "public" &&
                        (item.eventStatus?.toLowerCase() === "active") &&
                        currentDate.isSameOrAfter(onSaleDate) &&
                        currentDate.isBefore(endDate)
                      );
                    })
                    .map((item, index) => (
                          <div
                            key={index}
                            className="w-full text-[#183864] shadow-md flex justify-between md:mb-[10px]mb-[5px] border border-black border-opacity-30 cursor-pointer items-center transform hover:scale-105 transition-transform duration-300 rounded-lg"
                          >
                            <div className="w-full flex justify-between p-1.5">
                              <div className="flex md:justify-start justify-center items-center gap-2">
                                <div className="w-[15px] h-[42px] flex items-center justify-center">
                                  {bulletPointSVG}
                                </div>
                                <p className="text-[10px] md:text-[18px] leading-4 font-semibold">
                                  {`${spiltCourseName(course?.name)} - ${
                                    moment(item?.endDate, "YYYY-MM-DD").diff(moment(item?.startDate, "YYYY-MM-DD"), 'days') >= 1
                                      ? `(${moment(item?.startDate, "YYYY-MM-DD").format("dddd")}/${moment(item?.endDate, "YYYY-MM-DD").format("dddd")})`
                                      : `(${moment(item?.startDate, "YYYY-MM-DD").format("dddd")})`
                                  } | ${
                                    moment(item?.endDate, "YYYY-MM-DD").diff(moment(item?.startDate, "YYYY-MM-DD"), 'days') >= 1
                                      ? `${moment(item?.startDate, "YYYY-MM-DD").format("MMMM DD")}/${moment(item?.endDate, "YYYY-MM-DD").format("DD, YYYY")}`
                                      : moment(item?.startDate, "YYYY-MM-DD").format("MMMM DD, YYYY")
                                  } | ${item?.campus}`}
                                </p>
                              </div>
                              <button
                                className={`font-semibold text-xs md:text-[18px] leading-4 ${item.seatsTotal === item.seatsOccupied ? "text-gray-400" : "text-[#193864]"} pr-8 w-1/4 flex justify-center items-center`}
                                onClick={() =>
                                  handleOpenRegistereModal(item, course)
                                }
                                disabled = {item.seatsTotal === item.seatsOccupied}
                              >
                                Register
                              </button>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </section>
            </>
          )}
        </section>

        <div>
          <div className=" my-4 w-[369px] mx-auto sm:w-[520px] md:w-[620px] lg:w-[788px] xl:w-[1175px]  max-[370px]:w-[300px]">
            <div
              className=" flex flex-col items-center gap-3 sm:gap-6 xl:gap-16"
              data-carousel=""
            >
              <div className=" h-[317px] w-full rounded-[10px] overflow-hidden shadow-md sm:h-max ">
                <div
                  className=" duration-700 ease-in-out flex flex-col sm:flex-row sm:items-center my-1 "
                  data-carousel-item="active"
                >
                  <div className="px-6 py-4 sm:basis-2/4">
                    <img
                      src={trainingCarousalImage}
                      className="rounded-[10px]  "
                      alt="..."
                    />
                  </div>
                  <div className="basis-2/4 flex flex-col gap-3 text-center text-[#183864] mx-[1px] sm:text-left sm:pr-1 xl:gap-6 ">
                    <h3 className="font-bold text-[18.17px] leading-[21.8px] sm:text-[14px] lg:text-[18px] xl:text-[20px] xl:leading-[24px] max-[370px]:text-[16px]">
                      Over 20 years of experience
                    </h3>
                    <p className="text-[11.8px] font-normal sm:text-[8px] md:text-[10px] lg:text-[14px] xl:text-[18px] xl:leading-[27px]  max-[370px]:text-[10px]">
                      With over two decades of experience in the First Aid and
                      Workplace Safety industry, we have a proven track record
                      of delivering high-quality training and certification to
                      professionals across a variety of sectors
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex gap-2 lg:gap-4 justify-center">
                <button
                  type="button"
                  className="w-2 h-2 rounded-full opacity-100 bg-[#183864] lg:w-3 lg:h-3  xl:w-[14px] xl:h-[14px]"
                  aria-current="true"
                  aria-label="Slide 1"
                  data-carousel-slide-to="0"
                ></button>
                <button
                  type="button"
                  className="w-2 h-2 rounded-full bg-[#D9D9D9] lg:w-3 lg:h-3 xl:w-[14px] xl:h-[14px]"
                  aria-current="false"
                  aria-label="Slide 2"
                  data-carousel-slide-to="1"
                ></button>
                <button
                  type="button"
                  className="w-2 h-2 rounded-full bg-[#D9D9D9] lg:w-3 lg:h-3  xl:w-[14px] xl:h-[14px]"
                  aria-current="false"
                  aria-label="Slide 3"
                  data-carousel-slide-to="2"
                ></button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Frequently />
        </div>
      </Layout>
    </>
  );
};

export default CourseAbout;
